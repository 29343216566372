import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import ExportData from "components/ExportData/ExportData";
import { getTrialPeriodDays, getShippingCost } from "redux/action/common.action";
import { MasterSetting } from "components/MasterSetting/index";

const OtherSetting = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTrialPeriodDays());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getShippingCost());
  }, [dispatch]);

  return (
    <div>
      <ExportData />
      <MasterSetting />
    </div>
  );
};

export default OtherSetting;
