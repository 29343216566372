import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import { KeyboardArrowDown, KeyboardArrowUp, Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import { Button, Collapse } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { find } from "lodash";
import { generateProductUrl } from "utils";
import { getProductDetailsByProductType } from "utils";
import { useSnackbar } from "notistack";
import { updateSubscriptionProduct } from "redux/action/customer.action";
import { getCustomer } from "redux/action/customer.action";
import { removeItem } from "redux/action/order.action";
import { getOrder } from "redux/action/order.action";
import ItemAddDialog from "../ItemAddOrEdit";

export default function ProductLists(props) {
  const { row, fromScreen, userPriceVersion, allowEditProduct, showIsReturnedColumn = false, showItemDeleteColumn = false, showItemEditColumn = false } = props;
  const dispatch = useDispatch();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const CUSTOMER_STATE = useSelector(state => state.customer);
  const ORDER_STATE = useSelector(state => state.order);
  const [open, setOpen] = useState(false);
  const [currentProductId, setCurrentProductId] = useState(null);
  const [selectedProductType, setSelectedProductType] = useState(null);
  const [editItem, setEditItem] = useState(false);
  const userProductTypes = ORDER_STATE?.products && userPriceVersion && Object?.keys(find(ORDER_STATE.products, product => product.price_version === userPriceVersion))?.slice(1);

  const handleUpdateSubscriptionProductSuccess = () => {
    dispatch(getCustomer({ id: CUSTOMER_STATE.customer._id }));
    setCurrentProductId(null);
    setSelectedProductType(null);
    enqueueSnackbar("Product updated successfully", { variant: "success" });
  };
  const handleUpdateSubscriptionProductFailure = ({ error }) => {
    enqueueSnackbar(error, { variant: "error" });
  };

  const handleUpdateProduct = () => {
    dispatch(
      updateSubscriptionProduct(
        { userId: CUSTOMER_STATE.customer._id, currentProductId, selectedProductType },
        handleUpdateSubscriptionProductSuccess,
        handleUpdateSubscriptionProductFailure
      )
    );
  };

  const handleCloseDialog = () => {
    setCurrentProductId(null);
    setSelectedProductType(null);
  };

  const handleDeleteItem = itemId => {
    const handleRemoveItemSuccess = () => {
      dispatch(getOrder({ id: ORDER_STATE.order._id, type: "purchase-order" }));
      enqueueSnackbar("Item removed successfully", { variant: "success" });
    };
    const handleRemoveItemFailure = error => {
      enqueueSnackbar(error, { variant: "error" });
    };

    dispatch(removeItem({ orderType: "purchase-order", itemId, handleRemoveItemSuccess, handleRemoveItemFailure }));
  };

  const handleCloseItemDialog = () => {
    setEditItem(null);
  };
  const handleCloseSuccessItemDialog = () => {
    const { id } = params;
    setEditItem(null);
    dispatch(getOrder({ id, type: "purchase-order" }));
    enqueueSnackbar("Item Edited Successfully", { variant: "success" });
  };

  return (
    <React.Fragment>
      {editItem && (
        <ItemAddDialog
          editItem={editItem}
          handleCloseItemDialog={handleCloseItemDialog}
          handleCloseSuccessItemDialog={handleCloseSuccessItemDialog}
          orderId={ORDER_STATE.order._id}
        />
      )}
      {allowEditProduct && currentProductId && (
        <div>
          <Dialog open={currentProductId} onClose={handleCloseDialog}>
            <DialogContent>
              <DialogContentText color="black">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                    {userProductTypes?.map(productType => (
                      <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell scope="row">
                          <img style={{ height: "70px" }} alt="product" src={generateProductUrl(productType)} />
                        </TableCell>
                        <TableCell align="right">
                          {getProductDetailsByProductType(ORDER_STATE.allPriceVersionProducts[CUSTOMER_STATE.customer.price_version], productType).name}
                        </TableCell>
                        <TableCell align="right">
                          {selectedProductType === productType ? (
                            <Button variant="contained" color="primary">
                              Selected
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              color="primary"
                              disabled={productType === "box"}
                              onClick={() => {
                                setSelectedProductType(productType);
                              }}
                            >
                              Change
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" disabled={CUSTOMER_STATE.updateSubscriptionProductLoading} onClick={handleCloseDialog}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={handleUpdateProduct} disabled={CUSTOMER_STATE.updateSubscriptionProductLoading}>
                {CUSTOMER_STATE.updateSubscriptionProductLoading ? "Loading" : "Update"}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell size="medium">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>

        <TableCell size="medium" component="th" scope="row">
          {row._id}
        </TableCell>
        {fromScreen ? (
          <TableCell align="center" size="medium">
            <Link to={`/admin/orders/${row.order_id._id}`}>
              <div style={{ fontWeight: "400" }}>{row.order_id._id}</div>
            </Link>
          </TableCell>
        ) : null}
        <TableCell align="center" size="medium">
          {row.product_type_id.name}
        </TableCell>
        {showIsReturnedColumn && (
          <TableCell align="center" size="medium">
            {row.return ? "Yes" : "No"}
          </TableCell>
        )}
        <TableCell align="center" size="medium">
          {row.items.length}
        </TableCell>
        {allowEditProduct && (
          <TableCell align="center" size="medium">
            <Button
              variant="outlined"
              color="primary"
              startIcon={<EditIcon />}
              disabled={row.product_type_id.type === "box" || row.return}
              onClick={() => {
                setCurrentProductId(row._id);
                setSelectedProductType(row.product_type_id.type);
              }}
            >
              Edit Product
            </Button>
          </TableCell>
        )}
      </TableRow>
      {row.items.length > 0 && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, padding: "0px 74px" }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1, padding: "50px" }}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Content Name</TableCell>
                      <TableCell align="center">Quantity</TableCell>
                      <TableCell align="center">Barcode IDs</TableCell>
                      <TableCell align="center">Return ID</TableCell>
                      {(showItemDeleteColumn || showItemEditColumn) && <TableCell align="center">Action</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.items.map(item => (
                      <TableRow key={item._id}>
                        <TableCell component="th" scope="row">
                          {item._id}
                        </TableCell>
                        <TableCell>{item.content_name}</TableCell>
                        <TableCell align="center">{item.quantity}</TableCell>
                        <TableCell align="center">
                          {item?.sub_items?.length > 0
                            ? item.sub_items.map((sub_item, index) => (
                                <div key={index}>
                                  <a href={sub_item.barcode_image_url} target="_blank" rel="noopener noreferrer">
                                    {sub_item.barcode_id}
                                  </a>
                                </div>
                              ))
                            : "-"}
                        </TableCell>
                        <TableCell align="center">{item.return_id ? item.return_id : "-"}</TableCell>
                        {(showItemDeleteColumn || showItemEditColumn) && (
                          <TableCell align="center" size="medium">
                            {showItemDeleteColumn && (
                              <IconButton aria-label="expand row" size="small" onClick={() => handleDeleteItem(item._id)}>
                                <DeleteIcon />
                              </IconButton>
                            )}
                            {showItemEditColumn && !item?.sub_items?.[0]?.barcode_id && (
                              <IconButton aria-label="expand row" size="small" onClick={() => setEditItem(item)}>
                                <EditIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}
