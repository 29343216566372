import {
  GET_EXTRA_DETAILS,
  GET_EXTRA_DETAILS_SUCCESS,
  GET_EXTRA_DETAILS_FAILURE,
  GET_CARRIERS,
  GET_CARRIERS_SUCCESS,
  GET_CARRIERS_FAILURE,
  GET_WAREHOUSES,
  GET_WAREHOUSES_SUCCESS,
  GET_WAREHOUSES_FAILURE,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILURE,
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,
  GET_RETURN_ORDERS,
  GET_RETURN_ORDERS_SUCCESS,
  GET_RETURN_ORDERS_FAILURE,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILURE,
  GET_SHIPPING_COST,
  GET_SHIPPING_COST_SUCCESS,
  GET_SHIPPING_COST_FAILURE,
  UPDATE_SHIPPING_COST,
  UPDATE_SHIPPING_COST_SUCCESS,
  UPDATE_SHIPPING_COST_FAILURE,
  GET_RETURN_COST,
  GET_RETURN_COST_SUCCESS,
  GET_RETURN_COST_FAILURE,
  UPDATE_RETURN_COST,
  UPDATE_RETURN_COST_SUCCESS,
  UPDATE_RETURN_COST_FAILURE,
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  CREATE_PRODUCT_PRICES,
  CREATE_PRODUCT_PRICES_SUCCESS,
  CREATE_PRODUCT_PRICES_FAILURE,
  DELETE_PRODUCT_ITEM,
  DELETE_PRODUCT_ITEM_SUCCESS,
  DELETE_PRODUCT_ITEM_FAILURE
} from "./actionType";
import axios from "../../config/axios";

//FETCH EXTRA DETAILS
const fetchExtraDetails = payload => {
  return {
    type: GET_EXTRA_DETAILS,
    payload: payload
  };
};
const fetchExtraDetailsSuccess = payload => {
  return {
    type: GET_EXTRA_DETAILS_SUCCESS,
    payload: payload
  };
};
const fetchExtraDetailsFailure = payload => {
  return {
    type: GET_EXTRA_DETAILS_FAILURE,
    payload: payload
  };
};
export const getExtraDetails = () => async dispatch => {
  dispatch(fetchExtraDetails());
  axios
    .get(`/extra-details`)
    .then(res => {
      dispatch(
        fetchExtraDetailsSuccess({
          extraDetails: res.data.data.extraDetails
        })
      );
    })
    .catch(err => {
      dispatch(fetchExtraDetailsFailure({ error: err.response.data.message }));
    });
};

//FETCH CARRIERS
const fetchCarriers = payload => {
  return {
    type: GET_CARRIERS,
    payload: payload
  };
};
const fetchCarriersSuccess = payload => {
  return {
    type: GET_CARRIERS_SUCCESS,
    payload: payload
  };
};
const fetchCarriersFailure = payload => {
  return {
    type: GET_CARRIERS_FAILURE,
    payload: payload
  };
};
export const getCarriers = () => async dispatch => {
  dispatch(fetchCarriers());
  axios
    .get(`/admin/carriers`)
    .then(res => {
      dispatch(
        fetchCarriersSuccess({
          carriers: res.data.data.carriers
        })
      );
    })
    .catch(err => {
      dispatch(fetchCarriersFailure({ error: err.response.data.message }));
    });
};

//FETCH CARRIERS
const fetchWarehouses = payload => {
  return {
    type: GET_WAREHOUSES,
    payload: payload
  };
};
const fetchWarehousesSuccess = payload => {
  return {
    type: GET_WAREHOUSES_SUCCESS,
    payload: payload
  };
};
const fetchWarehousesFailure = payload => {
  return {
    type: GET_WAREHOUSES_FAILURE,
    payload: payload
  };
};
export const getWarehouses = () => async dispatch => {
  dispatch(fetchWarehouses());
  axios
    .get(`/admin/warehouses`)
    .then(res => {
      dispatch(
        fetchWarehousesSuccess({
          warehouses: res.data.data.warehouses
        })
      );
    })
    .catch(err => {
      dispatch(fetchWarehousesFailure({ error: err.response.data.message }));
    });
};

//FETCH ORDER
const fetchOrder = payload => {
  return {
    type: GET_ORDER,
    payload: payload
  };
};
const fetchOrderSuccess = payload => {
  return {
    type: GET_ORDER_SUCCESS,
    payload: payload
  };
};
const fetchOrderFailure = payload => {
  return {
    type: GET_ORDER_FAILURE,
    payload: payload
  };
};
export const getOrder = ({ id, type }, handleSuccess) => async dispatch => {
  dispatch(fetchOrder());
  axios
    .get(`/admin/order/${type}/${id}`)
    .then(res => {
      dispatch(fetchOrderSuccess({ order: res.data.data.order }));
      handleSuccess && handleSuccess({ order: res.data.data.order });
    })
    .catch(err => {
      dispatch(fetchOrderFailure({ error: err.response?.data?.message }));
    });
};

//FETCH ORDERS
const fetchOrders = payload => {
  return {
    type: GET_ORDERS,
    payload: payload
  };
};
const fetchOrdersSuccess = payload => {
  return {
    type: GET_ORDERS_SUCCESS,
    payload: payload
  };
};

const fetchOrdersFailure = payload => {
  return {
    type: GET_ORDERS_FAILURE,
    payload: payload
  };
};
export const getOrders = ({ rowsPerPageChanged = false }) => async dispatch => {
  dispatch(fetchOrders({ rowsPerPageChanged }));
  axios
    .get(`/admin/orders`)
    .then(res => {
      dispatch(fetchOrdersSuccess({ orders: [...res.data.data.orders], total: res.data.data.total }));
    })
    .catch(err => {
      dispatch(fetchOrdersFailure({ error: err.response.data.message }));
    });
};

//FETCH RETURN ORDERS
const fetchReturnOrders = payload => {
  return {
    type: GET_RETURN_ORDERS,
    payload: payload
  };
};
const fetchReturnOrdersSuccess = payload => {
  return {
    type: GET_RETURN_ORDERS_SUCCESS,
    payload: payload
  };
};

const fetchReturnOrdersFailure = payload => {
  return {
    type: GET_RETURN_ORDERS_FAILURE,
    payload: payload
  };
};
export const getReturnOrders = ({ rowsPerPageChanged = false }) => async dispatch => {
  dispatch(fetchReturnOrders({ rowsPerPageChanged }));
  axios
    .get(`/admin/return-orders`)
    .then(res => {
      dispatch(fetchReturnOrdersSuccess({ returnOrders: [...res.data.data.orders], total: res.data.data.total }));
    })
    .catch(err => {
      dispatch(fetchReturnOrdersFailure({ error: err.response.data.message }));
    });
};

//UPDATE ORDER
const updateOrder1 = payload => {
  return {
    type: UPDATE_ORDER,
    payload: payload
  };
};
const updateOrderSuccess = payload => {
  return {
    type: UPDATE_ORDER_SUCCESS,
    payload: payload
  };
};
const updateOrderFailure = payload => {
  return {
    type: UPDATE_ORDER_FAILURE,
    payload: payload
  };
};
export const updateOrder = ({ updatedOrder, order_id, type }, handleupdateOrderSuccess, handleUpdateOrderFailure) => async dispatch => {
  dispatch(updateOrder1());
  axios
    .patch(`/admin/update-order/${type}/${order_id}`, updatedOrder)
    .then(res => {
      const order = res.data.data.updated_order;
      handleupdateOrderSuccess();
      dispatch(updateOrderSuccess({ order_id: order._id, type, shouldUpdateOrderList: updatedOrder?.status || updatedOrder?.carrier_id }));
    })
    .catch(err => {
      handleUpdateOrderFailure({ error: err.response.data.message });
      dispatch(updateOrderFailure({ error: err.response.data.message }));
    });
};

//GET_SHIPPING_COST
const fetchShippingCost = payload => {
  return {
    type: GET_SHIPPING_COST,
    payload: payload
  };
};
const fetchShippingCostSuccess = payload => {
  return {
    type: GET_SHIPPING_COST_SUCCESS,
    payload: payload
  };
};
const fetchShippingCostFailure = payload => {
  return {
    type: GET_SHIPPING_COST_FAILURE,
    payload: payload
  };
};
export const getShippingCosts = () => async dispatch => {
  dispatch(fetchShippingCost());
  axios
    .get(`/admin/shipping-costs`)
    .then(res => {
      dispatch(fetchShippingCostSuccess({ shippingCosts: res.data.data.shippingCosts }));
    })
    .catch(err => {
      dispatch(fetchShippingCostFailure({ error: err.response.data.message }));
    });
};

//UPDATE_SHIPPING_COST
const updateShippingCost1 = payload => {
  return {
    type: UPDATE_SHIPPING_COST,
    payload: payload
  };
};
const updateShippingCostSuccess = payload => {
  return {
    type: UPDATE_SHIPPING_COST_SUCCESS,
    payload: payload
  };
};
const updateShippingCostFailure = payload => {
  return {
    type: UPDATE_SHIPPING_COST_FAILURE,
    payload: payload
  };
};
export const updateShippingCost = ({ newCosts, handleUpdateCostSuccess, handleUpdateCostFailure }) => async dispatch => {
  dispatch(updateShippingCost1());
  axios
    .patch(`/admin/set-shipping-cost`, { shipping_cost: newCosts })
    .then(res => {
      dispatch(updateShippingCostSuccess({ shippingCosts: res.data.data.shippingCosts }));
      handleUpdateCostSuccess();
    })
    .catch(err => {
      dispatch(updateShippingCostFailure({ error: err.response.data.message }));
      handleUpdateCostFailure();
    });
};

//GET_RETURN_COST
const fetchReturnCost = payload => {
  return {
    type: GET_RETURN_COST,
    payload: payload
  };
};
const fetchReturnCostSuccess = payload => {
  return {
    type: GET_RETURN_COST_SUCCESS,
    payload: payload
  };
};
const fetchReturnCostFailure = payload => {
  return {
    type: GET_RETURN_COST_FAILURE,
    payload: payload
  };
};
export const getReturnCosts = () => async dispatch => {
  dispatch(fetchReturnCost());
  axios
    .get(`/admin/return-cost`)
    .then(res => {
      dispatch(fetchReturnCostSuccess({ returnCosts: res.data.data.returnCosts }));
    })
    .catch(err => {
      dispatch(fetchReturnCostFailure({ error: err.response.data.message }));
    });
};

//UPDATE_RETURN_COST
const updateReturnCost1 = payload => {
  return {
    type: UPDATE_RETURN_COST,
    payload: payload
  };
};
const updateReturnCostSuccess = payload => {
  return {
    type: UPDATE_RETURN_COST_SUCCESS,
    payload: payload
  };
};
const updateReturnCostFailure = payload => {
  return {
    type: UPDATE_RETURN_COST_FAILURE,
    payload: payload
  };
};
export const updateReturnCost = ({ newCosts, handleUpdateCostSuccess, handleUpdateCostFailure }) => async dispatch => {
  dispatch(updateReturnCost1());
  axios
    .patch(`/admin/set-shipping-cost`, { shipping_cost: newCosts })
    .then(res => {
      dispatch(updateReturnCostSuccess({ shippingCosts: res.data.data.shippingCosts }));
      handleUpdateCostSuccess();
    })
    .catch(err => {
      dispatch(updateReturnCostFailure({ error: err.response.data.message }));
      handleUpdateCostFailure();
    });
};

//GET_RETURN_COST
const fetchProducts = payload => {
  return {
    type: GET_PRODUCTS,
    payload: payload
  };
};
const fetchProductsSuccess = payload => {
  return {
    type: GET_PRODUCTS_SUCCESS,
    payload: payload
  };
};
const fetchProductsFailure = payload => {
  return {
    type: GET_PRODUCTS_FAILURE,
    payload: payload
  };
};
export const getProducts = () => async dispatch => {
  dispatch(fetchProducts());
  axios
    .get(`/admin/product-types`)
    .then(res => {
      const first10PriceVersions = {};
      [...Array(10).keys()].forEach(num => {
        first10PriceVersions[`V${num}`] = `V0${num}`;
      });
      dispatch(
        fetchProductsSuccess({
          products: res.data.data.prices.map(priceVersion => {
            return first10PriceVersions[priceVersion.price_version] ? { ...priceVersion, price_version: first10PriceVersions[priceVersion.price_version] } : priceVersion;
          }),
          allPriceVersionProducts: res.data.data.categarizedProducts
        })
      );
    })
    .catch(err => {
      dispatch(fetchProductsFailure({ error: err.response.data.message }));
    });
};

//GET_RETURN_COST
const newProductPrices = payload => {
  return {
    type: CREATE_PRODUCT_PRICES,
    payload: payload
  };
};
const createProductPricesSuccess = payload => {
  return {
    type: CREATE_PRODUCT_PRICES_SUCCESS,
    payload: payload
  };
};
const createProductPricesFailure = payload => {
  return {
    type: CREATE_PRODUCT_PRICES_FAILURE,
    payload: payload
  };
};
export const createProductprices = ({ prices, handleCreateCostSuccess, handleCreateCostFailure }) => async dispatch => {
  dispatch(newProductPrices());
  axios
    .post(`/admin/product-types`, { prices })
    .then(res => {
      dispatch(createProductPricesSuccess({}));
      handleCreateCostSuccess && handleCreateCostSuccess();
    })
    .catch(err => {
      dispatch(createProductPricesFailure({ error: err.response.data.message }));
      handleCreateCostFailure && handleCreateCostFailure(err.response.data.message);
    });
};

//RETURN_ITEM
const deleteItem = payload => {
  return {
    type: DELETE_PRODUCT_ITEM,
    payload: payload
  };
};
const deleteItemSuccess = payload => {
  return {
    type: DELETE_PRODUCT_ITEM_SUCCESS,
    payload: payload
  };
};
const deleteItemFailure = payload => {
  return {
    type: DELETE_PRODUCT_ITEM_FAILURE,
    payload: payload
  };
};

export const removeItem = ({ orderType, itemId, handleRemoveItemSuccess, handleRemoveItemFailure }) => async dispatch => {
  dispatch(deleteItem());
  axios
    .delete(`/admin/order/remove-item/${orderType}/${itemId}`)
    .then(res => {
      dispatch(deleteItemSuccess({}));
      handleRemoveItemSuccess && handleRemoveItemSuccess();
    })
    .catch(err => {
      dispatch(deleteItemFailure({ error: err.response.data.message }));
      handleRemoveItemFailure && handleRemoveItemFailure(err.response.data.message);
    });
};
