import React from "react";
import { Route } from "react-router-dom";

import WarehouseList from "components/WarehouseList/index";

export default function Subscriptions() {
  return (
    <div>
      <Route path={`/admin/warehouses`} exact={true} component={WarehouseList} />
    </div>
  );
}
