import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

// core components
import NoData from "assets/img/NoData.svg";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import MaterialDataGridTable from "../MaterialDataGridTable/index";
import SubscriptionStatusChip from "./SubscriptionStatusChip";

//NPM PACKAGES
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
//FUNCTIONS
import { getSubscriptions } from "redux/action/customer.action";
import Loading from "components/Loading";
import { getCurrentSubscriptionId, getCurrentSubscriptionURL } from "./HelperFunction";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px"
  }
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  //VARIABLES
  const history = useHistory();
  const dispatch = useDispatch();
  const CUSTOMER_STATE = useSelector(state => state.customer);
  let subscriptions = CUSTOMER_STATE.subscriptions;
  const [rows, setRows] = React.useState([]);
  const columns = [
    {
      field: "id",
      headerName: "Subscription ID",
      sortable: true,
      width: 280,
      // disableClickEventBubbling: true,
      renderCell: params => {
        return (
          <a href={getCurrentSubscriptionURL(params.row)} target="_blank" rel="noopener noreferrer">
            <div style={{ fontWeight: "400", color: "blue" }}>{getCurrentSubscriptionId(params.row)}</div>
          </a>
        );
      }
    },
    {
      field: "user_id",
      headerName: "User ID",
      width: 130,
      type: "number",
      headerAlign: "left",
      align: "left",
      disableColumnMenu: false,
      renderCell: params => {
        return (
          <button style={{ background: "none", border: "none", color: "blue", cursor: "pointer" }} onClick={() => openUserDetail(params.row.user_id)}>
            {params.row.user_id}
          </button>
        );
      }
    },
    {
      field: "user_name",
      headerName: "Name",
      width: 120,
      headerAlign: "left",
      align: "left",
      editable: false
    },
    {
      field: "sripe_sub_status",
      headerName: "Status",
      width: 120,
      headerAlign: "left",
      align: "left",
      editable: false,
      renderCell: params => {
        return (
          <SubscriptionStatusChip
            stripe_subscription_schedule_id={params.row?.stripe_subscription_schedule_id}
            stripe_subscription_schedule_status={params.row?.stripe_subscription_schedule_status}
            stripe_subscription_status={params.row?.stripe_subscription_status}
          />
        );
      }
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 120,
      editable: false,
      headerAlign: "left",
      align: "left",
      type: "number",
      disableColumnMenu: true,
      renderCell: params => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
            {params.row?.amount ? params.row.amount + " €" : "-"}
          </div>
        );
      }
    },
    {
      field: "products",
      headerName: "Products",
      width: 350,
      editable: false,
      headerAlign: "left",
      align: "left"
    },
    {
      field: "created_at",
      headerName: "Create Date",
      width: 150,
      editable: false,
      headerAlign: "left",
      align: "left",
      type: "date",
      valueFormatter: params => moment(params?.value).format("YYYY-MM-DD")
    },
    {
      field: "start_date",
      headerName: "Start Date",
      width: 150,
      editable: false,
      headerAlign: "left",
      align: "left",
      type: "date"
      // valueFormatter: params => moment(params?.value).format("YYYY-MM-DD")
    },
    {
      field: "next_payment",
      headerName: "Next Payment",
      width: 170,
      editable: false,
      headerAlign: "left",
      align: "left",
      type: "date"
      // valueFormatter: params => moment(params?.value).format("YYYY-MM-DD")
    }
  ];

  //FUNCTIONS
  const handleDSubscriptionsAsColumn = async subscriptions => {
    return subscriptions.map(subscription => {
      return {
        id: subscription._id,
        stripe_subscription_id: subscription.stripe_subscription_id,
        stripe_subscription_status: subscription.stripe_subscription_status,
        stripe_subscription_schedule_id: subscription.stripe_subscription_schedule_id,
        stripe_subscription_schedule_status: subscription.stripe_subscription_schedule_status,
        user_id: subscription.user_id,
        user_name: subscription?.user_name,
        status: subscription?.status,
        sripe_sub_status: subscription?.stripe_subscription_schedule_id ? subscription?.stripe_subscription_schedule_status : subscription?.stripe_subscription_status,
        amount: subscription?.amount && +subscription.amount?.value,
        products: subscription?.products,
        created_at: moment(subscription?.created * 1000).format("YYYY-MM-DD"),
        start_date: subscription?.current_period_start ? moment(subscription.current_period_start * 1000).format("YYYY-MM-DD") : "NA",
        next_payment: subscription?.current_period_end ? moment(subscription.current_period_end * 1000).format("YYYY-MM-DD") : "NA"
      };
    });
  };
  useEffect(() => {
    if (!subscriptions) {
      dispatch(getSubscriptions({}));
    }
  }, [subscriptions, dispatch]);

  useEffect(() => {
    if (subscriptions && subscriptions.length > 0) {
      (async () => {
        const rows = await handleDSubscriptionsAsColumn(subscriptions);
        setRows(rows);
      })();
    }
  }, [subscriptions]); // eslint-disable-line

  const openUserDetail = id => {
    history.push(`/admin/customers/${id}`);
  };

  //CONSOLE LOG

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <div className={classes.root}>
          {CUSTOMER_STATE.fetchingSubscriptionsLoading && isEmpty(subscriptions) ? (
            <div className={classes.loading}>
              <Loading />
            </div>
          ) : (
            <div>
              {subscriptions && subscriptions.length > 0 ? (
                <div>
                  <MaterialDataGridTable
                    rows={rows}
                    columns={columns}
                    data={subscriptions}
                    setRows={setRows}
                    handleDataAsColumn={handleDSubscriptionsAsColumn}
                    tableName="SUBSCRIPTIONS_TABLE"
                  />
                </div>
              ) : (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <img src={NoData} alt="" />
                  <label style={{ fontSize: "larger", padding: "20px" }}>There is no any Subscription</label>
                </div>
              )}
            </div>
          )}
        </div>
      </GridItem>
    </GridContainer>
  );
}
