import {
  GET_SHIPPING_COST,
  GET_SHIPPING_COST_SUCCESS,
  GET_SHIPPING_COST_FAILURE,
  UPDATE_SHIPPING_COST,
  UPDATE_SHIPPING_COST_SUCCESS,
  UPDATE_SHIPPING_COST_FAILURE,
  GET_TRIAL_PERIOD_DAYS,
  GET_TRIAL_PERIOD_DAYS_FAILURE,
  GET_TRIAL_PERIOD_DAYS_SUCCESS,
  UPDATE_TRIAL_PERIOD_DAYS,
  UPDATE_TRIAL_PERIOD_DAYS_SUCCESS,
  UPDATE_TRIAL_PERIOD_DAYS_FAILURE
} from "./actionType";
import axios from "../../config/axios";

const fetchShippingCost = payload => {
  return {
    type: GET_SHIPPING_COST,
    payload: payload
  };
};

const fetchShippingCostSuccess = payload => {
  return {
    type: GET_SHIPPING_COST_SUCCESS,
    payload: payload
  };
};

const fetchShippingCostFailure = payload => {
  return {
    type: GET_SHIPPING_COST_FAILURE,
    payload: payload
  };
};

export const getShippingCost = () => async dispatch => {
  dispatch(fetchShippingCost());
  axios
    .get(`/admin/shipping-cost`)
    .then(res => {
      dispatch(
        fetchShippingCostSuccess({
          shippingCost: res.data.data.shipping_cost
        })
      );
    })
    .catch(err => {
      dispatch(fetchShippingCostFailure({ error: err.response.data.message }));
    });
};

const updateShippingCost = payload => {
  return {
    type: UPDATE_SHIPPING_COST,
    payload: payload
  };
};

const updateShippingCostSuccess = payload => {
  return {
    type: UPDATE_SHIPPING_COST_SUCCESS,
    payload: payload
  };
};

const updateShippingCostFailure = payload => {
  return {
    type: UPDATE_SHIPPING_COST_FAILURE,
    payload: payload
  };
};

export const setNewShippingCost = ({ shippingCost, handleUpdateCostSuccess, handleUpdateCostFailure }) => async dispatch => {
  dispatch(updateShippingCost());
  axios
    .patch(`/admin/shipping-cost`, { shipping_cost: +shippingCost })
    .then(res => {
      dispatch(
        updateShippingCostSuccess({
          shippingCost: +res.data.data.shipping_cost
        })
      );
      handleUpdateCostSuccess();
    })
    .catch(err => {
      dispatch(updateShippingCostFailure({ error: err.response.data.message }));
      handleUpdateCostFailure();
    });
};

const fetchTrialPeriodDays = payload => {
  return {
    type: GET_TRIAL_PERIOD_DAYS,
    payload: payload
  };
};

const fetchTrialPeriodDaysSuccess = payload => {
  return {
    type: GET_TRIAL_PERIOD_DAYS_SUCCESS,
    payload: payload
  };
};

const fetchTrialPeriodDaysFailure = payload => {
  return {
    type: GET_TRIAL_PERIOD_DAYS_FAILURE,
    payload: payload
  };
};

export const getTrialPeriodDays = () => async dispatch => {
  dispatch(fetchTrialPeriodDays());
  axios
    .get(`/admin/trial-period-days`)
    .then(res => {
      dispatch(
        fetchTrialPeriodDaysSuccess({
          trialPeriodDays: res.data.data.trial_period_days
        })
      );
    })
    .catch(err => {
      dispatch(fetchTrialPeriodDaysFailure({ error: err.response.data.message }));
    });
};

const updateTrialPeriodDays = payload => {
  return {
    type: UPDATE_TRIAL_PERIOD_DAYS,
    payload: payload
  };
};

const updateTrialPeriodDaysSuccess = payload => {
  return {
    type: UPDATE_TRIAL_PERIOD_DAYS_SUCCESS,
    payload: payload
  };
};

const updateTrialPeriodDaysFailure = payload => {
  return {
    type: UPDATE_TRIAL_PERIOD_DAYS_FAILURE,
    payload: payload
  };
};

export const setNewTrialPeriodDays = ({ trialPeriodDays, handleUpdateCostSuccess, handleUpdateCostFailure }) => async dispatch => {
  dispatch(updateTrialPeriodDays());
  axios
    .patch(`/admin/trial-period-days`, { trial_period_days: +trialPeriodDays })
    .then(res => {
      dispatch(
        updateTrialPeriodDaysSuccess({
          trialPeriodDays: res.data.data.trial_period_days
        })
      );
      handleUpdateCostSuccess();
    })
    .catch(err => {
      dispatch(updateTrialPeriodDaysFailure({ error: err.response.data.message }));
      handleUpdateCostFailure();
    });
};
