import React, { useState } from "react";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";

import { useDispatch, useSelector } from "react-redux";
import { find, uniq } from "lodash";
import { Button, Tooltip } from "@material-ui/core";
import { updateSubscriptionItems } from "redux/action/customer.action";
import { useSnackbar } from "notistack";
import { getCustomer } from "redux/action/customer.action";
import { HelpOutline } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 700
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px 15px 15px 30px",
    borderBottom: "1px solid lightgrey"
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0px 30px 30px 0px"
  },
  title: {
    display: "flex",
    alignItems: "center",
    fontWeight: "500",
    fontSize: "27px",
    lineHeight: "1.23"
  }
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

export default function ProductItems() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [changeItems, setChangeItems] = useState({});
  const [itemsEditable, setitemsEditable] = useState(false);
  const CUSTOMER_STATE = useSelector(state => state.customer);
  const subscriptionItems = CUSTOMER_STATE.customer.subscription_items;
  const subscriptionProducts = CUSTOMER_STATE.customer.subscription_products;

  const productIds = uniq(subscriptionProducts.map(product => product._id));

  const handleChangeProduct = ({ itemId, currentProductId, newProductId }) => {
    if (currentProductId === newProductId) {
      delete changeItems[itemId];
      setChangeItems({ ...changeItems });
    } else {
      setChangeItems({ ...changeItems, [itemId]: { itemId, currentProductId, newProductId } });
    }
  };

  const getProductIdWithName = productId => {
    const product = find(subscriptionProducts, product => product._id === productId);
    return `${product._id}, ${product.product_type_id.name}`;
  };

  const handleupdateCustomerSuccess = () => {
    enqueueSnackbar("Items updated successfully", { variant: "success" });
    dispatch(getCustomer({ id: CUSTOMER_STATE.customer._id }));
  };

  const handleupdateCustomerFailure = error => {
    enqueueSnackbar(error, { variant: "error" });
  };

  const saveItems = () => {
    dispatch(
      updateSubscriptionItems(
        { items: Object.values(changeItems), subscriptionId: CUSTOMER_STATE.customer.stripe_subscription_id },
        handleupdateCustomerSuccess,
        handleupdateCustomerFailure
      )
    );
  };
  return (
    <React.Fragment>
      <div className={classes.header}>
        <div style={{ display: "flex" }}>
          <div className={classes.title}>Items</div>
          <Tooltip title="It is required for every product to include at least one item, while Caixa is limited to only having one.">
            <IconButton>
              <HelpOutline />
            </IconButton>
          </Tooltip>
        </div>
        <Button color="primary" variant="outlined" onClick={() => setitemsEditable(true)}>
          Edit
        </Button>
      </div>
      <div className="table">
        <TableContainer component={Paper}>
          <Table aria-label="customized table" size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell>ID</StyledTableCell>
                <StyledTableCell align="center">Content Name</StyledTableCell>
                <StyledTableCell align="center">Quantity</StyledTableCell>
                <StyledTableCell align="center">Barcode IDs</StyledTableCell>
                <StyledTableCell align="center">Product</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptionItems.map(row => (
                <TableRow key={row._id}>
                  <TableCell component="th" scope="row">
                    {row._id}
                  </TableCell>
                  <TableCell>{row.content_name}</TableCell>
                  <TableCell align="center">{row.quantity}</TableCell>
                  <TableCell align="center">
                    {row?.sub_items?.length > 0
                      ? row.sub_items.map((sub_item, index) => (
                          <div key={index}>
                            <a href={sub_item.barcode_image_url} target="_blank" rel="noopener noreferrer">
                              {sub_item.barcode_id}
                            </a>
                          </div>
                        ))
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {itemsEditable ? (
                      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={changeItems[row._id]?.newProductId || row.product_id._id}
                          label="Id, Name"
                          onChange={event => handleChangeProduct({ itemId: row._id, currentProductId: row.product_id._id, newProductId: event.target.value })}
                        >
                          {productIds.map(productId => (
                            <MenuItem value={productId}>{getProductIdWithName(productId)}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <>
                        {row.product_id._id}
                        {", "}
                        <b>{row.product_id.product_type_id.name}</b>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {itemsEditable && (
        <div className={classes.footer}>
          <Button variant="contained" onClick={saveItems} disabled={CUSTOMER_STATE.updateSubscriptionItemsLoading}>
            {CUSTOMER_STATE.updateSubscriptionItemsLoading ? "Loading" : "Save"}
          </Button>
        </div>
      )}
    </React.Fragment>
  );
}
