import React, { useEffect } from "react";

//MATERIAL UI COMPONENTS
import { IconButton, makeStyles } from "@material-ui/core";
import { Visibility as VisibilityIcon } from "@material-ui/icons";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import NoData from "assets/img/NoData.svg";
import Loading from "components/Loading";
import MaterialDataGridTable from "../MaterialDataGridTable/index";

//NPM PACKAGES
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
//FUNCTIONS

import { getCustomers } from "redux/action/customer.action";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px"
  }
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  //VARIABLES
  const history = useHistory();
  const dispatch = useDispatch();
  const CUSTOMER_STATE = useSelector(state => state.customer);
  let customers = CUSTOMER_STATE.customers;
  const [rows, setRows] = React.useState([]);

  const columns = [
    {
      field: " ",
      headerName: " ",
      sortable: false,
      width: 70,
      disableClickEventBubbling: true,
      renderCell: params => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
            <IconButton m={0.5} aria-label="primary" onClick={() => openUserDetail(params.row.id)}>
              <VisibilityIcon style={{ paddingRight: "10px" }} />
            </IconButton>
          </div>
        );
      }
    },
    { field: "id", headerName: "ID", width: 90, type: "number", headerAlign: "left", align: "left", disableColumnMenu: true },
    {
      field: "created_at",
      headerName: "Date",
      width: 120,
      editable: false,
      headerAlign: "left",
      align: "left",
      type: "date",
      valueFormatter: params => moment(params?.value).format("YYYY-MM-DD")
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      headerAlign: "left",
      align: "left",
      editable: false
    },
    {
      field: "email",
      headerName: "Email",
      width: 450,
      headerAlign: "left",
      align: "left",
      editable: false
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
      editable: false,
      headerAlign: "left",
      align: "left"
    },
    {
      field: "subscribed_price",
      headerName: "Subscribed amount",
      width: 300,
      editable: false,
      headerAlign: "left",
      align: "left",
      type: "number",
      renderCell: params => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
            {params?.row?.subscribed_price ? params.row.subscribed_price + " €" : "-"}
          </div>
        );
      },
      disableColumnMenu: true
    },
    {
      field: "city",
      headerName: "City",
      width: 110,
      editable: false,
      headerAlign: "left",
      align: "left"
    },
    {
      field: "zipcode",
      headerName: "zipcode",
      width: 130,
      editable: false,
      headerAlign: "left",
      align: "left"
    }
  ];

  const handleCustomersAsColumn = async customers => {
    return customers.map(customer => {
      return {
        id: customer._id,
        created_at: moment(customer.created_at).format("YYYY-MM-DD"),
        name: customer.name,
        email: customer.email,
        phone: customer.phone,
        subscribed_price: customer.subscription && +customer.subscription.amount.value,

        city: customer.city,
        zipcode: customer.zipcode
      };
    });
  };

  //FUNCTIONS
  useEffect(() => {
    if (!customers) {
      dispatch(getCustomers({}));
    }
  }, [customers, dispatch]);

  useEffect(() => {
    if (customers && customers.length > 0) {
      (async () => {
        const rows = await handleCustomersAsColumn(customers);
        setRows(rows);
      })();
    }
  }, [customers]); // eslint-disable-line

  const openUserDetail = id => {
    history.push(`${history.location.pathname}/${id}`);
  };

  //CONSOLE LOG
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <div className={classes.root}>
          {CUSTOMER_STATE.fetchingCustomersLoading && isEmpty(customers) ? (
            <div className={classes.loading}>
              <Loading />
            </div>
          ) : (
            <div>
              {customers && customers.length > 0 ? (
                <div>
                  <MaterialDataGridTable
                    rows={rows}
                    columns={columns}
                    data={customers}
                    setRows={setRows}
                    handleDataAsColumn={handleCustomersAsColumn}
                    tableName="CUSTOMERS_TABLE"
                  />
                </div>
              ) : (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <img src={NoData} alt="" />
                  <label style={{ fontSize: "larger", padding: "20px" }}>There is no any Customer</label>
                </div>
              )}
            </div>
          )}
        </div>
      </GridItem>
    </GridContainer>
  );
}
