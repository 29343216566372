import axios from "axios";
const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
  headers: {
    common: {
      "Content-Type": "application/json"
    }
  }
});

instance.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("user-token")}`;
  return config;
});

export default instance;
