import React from "react";
import { Route } from "react-router-dom";

//core components
import OrderList from "components/Order/index";
import OrderDetails from "components/OrderDetails/OrderDetails";

export default function OrdersPage() {
  return (
    <div>
      <Route path={`/admin/orders/:id`} exact={true} component={OrderDetails} />
      <Route path={`/admin/orders`} exact={true} component={OrderList} />
    </div>
  );
}
