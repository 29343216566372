import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

function Loading(props) {
  return (
    <div
      style={{
        margin: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <CircularProgress />
    </div>
  );
}

export default Loading;
