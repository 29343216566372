import React from "react";
import { Route } from "react-router-dom";

//core components
import SubscriptionList from "components/Subscription/index";

export default function Subscriptions() {
  return (
    <div>
      <Route path={`/admin/subscriptions`} exact={true} component={SubscriptionList} />
    </div>
  );
}
