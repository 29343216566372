import React, { useState } from "react";
import { map, every, find } from "lodash";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import "./AddProductPrices.css";
import { TextField, Table, TableBody, TableCell, TableHead, TableRow, InputAdornment, Chip, Button } from "@material-ui/core";
import { createProductprices, getProducts } from "../../redux/action/order.action";
import { useHistory } from "react-router-dom";
const imageLists = [
  { e_name: "box", name: "Caixa" },
  { e_name: "space_1", name: "0.25m²" },
  { e_name: "space_2", name: "0.50m²" },
  { e_name: "space_v2_01", name: "1m²" },
  { e_name: "space_v2_02", name: "2m²" },
  { e_name: "space_v2_04", name: "4m²" },
  { e_name: "space_v2_06", name: "6m²" },
  { e_name: "space_v2_08", name: "8m²" },
  { e_name: "space_v2_10", name: "10m²" },
  { e_name: "space_v2_16", name: "16m²" },
  { e_name: "space_v2_25", name: "25m²" },

  { e_name: "space_3", name: "3m³" },
  { e_name: "space_4", name: "6m³" },
  { e_name: "space_5", name: "9m³" }
];
const productTypes = ["box", "space_1", "space_2", "space_v2_01", "space_v2_02", "space_v2_04", "space_v2_06", "space_v2_08", "space_v2_10", "space_v2_16", "space_v2_25"];

const AddProductPrices = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const ORDER_STATE = useSelector(state => state.order);

  const [newCosts, setNewCosts] = useState({});
  const isSaveButtonEnable = every(productTypes, productType => newCosts?.[productType] > 0);

  const onChangeCost = (type, value) => {
    setNewCosts({ ...newCosts, [type]: Math.abs(value) });
  };
  const handleCreateCostSuccess = () => {
    enqueueSnackbar("Prices created successfully", { variant: "success" });
    dispatch(getProducts());
    history.push("/admin/products");
  };
  const handleCreateCostFailure = error => {
    enqueueSnackbar(error, { variant: "error" });
  };
  const handleSubmit = () => {
    dispatch(createProductprices({ prices: newCosts, handleCreateCostSuccess, handleCreateCostFailure }));
  };

  return (
    <div className="shipping-container">
      <div style={{ display: "block", overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow className="cost-table__head-row">
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>
                <Chip variant="outlined" style={{ background: "mediumturquoise" }} size="small" label="Price" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(productTypes, (productType, index1) => {
              let url = `https://keepwhat-cdn.s3.eu-west-2.amazonaws.com/img/all-products/${productType}.svg`;
              return (
                <TableRow className="cost-table__row" id={index1}>
                  <TableCell scope="row">
                    <img style={{ height: "70px" }} alt="product" src={url} />
                  </TableCell>
                  <TableCell scope="row">{find(imageLists, ele => ele.e_name === productType)?.name}</TableCell>
                  <TableCell align="left">
                    <InputText value={newCosts[productType]} currency="€" productType={productType} onChangeCost={onChangeCost} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <div>
          <Button
            onClick={handleSubmit}
            style={{ marginTop: "15px" }}
            variant="contained"
            disabled={!isSaveButtonEnable || ORDER_STATE?.createProductPricesLoading ? true : false}
            color="primary"
          >
            {ORDER_STATE?.createProductPricesLoading ? "Loading" : "Save"}
          </Button>
        </div>
      </div>
    </div>
  );
};

const InputText = ({ value, currency, productType, onChangeCost }) => {
  return (
    <TextField
      id="outlined-adornment-amount"
      onChange={event => onChangeCost(productType, event.target.value)}
      InputProps={{
        startAdornment: <InputAdornment position="start">{currency}</InputAdornment>
      }}
      variant="outlined"
      className="cost-table__input"
      value={value}
      type="Number"
      size="small"
    />
  );
};
export default AddProductPrices;
