import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import InfoIcon from "@material-ui/icons/Info";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import CheckIcon from "@material-ui/icons/Check";
import moment from "moment";
import { Chip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
//FUNCTIONS
import ReturnOrderTable from "../MaterialDataGridTable/index";
import { getReturnOrders } from "redux/action/order.action";
import Loading from "components/Loading";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import NoData from "assets/img/NoData.svg";
import { isBoolean, isEmpty, round, split } from "lodash";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  loading: {
    display: "flex",
    justifyContent: "center"
  }
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  //VARIABLES

  const history = useHistory();
  const dispatch = useDispatch();
  const ORDER_STATE = useSelector(state => state.order);
  let returnOrders = ORDER_STATE.return_orders;
  const [rows, setRows] = React.useState([]);

  const columns = [
    {
      field: " ",
      headerName: "",
      sortable: false,
      width: 90,
      disableClickEventBubbling: true,
      renderCell: params => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
            <IconButton m={0.5} aria-label="primary" onClick={() => openReturnOrder(params.row.id)}>
              <VisibilityIcon style={{ paddingRight: "10px" }} />
            </IconButton>
          </div>
        );
      }
    },
    { field: "id", headerName: "ID", width: 90, type: "number", headerAlign: "left", align: "left", disableColumnMenu: true },
    {
      field: "created_at",
      headerName: "Creation Date",
      width: 170,
      editable: false,
      headerAlign: "left",
      align: "left",
      type: "date",
      valueFormatter: params => moment(params?.value).format("YYYY-MM-DD")
    },
    {
      field: "user_name",
      headerName: "Customer",
      width: 180,
      headerAlign: "left",
      align: "left",
      editable: false
    },
    {
      field: "email",
      headerName: "Email",
      width: 280,
      headerAlign: "left",
      align: "left",
      editable: false
    },
    {
      field: "type",
      headerName: "Type",
      width: 130,
      editable: false,
      headerAlign: "left",
      align: "left"
    },
    {
      field: "return_date",
      headerName: "Return Date",
      width: 160,
      editable: false,
      headerAlign: "left",
      align: "left",
      type: "date",
      valueFormatter: params => moment(params?.value).format("YYYY-MM-DD")
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      editable: false,
      headerAlign: "left",
      align: "left",
      renderCell: params => {
        let icon;
        let color;
        switch (params.row.status) {
          case "cancelled":
            icon = <ReportProblemIcon style={{ color: "#d32f2f" }} />;
            color = "#d32f2f";
            break;
          case "processing":
            icon = <AutorenewIcon style={{ color: "#f57c00" }} />;
            color = "#f57c00";
            break;
          case "completed":
            icon = <CheckIcon style={{ color: "#388e3c" }} />;
            color = "#388e3c";
            break;
          case "confirmed":
            icon = <InfoIcon style={{ color: "#1976d2" }} />;
            color = "#1976d2";
            break;

          default:
            break;
        }

        return (
          <div className="d-flex justify-content-between align-items-center ">
            <Chip icon={icon} label={params.row.status} variant="outlined" style={{ color: color, borderColor: color }} />
          </div>
        );
      }
    },
    {
      field: "shipping_payment_status",
      headerName: "Payment Status",
      width: 180,
      headerAlign: "left",
      align: "left",
      editable: false
    },
    {
      field: "shipping_cost",
      headerName: "Shipping Price",
      width: 170,
      headerAlign: "left",
      align: "left",
      editable: false,
      type: "number"
    },
    {
      field: "items_count",
      headerName: "Items",
      width: 140,
      editable: false,
      headerAlign: "left",
      align: "left",
      disableColumnMenu: true,
      type: "number"
    },
    {
      field: "carrier_name",
      headerName: "Carrier",
      width: 140,
      editable: false,
      headerAlign: "left",
      align: "left"
    },
    {
      field: "warehouse_name",
      headerName: "Warehouse",
      width: 150,
      editable: false,
      headerAlign: "left",
      align: "left"
    },
    {
      field: "city",
      headerName: "City",
      width: 140,
      editable: false,
      headerAlign: "left",
      align: "left"
    }
  ];
  //FUNCTIONS
  const handleReturnOrderAsColumn = async orders => {
    return orders.map(order => {
      return {
        id: order._id,
        status: order.status,
        created_at: moment(order.created_at).format("YYYY-MM-DD"),
        type: isBoolean(order?.partial) ? (order.partial ? "Partial" : "Total") : "NA",
        shipping_payment_status: order?.stripe_shipping_details?.p_intent_status || "NA",
        return_date: split(order.return_date, "::")[0],
        user_name: order.user_name,
        email: order.email,
        shipping_cost: round(order.shipping_cost, 2),
        items_count: order?.items_count,
        city: order.city,
        zipcode: order.zipcode,
        carrier_name: order?.carrier_name || "NA",
        warehouse_name: order?.warehouse_name || "NA"
      };
    });
  };
  useEffect(() => {
    if (!returnOrders) {
      dispatch(getReturnOrders({}));
    }
  }, [dispatch, props, returnOrders]); //[] ADD ONLY TO REMOVE WARNINGS
  useEffect(() => {
    if (returnOrders && returnOrders.length > 0) {
      (async () => {
        const rows = await handleReturnOrderAsColumn(returnOrders);
        setRows(rows);
      })();
    }
  }, [returnOrders]); // eslint-disable-line
  const openReturnOrder = returnOrderId => {
    history.push(`${history.location.pathname}/${returnOrderId}`);
  };

  //CONSOLE LOG

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <div className={classes.root}>
          {ORDER_STATE.fetchingReturnOrdersLoading && isEmpty(returnOrders) ? (
            <div className={classes.loading}>
              <Loading />
            </div>
          ) : (
            <div>
              {returnOrders && returnOrders.length > 0 ? (
                <ReturnOrderTable
                  rows={rows}
                  columns={columns}
                  data={returnOrders}
                  openReturnOrder={openReturnOrder}
                  setRows={setRows}
                  handleDataAsColumn={handleReturnOrderAsColumn}
                  tableName="RETURN_ORDERS_TABLE"
                />
              ) : (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <img src={NoData} alt="" />
                  <label style={{ fontSize: "larger", padding: "20px" }}>There is no any Return order</label>
                </div>
              )}
            </div>
          )}
        </div>
      </GridItem>
    </GridContainer>
  );
}
