// core components/views for Admin layout
// import DashboardPage from "views/Dashboard/Dashboard.js";
import Orders from "views/Orders/Orders.js";
import ReturnOrders from "views/ReturnOrders/ReturnOrders.js";
import Customers from "views/Customers/Customers.js";
import Subscriptions from "views/Subscriptions/Subscriptions.js";
import shippingCosts from "views/ShippingCosts/ShippingCosts.js";
import returnCosts from "views/ReturnCosts/ReturnCosts.js";
import otherSetting from "views/OtherSetting/OtherSetting.js";
import ProductPrices from "views/ProductPrices/ProductPrices.js";
import Carriers from "views/Carriers/index.js";
import Warehouses from "views/Warehouses/index.js";

const routes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: Dashboard,
  //   component: DashboardPage,
  //   layout: "/admin"
  // },
  {
    path: "/orders",
    name: "Pickup Orders",
    icon: "shop_two",
    component: Orders,
    layout: "/admin"
  },
  {
    path: "/return-orders",
    name: "Return Orders",
    icon: "assignment_return",
    component: ReturnOrders,
    layout: "/admin"
  },
  {
    path: "/customers",
    name: "Customers",
    icon: "people_alt",
    component: Customers,
    layout: "/admin"
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    icon: "cached",
    component: Subscriptions,
    layout: "/admin"
  },
  {
    path: "/products",
    name: "Products",
    icon: "inventory_icon",
    component: ProductPrices,
    layout: "/admin"
  },
  {
    path: "/carriers",
    name: "Carriers",
    icon: "supervisor_account_icon",
    component: Carriers,
    layout: "/admin"
  },
  {
    path: "/warehouses",
    name: "Warehouses",
    icon: "store_icon",
    component: Warehouses,
    layout: "/admin"
  },
  {
    path: "/shipping-cost",
    name: "Pickup Costs",
    icon: "local_shipping",
    component: shippingCosts,
    layout: "/admin"
  },
  {
    path: "/return-cost",
    name: "Return Cost",
    icon: "local_shipping",
    component: returnCosts,
    layout: "/admin"
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "settings",
    component: otherSetting,
    layout: "/admin"
  }
  // {
  //   path: "/subscriptions",
  //   name: "subscriptions",
  //   icon: "subscriptions",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: LocationOn,
  //   component: Maps,
  //   layout: "/admin"
  // }
];

export default routes;
