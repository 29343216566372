import React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { useSelector } from "react-redux";

const options = [
  {
    value: true,
    label: "Yes"
  },
  {
    value: false,
    label: "No"
  }
];
const ShippingDetails = ({ listOfExtraDetails }) => {
  //CONSTANTS
  const ORDER_STATE = useSelector(state => state.order);
  let orderListOfExtraDetails;
  //FUNCTIONS
  if (!orderListOfExtraDetails) {
    orderListOfExtraDetails = listOfExtraDetails.map(extraDetail => {
      return extraDetail._id;
    });
  }

  //CONSOLE LOG
  return (
    <div>
      <div className="label">Shipping Details</div>
      {ORDER_STATE.extraDetails.map((extraDetail, index) => {
        return (
          <div className="row" key={index}>
            <div style={{ minWidth: "325px", maxWidth: "325px" }}>{extraDetail.name}</div>
            <div className="form-input">
              <TextField
                id="standard-select-detail"
                select
                label="Select"
                disabled
                value={orderListOfExtraDetails.includes(extraDetail._id)}
                size="small"
                variant="outlined"
                fullWidth={true}
              >
                {options.map(option => (
                  <MenuItem key={option.label} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ShippingDetails;
