import React, { useState, useEffect, useCallback } from "react";
import axios from "../../config/axios";
import Loading from "components/Loading";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { Button, makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Add, Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import { CarrierAddOrEdit } from "./CarrierAddOrEdit";
import IconButton from "@material-ui/core/IconButton";

import "./index.css";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    flexGrow: 1
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  loading: {
    display: "flex",
    justifyContent: "center"
  }
}));

const CarrierList = () => {
  const classes = useStyles();
  const [carriers, setCarriers] = useState([]);
  const [editCarrier, setEditCarrier] = useState(null);
  const [fetchingCarriers, setFetchingCarriers] = useState(false);
  const [isOpenCarrierPopup, setIsOpenCarrierPopup] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const fetchCarriers = useCallback(() => {
    setFetchingCarriers(true);
    axios
      .get(`/admin/carriers`)
      .then(res => {
        setFetchingCarriers(false);
        setCarriers(res.data.data.carriers);
      })
      .catch(error => {
        enqueueSnackbar(error, { variant: "error" });
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (isEmpty(carriers)) {
      fetchCarriers();
    }
  }, [carriers, fetchCarriers]);

  const onClickAddNewCarrier = () => {
    setIsOpenCarrierPopup(true);
  };
  const handleCloseCarrierPopup = () => {
    setIsOpenCarrierPopup(false);
    setEditCarrier(null);
    fetchCarriers();
  };

  const handlDeleteCarrier = carrierId => {
    axios.delete(`/admin/remove-carrier/${carrierId}`).then(() => {
      enqueueSnackbar("Carrier removed successfully", { variant: "success" });
      fetchCarriers();
    });
  };

  const handleEditCarrier = carrier => {
    setIsOpenCarrierPopup(true);
    setEditCarrier(carrier);
  };

  return (
    <div className="carrier-list">
      <CarrierAddOrEdit isOpen={isOpenCarrierPopup} handleClose={handleCloseCarrierPopup} editCarrier={editCarrier} />
      {fetchingCarriers && isEmpty(carriers) ? (
        <div className={classes.loading}>
          <Loading />
        </div>
      ) : (
        <div>
          {carriers && carriers.length > 0 && (
            <div>
              <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
                <Button startIcon={<Add />} color="primary" variant="contained" onClick={() => onClickAddNewCarrier()}>
                  Add new carrier
                </Button>
              </div>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Carrier ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Area name</TableCell>
                      <TableCell>latitude</TableCell>
                      <TableCell>longitude</TableCell>
                      <TableCell>Active</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {carriers.map(carrier => (
                      <TableRow key={carrier._id}>
                        <TableCell> {carrier._id}</TableCell>
                        <TableCell>{carrier.name}</TableCell>
                        <TableCell>{carrier.email}</TableCell>
                        <TableCell>{carrier.area_name}</TableCell>
                        <TableCell>{carrier.latitude}</TableCell>
                        <TableCell>{carrier.longitude}</TableCell>
                        <TableCell>{carrier.is_active ? "Yes" : "No"}</TableCell>
                        <TableCell>
                          <IconButton aria-label="expand row" size="small" onClick={() => handleEditCarrier(carrier)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton aria-label="expand row" size="small" onClick={() => handlDeleteCarrier(carrier._id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CarrierList;
