import React from "react";
import { Route } from "react-router-dom";

//core components
import CarrierList from "components/CarrierList/index";

export default function Subscriptions() {
  return (
    <div>
      <Route path={`/admin/carriers`} exact={true} component={CarrierList} />
    </div>
  );
}
