import React, { useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, CssBaseline, Link, Paper, Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { login } from "redux/action/auth.action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import KeepwhatImage from "assets/img/Signin.jpg";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "../../config/axios";
import { FormInputText } from "components/MuiInputComponents/FormInputText";
import { signinSchema } from "./schemas/Signin.schema";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link target="_blank" color="inherit" href="https://keepwhat.com/">
        KeepWhat
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  image: {
    backgroundImage: `url(${KeepwhatImage})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function SignIn() {
  const [isVerifiedbyGoogleRecaptcha, setIsVerifiedbyGoogleRecaptcha] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const AUTH_STATE = useSelector(state => state.auth);
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({ resolver: yupResolver(signinSchema) });

  const handleLoginSuccess = () => {
    history.push("/admin/dashboard");
  };

  const handleVerifyRecaptcha = useCallback(
    async token => {
      if (!isVerifiedbyGoogleRecaptcha) {
        const {
          data: {
            data: { googleResponse }
          }
        } = await axios.post("/verify/recaptcha", { humanKey: token });

        if (googleResponse?.success && googleResponse?.score > 0) {
          setIsVerifiedbyGoogleRecaptcha(true);
        }
      }
    },
    [isVerifiedbyGoogleRecaptcha]
  );

  const onSubmit = async data => {
    if (!isVerifiedbyGoogleRecaptcha) return;

    dispatch(login({ email: data.email, password: data.password }, handleLoginSuccess));
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <GoogleReCaptcha onVerify={handleVerifyRecaptcha} />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src="https://uploads-ssl.webflow.com/5f4e065e81cfc65ac23ac38f/6012f3bfdbc7134e374bac2b_Logo.svg" alt="logo" className={classes.img} style={{ width: "200px" }} />

          <FormInputText
            id="email"
            label="Email Address"
            name="email"
            control={control}
            helperText={errors?.email?.message}
            isError={errors?.email?.message}
            inputProps={{ autoFocus: true, fullWidth: true, margin: "normal" }}
          />
          <FormInputText
            id="password"
            name="password"
            label="Password"
            control={control}
            helperText={errors?.password?.message}
            isError={errors?.password?.message}
            inputProps={{ type: "password", fullWidth: true, margin: "normal" }}
          />

          <div style={{ display: "flex", justifyContent: "center", color: "red", fontSize: "large" }}>{AUTH_STATE.loginError ? AUTH_STATE.loginError : null}</div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            className={classes.submit}
            disabled={AUTH_STATE.loginLoading ? true : false}
          >
            {AUTH_STATE.loginLoading ? "Loading..." : "Sign In"}
          </Button>
          <Box mt={5}>
            <Copyright />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}
