import { combineReducers } from "redux";

import auth from "./auth.reducer";
import order from "./order.reducer";
import customer from "./customer.reducer";
import common from "./common.reducer";
const appReducer = combineReducers({
  auth,
  order,
  customer,
  common
});

const rootReducer = (state, action) => {
  if (action.type === "AUTH_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
