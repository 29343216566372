import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import axios from "../../../config/axios";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, TextField, Checkbox } from "@material-ui/core";
import { useSnackbar } from "notistack";

export const WarehouseAddOrEdit = ({ isOpen, handleClose, editWarehouse }) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleWarehouseSubmit = async event => {
    //Preventing page refresh
    event.preventDefault();

    setLoading(true);

    const body = {
      name: event.target.name.value,
      admin_emails: event.target.admin_emails.value.split(","),
      zipcode: event.target.zipcode.value,
      latitude: event.target.latitude.value,
      longitude: event.target.longitude.value,
      is_active: event.target.is_active.checked
    };
    if (editWarehouse) {
      axios
        .patch(`/admin/update-warehouse/${editWarehouse._id}`, body)
        .then(res => {
          setLoading(false);
          handleClose();
          enqueueSnackbar("Warehouse updated successfully", { variant: "success" });
        })
        .catch(error => {
          enqueueSnackbar(error?.message, { variant: "error" });
          setLoading(false);
        });
    } else {
      axios
        .post(`/admin/create-warehouse`, body)
        .then(res => {
          setLoading(false);
          handleClose();
          enqueueSnackbar("Warehouse created successfully", { variant: "success" });
        })
        .catch(error => {
          enqueueSnackbar(error?.message, { variant: "error" });
          setLoading(false);
        });
    }
  };
  return (
    <Dialog fullWidth={true} maxWidth={"sm"} open={isOpen} onClose={handleClose} aria-labelledby="max-width-dialog-title">
      <DialogTitle id="max-width-dialog-title">{editWarehouse ? "Edit" : "Add"} Warehouse</DialogTitle>
      <div className="warehouse-popup">
        <form onSubmit={handleWarehouseSubmit}>
          <div className="form-field">
            <div>Name</div>
            <div>
              <TextField id="outlined-basic" name="name" label="Name" variant="outlined" defaultValue={editWarehouse?.name} required />
            </div>
          </div>
          <div className="form-field">
            <div>Zipcode</div>
            <TextField id="outlined-basic" name="zipcode" label="Zipcode" variant="outlined" defaultValue={editWarehouse?.zipcode} required />
          </div>
          <div className="form-field">
            <div>Latitude</div>
            <TextField id="outlined-basic" name="latitude" type="String" label="Latitude" variant="outlined" defaultValue={editWarehouse?.latitude} required />
          </div>
          <div className="form-field">
            <div>Longitude</div>
            <TextField id="outlined-basic" name="longitude" type="String" label="Longitude" variant="outlined" defaultValue={editWarehouse?.longitude} required />
          </div>{" "}
          <div className="form-field">
            <div>Admin Emails</div>
            <TextField
              id="standard-multiline-static"
              name="admin_emails"
              type="String"
              label="Admin Emails"
              variant="outlined"
              multiline
              rows={4}
              defaultValue={editWarehouse?.admin_emails?.join(",")}
              required
              helperText='Please enter email addresses with "," '
            />
          </div>
          <div className="form-field">
            <div>Is Active</div>
            <Checkbox name="is_active" defaultChecked={editWarehouse?.is_active} />
          </div>
          <div className="save-button">
            <Button type="submit" fullWidth variant="contained" color="primary" disabled={loading}>
              {loading ? "Loading" : "Save"}
            </Button>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};
