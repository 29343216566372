import React, { useState } from "react";
import { map } from "lodash";
import moment from "moment";
import csvFormat from "./csvFormat";
import { useSnackbar } from "notistack";
import { makeStyles, InputLabel, MenuItem, FormControl, Select, Button } from "@material-ui/core";
import "./exportData.css";

import DownloadCSV from "../GenerateCSV";
import axios from "../../config/axios";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: "5px",
    width: 223
  }
}));

const ExportData = () => {
  const [pageName, setPageName] = useState("/admin/orders");
  const { enqueueSnackbar } = useSnackbar();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [status, setStatus] = useState("processing");
  const [exportType, setExportType] = useState("csv");
  const handleChangePageName = event => setPageName(event.target.value);
  const handleChangeStatus = event => setStatus(event.target.value);
  const handleExportType = event => setExportType(event.target.value);

  const handleClickPrint = () => {
    setButtonLoading(true);
    if (exportType === "pdf") return enqueueSnackbar("PDF is not supported for this Options", { variant: "error" }) && setButtonLoading(false);
    let query = pageName;
    if (status && (pageName === "/admin/orders" || pageName === "/admin/return-orders")) query = pageName + `?status=${status}`;

    axios
      .get(`${query}`)
      .then(res => {
        let data;
        if (pageName === "/admin/orders" || pageName === "/admin/return-orders") data = res.data.data.orders;
        if (pageName === "/admin/items") data = res.data.data.items;
        if (pageName === "/get-subscriptions") data = res.data.data.subscriptions;
        if (!data) return enqueueSnackbar("Something Went Wrong", { variant: "error" }) && setButtonLoading(false);
        const csvData =
          data?.length > 0
            ? map(data, field => {
                let row = {};
                map(csvFormat[pageName], column => {
                  let value = field?.[column[0]];
                  if (column[0] === "amount") value = field?.[column[0]]?.value;
                  if (column[0] === "current_period_start" || column[0] === "current_period_end") value = moment(value * 1000).format("DD-MM-yyyy");
                  row[column[1]] = value ? value : "-";
                });
                return row;
              })
            : [];
        DownloadCSV({ csvData, exportFileName: `Keepwhat data (${moment().format("MMM YYYY")})` });
        setButtonLoading(false);
      })
      .catch(err => {
        setButtonLoading(false);
        enqueueSnackbar("Something Went Wrong", { variant: "error" });
      });
  };
  return (
    <div className="main-container">
      <div style={{ paddingBottom: "10px" }}>Export Data</div>
      <div className="d-flex">
        <InputText
          lable="Page"
          value={pageName}
          menuItems={[
            ["/admin/orders", "Pickup Orders"],
            ["/admin/return-orders", "Return Orders"],
            ["/admin/items", "Items"],
            ["/get-subscriptions", "Subscriptions"]
          ]}
          onChange={handleChangePageName}
        />
        {(pageName === "/admin/orders" || pageName === "/admin/return-orders") && (
          <InputText
            lable="Status"
            value={status}
            menuItems={[
              ["processing", "Processing"],
              ["confirmed", "Confirmed"],
              ["completed", "Completed"],
              ["cancelled", "Cancelled"]
            ]}
            onChange={handleChangeStatus}
          />
        )}
      </div>
      <InputText
        lable="CSV/PDF"
        value={exportType}
        menuItems={[
          ["csv", "CSV"],
          ["pdf", "PDF"]
        ]}
        onChange={handleExportType}
      />
      <div style={{ display: "flex", margin: "5px" }}>
        <Button style={{ width: "90px" }} className="d-flex" variant="contained" disabled={buttonLoading} size="small" color="primary" onClick={handleClickPrint}>
          {buttonLoading ? "Loading" : "Download"}
        </Button>
      </div>
    </div>
  );
};

const InputText = ({ menuItems, value, lable, onChange }) => {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" className={classes.formControl} size="small" aria-hidden="true">
      <InputLabel id="demo-simple-select-outlined-label">{lable}</InputLabel>
      <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label={lable} value={value} onChange={onChange}>
        {map(menuItems, (menuItem, index) => {
          return (
            <MenuItem key={index} value={menuItem[0]}>
              {menuItem[1]}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default ExportData;
