import React from "react";
import ReactDOM from "react-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import store from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import withAuth from "utils/withAuth";
import { SnackbarProvider } from "notistack";
import Button from "@material-ui/core/Button";
// core components
import Admin from "layouts/Admin.js";
import SignIn from "views/Signin/SignIn.js";

import "assets/css/material-dashboard-react.css?v=1.9.0";
import "react-credit-cards/es/styles-compiled.css";
import "assets/css/common.scss";

Sentry.init({
  dsn: "https://e52d5773c7b2484bae29b657840c52fe@sentry.twt-env.com/7",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_SENTRY_ENV || "local"
});

const notistackRef = React.createRef();
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
  // <Router history={hist}>
  <SnackbarProvider
    ref={notistackRef}
    maxSnack={3}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    action={key => <Button onClick={onClickDismiss(key)}>Dismiss</Button>}
  >
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}>
      <Provider store={store}>
        <Router>
          {/* history={hist} */}
          <Switch>
            <Route path="/admin/login" component={withAuth(SignIn, true)} />
            <Route path="/admin" component={withAuth(Admin)} />
            <Redirect from="/" to="/admin/login" />
          </Switch>
        </Router>
      </Provider>
    </GoogleReCaptchaProvider>
  </SnackbarProvider>,
  document.getElementById("root")
);
