import React, { useEffect, useState } from "react";

//NPM PACKAGES
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { split, isEmpty, round } from "lodash";
import moment from "moment";

//MATERIAL UI COMPONENTS
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { Add } from "@material-ui/icons";
import Chip from "@material-ui/core/Chip";

import ProductLists from "./ProductLists";
//core components
import ShippingDetails from "components/ShippingDetails/index";
import ItemAddDialog from "components/OrderDetails/ItemAddOrEdit";
import { getExtraDetails, getOrder, getCarriers, getWarehouses, updateOrder } from "redux/action/order.action";
import { orderShippingStatuses } from "assets/constants/constants";
import { stripeDashboardURL } from "utils";
import { purchaseOrderStatus } from "assets/constants/constants";
import AddProduct from "../CustomerDetails/AddProduct";
import { getProducts } from "redux/action/order.action";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  }
  // body: {
  //   fontSize: 14
  // }
}))(TableCell);

const statuses = [
  {
    value: "processing",
    label: "Processing"
  },
  {
    value: "confirmed",
    label: "Confirmed"
  },
  {
    value: "completed",
    label: "Completed"
  },
  {
    value: "cancelled",
    label: "Cancelled"
  }
];

const OrderDetails = () => {
  //CONSTANTS
  const dispatch = useDispatch();
  const params = useParams();
  const ORDER_STATE = useSelector(state => state.order);
  const [selectedPickupDate, setSelectedPickupDate] = React.useState(null);
  const [selectedPickupTime, setSelectedPickupTime] = React.useState(null);
  const [isOpenAddProductDialog, setIsOpenAddProductDialog] = React.useState(false);

  const [items, setItems] = React.useState(null);
  const [status, setStatus] = React.useState("");
  const [carrier, setCarrier] = React.useState("");
  const [warehouse, setWarehouse] = React.useState("");
  const [oneTimeValue, setOneTimeValue] = useState(null);
  const [notes, setNotes] = React.useState("");
  const [updatedOrder, setUpdatedOrder] = React.useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [showComponent, setShowComponent] = React.useState(false);
  const [hooksDependency, setHookDependency] = React.useState(true);
  const [hooksDependency2, setHookDependency2] = React.useState({ random1: true, random2: true, random3: true });
  //FUNCTIONS
  useEffect(() => {
    if (hooksDependency) {
      let { id } = params;
      const handleSuccess = ({ order }) => {
        if (!order.checked_in <= 0 && order.status !== "processing" && !order.carrier_id) {
          enqueueSnackbar("Depois de confirmares a encomenda, não te esqueças de associar um Carrier a este pedido.", { variant: "warning" });
        }
      };
      dispatch(getOrder({ id, type: "purchase-order" }, handleSuccess));
      setShowComponent(true);
      if (!ORDER_STATE.extraDetails) {
        dispatch(getExtraDetails());
      }
      if (!ORDER_STATE.carriers || !ORDER_STATE.warehouses) {
        dispatch(getCarriers());
        dispatch(getWarehouses());
      }
      setHookDependency(false);
    }
  }, [ORDER_STATE, dispatch, hooksDependency, params, enqueueSnackbar]); //[] ADD DEPENDENCIES ONLY TO REMOVE WARNINGS

  useEffect(() => {
    if (showComponent && ORDER_STATE.order && !selectedPickupDate && hooksDependency2.random1) {
      let date = moment(split(ORDER_STATE.order.pickup_date, "::")[0]).format("YYYY-MM-DD");
      let time = split(ORDER_STATE.order.pickup_date, "::")[1];
      setSelectedPickupDate(date);
      setSelectedPickupTime(time);
      setStatus(ORDER_STATE.order.status);
      setOneTimeValue(ORDER_STATE.order.amount.one_time.value);
      setNotes(ORDER_STATE.order.notes);
      setHookDependency2({ ...hooksDependency2, random1: false });
    }
    if (ORDER_STATE.order && ORDER_STATE.carriers && !carrier && hooksDependency2.random2) {
      setCarrier(ORDER_STATE.order.carrier_id);
      setHookDependency2({ ...hooksDependency2, random2: false });
    }
    if (ORDER_STATE.order && ORDER_STATE.warehouses && !warehouse && hooksDependency2.random3) {
      setWarehouse(ORDER_STATE.order.warehouse_id);
      setHookDependency2({ ...hooksDependency2, random3: false });
    }
  }, [ORDER_STATE, hooksDependency2, carrier, selectedPickupDate, showComponent, warehouse]); //[ORDER_STATE] OTHER ADDED TO REMOVE WARNING

  const handleupdateOrderSuccess = () => {
    enqueueSnackbar("Order Updated Successfully", { variant: "success" });
    if (updatedOrder?.status === "confirmed" && !carrier) {
      enqueueSnackbar("Depois de confirmares a encomenda, não te esqueças de associar um Carrier a este pedido.", { variant: "warning" });
    }
    setUpdatedOrder({});
  };
  const handleUpdateOrderFailure = ({ error }) => {
    enqueueSnackbar(error, { variant: "error" });
  };
  useEffect(() => {
    if (!ORDER_STATE.products) {
      dispatch(getProducts());
    }
  }, [ORDER_STATE.products, dispatch]);

  const HandleSubmit = event => {
    event.preventDefault();

    let order = { ...updatedOrder };
    if (order.date && order.time) {
      order.pickup_date = `${order.date}::${order.time}`;
      delete order.date;
      delete order.time;
    } else if (order.date) {
      order.pickup_date = `${order.date}::${split(ORDER_STATE.order.pickup_date, "::")[1]}`;
      delete order.date;
    } else if (order.time) {
      order.pickup_date = `${split(ORDER_STATE.order.pickup_date, "::")[0]}::${order.time}`;
      delete order.time;
    }
    dispatch(
      updateOrder(
        {
          updatedOrder: order,
          order_id: ORDER_STATE.order._id,
          type: "purchase-order"
        },
        handleupdateOrderSuccess,
        handleUpdateOrderFailure
      )
    );
  };
  const handlePikupDateChange = event => {
    if (moment(event.target.value).unix() > moment().add(3, "m").unix()) {
      setSelectedPickupDate(event.target.value);
      setUpdatedOrder({ ...updatedOrder, date: moment(event.target.value).format("YYYY-MM-DD") });
    } else {
      //! show notification
    }
  };
  const handlePickupTimeChange = event => {
    setSelectedPickupTime(event.target.value);
    setUpdatedOrder({ ...updatedOrder, time: event.target.value });
  };

  const onChangeOneTimeValue = value => {
    setOneTimeValue(value);
    setUpdatedOrder({ ...updatedOrder, one_time: { value, currency: "€" } });
  };
  const onOpenItems = items => {
    setItems({ items });
  };
  const onChangeCarrier = value => {
    setCarrier(value);
    setUpdatedOrder({ ...updatedOrder, carrier_id: value });
  };
  const onChangeWarehouse = value => {
    setWarehouse(value);
    setUpdatedOrder({ ...updatedOrder, warehouse_id: value });
  };
  const onnotesChange = event => {
    setNotes(event.target.value);
    setUpdatedOrder({ ...updatedOrder, notes: event.target.value });
  };
  const handleCloseItemDialog = () => {
    setItems(null);
  };
  const handleCloseSuccessItemDialog = () => {
    let { id } = params;
    setItems(null);
    dispatch(getOrder({ id, type: "purchase-order" }));
    enqueueSnackbar("Item Added Successfully", { variant: "success" });
  };
  const handleStatusChange = event => {
    setStatus(event.target.value);
    setUpdatedOrder({ ...updatedOrder, status: event.target.value });
  };

  return (
    <div>
      {items && (
        <ItemAddDialog items={items} handleCloseItemDialog={handleCloseItemDialog} handleCloseSuccessItemDialog={handleCloseSuccessItemDialog} orderId={ORDER_STATE.order._id} />
      )}

      {isOpenAddProductDialog && (
        <AddProduct
          setIsOpenAddProductDialog={setIsOpenAddProductDialog}
          allowOrderSelection={false}
          orderId={ORDER_STATE?.order?._id}
          userId={ORDER_STATE?.order?.user_id?._id}
          priceVersion={ORDER_STATE?.order?.user_id?.price_version}
        />
      )}

      <h3 style={{ fontWeight: "600" }}>Order Details</h3>

      {ORDER_STATE.fetchingOrderLoading ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
        <div>
          {showComponent && ORDER_STATE.order && ORDER_STATE.extraDetails && ORDER_STATE.carriers && ORDER_STATE.warehouses && (
            <div>
              <div className="details">
                <div className="title">Order #{ORDER_STATE.order._id}</div>
                <div className="card">
                  <div className="col">
                    <div className="row">
                      <div className="label">Subscribed amount</div>
                      <div>
                        {round(ORDER_STATE.order.amount.recurring.value, 2)} {ORDER_STATE.order.amount.recurring.currency}
                      </div>
                    </div>

                    <div className="row">
                      <div className="label">Pick up Date</div>
                      <div className="form-input">
                        <TextField
                          id="datetime-local"
                          label="Date"
                          type="date"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={selectedPickupDate === null ? "" : selectedPickupDate}
                          onChange={handlePikupDateChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="label">Pick up Time</div>
                      <div className="form-input">
                        <FormControl variant="outlined">
                          <InputLabel id="demo-simple-select-outlined-label">Time</InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={selectedPickupTime === null ? "" : selectedPickupTime}
                            onChange={handlePickupTimeChange}
                            label="Time"
                          >
                            <MenuItem value="09:00 - 12:00">09:00 - 12:00</MenuItem>
                            <MenuItem value="13:00 - 15:00">13:00 - 15:00</MenuItem>
                            <MenuItem value="15:00 - 19:00">15:00 - 19:00</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="row">
                      <div className="label">Status</div>
                      <div className="form-input">
                        <TextField
                          id="standard-select-status"
                          select
                          label="Select"
                          value={status === null ? "" : status}
                          onChange={e => handleStatusChange(e)}
                          helperText={ORDER_STATE.order.checked_in <= 0 && "does not have any Item filled inside a product."}
                          variant="outlined"
                        >
                          {statuses.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                    <div className="row">
                      <div className="label">Price Version</div>
                      <div>{ORDER_STATE.order?.user_id?.price_version}</div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row" style={{ display: "flex", flexDirection: "column" }}>
                      <div className="row">
                        <div className="label" style={{ minWidth: "100px" }}>
                          Location
                        </div>
                        <div>
                          <div className="row">
                            <div className="label">Address</div>
                            <div>{ORDER_STATE.order.location.address}</div>
                          </div>
                          <div className="row">
                            <div className="label">floor</div>
                            <div>{ORDER_STATE.order.location.floor}</div>
                          </div>
                          <div className="row">
                            <div className="label">City</div>
                            <div>{ORDER_STATE.order.location.city}</div>
                          </div>
                          <div className="row">
                            <div className="label">Zipcode</div>
                            <div>{ORDER_STATE.order.location.zipcode}</div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="label" style={{ minWidth: "100px" }}>
                          Notes
                        </div>
                        <div style={{ width: "100%", maxWidth: "400px" }}>
                          <TextField
                            id="outlined-multiline-static"
                            label="Multiline"
                            multiline
                            rows={4}
                            onChange={e => onnotesChange(e)}
                            value={notes || ""}
                            variant="outlined"
                            fullWidth={true}
                          />
                        </div>
                      </div>
                      {/* // */}
                    </div>
                  </div>
                </div>
                <div className="save">
                  {ORDER_STATE.updateOrderLoading ? (
                    <Button variant="contained" color="primary" disabled={true}>
                      Loading
                    </Button>
                  ) : (
                    <Button variant="contained" color="primary" onClick={e => HandleSubmit(e)} disabled={Object.keys(updatedOrder).length <= 0 ? true : false}>
                      Save
                    </Button>
                  )}
                </div>
              </div>

              <div className="details">
                <div className="title">Shipping Details</div>
                <div className="card">
                  <div className="col">
                    <div className="row">
                      <div className="label">Shipping cost</div>
                      <div className="form-input">
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            value={oneTimeValue === null ? "" : round(oneTimeValue, 2)}
                            onChange={e => {
                              onChangeOneTimeValue(e.target.value);
                            }}
                            disabled
                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                            labelWidth={60}
                          />
                        </FormControl>
                      </div>
                    </div>
                    {ORDER_STATE.order.stripe_shipping_details && (
                      <div className="row">
                        <div className="label">Shipping status</div>
                        <div>
                          <Chip
                            label={orderShippingStatuses[ORDER_STATE.order.stripe_shipping_details.p_intent_status]?.name}
                            variant="outlined"
                            style={{
                              border: "1px solid",
                              borderColor: orderShippingStatuses[ORDER_STATE.order.stripe_shipping_details.p_intent_status]?.borderColor,
                              backgroundColor: orderShippingStatuses[ORDER_STATE.order.stripe_shipping_details.p_intent_status]?.backgroundColor,
                              borderRadius: "5px",
                              fontWeight: "bold",
                              color: orderShippingStatuses[ORDER_STATE.order.stripe_shipping_details.p_intent_status]?.color
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="label">Carrier</div>

                      <div className="form-input">
                        <TextField
                          id="standard-select-status"
                          select
                          fullWidth={true}
                          label="Select"
                          disabled={ORDER_STATE.order.checked_in <= 0}
                          value={carrier === null ? "" : carrier}
                          onChange={e => onChangeCarrier(e.target.value)}
                          helperText="Please select carrier "
                          variant="outlined"
                        >
                          {ORDER_STATE.carriers.map(option => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                    <div className="row">
                      <div className="label">Warehouse</div>
                      <div className="form-input">
                        <TextField
                          id="standard-select-status"
                          select
                          fullWidth={true}
                          label="Select"
                          value={warehouse === null ? "" : warehouse}
                          onChange={e => onChangeWarehouse(e.target.value)}
                          helperText="Please select Warehouse"
                          variant="outlined"
                        >
                          {ORDER_STATE.warehouses.map(option => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <ShippingDetails listOfExtraDetails={ORDER_STATE.order.list_of_extra_details} />
                  </div>
                </div>
              </div>

              <div className="details">
                <div className="title">Customer Information</div>

                <div className="col">
                  <div className="row">
                    <div className="label">ID</div>

                    <Link to={`/admin/customers/${ORDER_STATE.order.user_id._id}`}>
                      <div style={{ fontWeight: "400" }}>{ORDER_STATE.order.user_id._id}</div>
                    </Link>
                  </div>
                  <div className="row">
                    <div className="label">Subscription ID</div>
                    <a href={`${stripeDashboardURL}/subscriptions/${ORDER_STATE.order.user_id.stripe_subscription_id}`} target="_blank" rel="noopener noreferrer">
                      <div style={{ fontWeight: "400" }}>{ORDER_STATE.order.user_id.stripe_subscription_id}</div>
                    </a>
                  </div>
                  <div className="row">
                    <div className="label">Name</div>

                    <div>{ORDER_STATE.order.user_id.name}</div>
                  </div>
                  <div className="row">
                    <div className="label">Email</div>
                    <div>{ORDER_STATE.order.user_id.email}</div>
                  </div>
                  <div className="row">
                    <div className="label">Phone Number</div>
                    <div>{ORDER_STATE.order.user_id.phone}</div>
                  </div>
                  <div className="row">
                    <div className="label">NIF Number</div>
                    <div>{isEmpty(ORDER_STATE.order.user_id.nif) ? "-" : ORDER_STATE.order.user_id.nif}</div>
                  </div>
                </div>
              </div>

              <div className="details">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid lightgrey", marginRight: "15px" }}>
                  <div className="title">Products</div>
                  <div>
                    {/* Order status should not be cancelled and subscription should be  */}
                    {!(ORDER_STATE?.order?.status === purchaseOrderStatus.cancelled) && ORDER_STATE?.order?.user_id?.stripe_subscription_id && (
                      <Button style={{ marginRight: "10px" }} startIcon={<Add />} color="primary" variant="contained" onClick={() => setIsOpenAddProductDialog(true)}>
                        Add new Product
                      </Button>
                    )}
                    <Button startIcon={<Add />} color="primary" variant="contained" onClick={() => onOpenItems(ORDER_STATE.order.products)}>
                      Add new item
                    </Button>
                  </div>
                </div>
                <div className="table">
                  <TableContainer component={Paper}>
                    <Table aria-label="collapsible table" size="small">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell />
                          <StyledTableCell>ID</StyledTableCell>
                          <StyledTableCell align="center">Type</StyledTableCell>
                          <StyledTableCell align="center">Returned</StyledTableCell>
                          <StyledTableCell align="center">Content Items</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ORDER_STATE?.order?.products?.map(row => (
                          <ProductLists
                            key={row._id}
                            row={row}
                            showIsReturnedColumn={true}
                            showItemDeleteColumn={ORDER_STATE.order.status === purchaseOrderStatus.processing || ORDER_STATE.order.status === purchaseOrderStatus.confirmed}
                            showItemEditColumn={true}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OrderDetails;
