import React from "react";

const ImageUpload = ({ isDragging }) => {
  return (
    <svg style={isDragging ? { fill: "blue" } : {}} xmlns="http://www.w3.org/2000/svg" width="400" height="56" viewBox="0 0 494.75 52.016">
      <g id="Group_44" data-name="Group 44" transform="translate(-267.125 -658.125)">
        <g id="Group_43" data-name="Group 43">
          <rect
            id="Rectangle_57"
            data-name="Rectangle 57"
            width="494"
            height="40"
            rx="3"
            transform="translate(267.5 658.5)"
            fill="none"
            stroke="#ccc"
            stroke-miterlimit="10"
            stroke-width="0.75"
            stroke-dasharray="6 3"
          />
          <text
            id="Drop_files_to_attach_or_browse."
            data-name="Drop files to attach, or browse."
            transform="translate(282.5 668.141)"
            fill="#6b778c"
            font-size="14"
            font-family="SegoeUI, Segoe UI"
          >
            <tspan x="136.446" y="15">
              Drop files to attach, or browse.
            </tspan>
          </text>
        </g>
        <g id="Page-1" transform="translate(0 -1)">
          <g id="Group_42" data-name="Group 42">
            <g id="Issue-Attachments">
              <g id="Group_41" data-name="Group 41">
                <g id="cloud-upload-2">
                  <path
                    id="Shape"
                    d="M396.125,688.648v-8.592l2.049,2.075a.719.719,0,0,0,1.025,0,.741.741,0,0,0,0-1.037l-3.286-3.324a.861.861,0,0,0-.111-.092c-.017-.01-.034-.017-.051-.027a.762.762,0,0,0-.074-.04.589.589,0,0,0-.065-.02c-.024-.007-.047-.016-.071-.021a.6.6,0,0,0-.111-.011c-.011,0-.019,0-.031,0s-.021,0-.032,0a.633.633,0,0,0-.108.011c-.026.005-.05.016-.075.023s-.04.01-.061.018a.613.613,0,0,0-.08.043.508.508,0,0,0-.045.024.757.757,0,0,0-.111.092l-3.285,3.324a.74.74,0,0,0,0,1.037.718.718,0,0,0,1.024,0l2.049-2.075v8.593a.725.725,0,1,0,1.45,0Z"
                    fill="#8993a4"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_33"
                    data-name="Path 33"
                    d="M403.7,675.285a6.381,6.381,0,0,0-11.67-3.218,3.813,3.813,0,0,0-1.514-.315,3.922,3.922,0,0,0-3.876,3.514,5.439,5.439,0,0,0,1.94,10.495h4.08v-1.255h-4.081a4.178,4.178,0,0,1-.672-8.294,2.625,2.625,0,0,1-.051-.513,2.675,2.675,0,0,1,2.66-2.692,2.63,2.63,0,0,1,1.961.886,5.154,5.154,0,0,1,10,1.832,4.5,4.5,0,0,1-.024.494,4.182,4.182,0,0,1-.75,8.287h-3.616v1.255H401.7a5.443,5.443,0,0,0,2-10.476Z"
                    fill="#8993a4"
                    fill-rule="evenodd"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ImageUpload;
