import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { deleteCustomer } from "redux/action/customer.action";

export default function FormDialog(props) {
  const CUSTOMER_STATE = useSelector(state => state.customer);
  const ORDER_STATE = useSelector(state => state.order);

  const dispatch = useDispatch();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteCustomerSuccess = () => {
    enqueueSnackbar("Customer Deleted Successfully", { variant: "success" });
    if (!CUSTOMER_STATE.deleteCustomerLoading) {
      ORDER_STATE.orders = null;
      ORDER_STATE.return_orders = null;
      props.setIsDeleteShow(false)
      history.push(`/admin/customers`);
    }
  };
  const handleDeleteCustomerFailure = ({ error }) => {
    enqueueSnackbar(error, { variant: "error" });
  };

  const handleClose = () => {
    props.setIsDeleteShow(false);
  };

  const handleDelete = () => {
    dispatch(deleteCustomer({ id: props.id }, handleDeleteCustomerSuccess, handleDeleteCustomerFailure));
  };

  return (
    <div>
      <Dialog open={props.isDeleteShow} onClose={handleClose}>
        <DialogTitle>
          <div style={{ display: "flex", alignItems: "center" }}>
            <DeleteOutlineIcon style={{ color: "red" }} />
            {"  "} Delete Customer
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText color="black">
            <span>
              Are you sure you want to delete this customer?
              <p>It will remove all data of customer.</p>
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" disabled={CUSTOMER_STATE.deleteCustomerLoading ? true : false} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={handleDelete}
            disabled={CUSTOMER_STATE.deleteCustomerLoading ? true : false}
            style={{ backgroundColor: "red", color: "white" }}
          >
            {CUSTOMER_STATE.deleteCustomerLoading ? "Loading" : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
