import React from "react";
import { Route } from "react-router-dom";

//core components
import ReturnOrderList from "components/ReturnOrder/index";
import ReturnOrderDetails from "components/ReturnOrderDetails/ReturnOrderDetails";

export default function ReturnOrders() {
  return (
    <div>
      <Route path={`/admin/return-orders/:id`} exact={true} component={ReturnOrderDetails} />
      <Route path={`/admin/return-orders`} exact={true} component={ReturnOrderList} />
    </div>
  );
}
