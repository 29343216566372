//auth

export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAILURE = "AUTH_LOGIN_FAILURE";

export const AUTH_USER_LOGOUT = "AUTH_USER_LOGOUT";
export const AUTH_USER_LOGOUT_SUCCESS = "AUTH_USER_LOGOUT_SUCCESS";
export const AUTH_USER_LOGOUT_FAILURE = "AUTH_USER_LOGOUT_FAILURE";

//customers
export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_FAILURE = "GET_CUSTOMER_FAILURE";

export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAILURE = "UPDATE_CUSTOMER_FAILURE";

export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_FAILURE = "DELETE_CUSTOMER_FAILURE";

export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAILURE = "GET_CUSTOMERS_FAILURE";

//Subscriptions
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";
export const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS";
export const GET_SUBSCRIPTIONS_FAILURE = "GET_SUBSCRIPTIONS_FAILURE";

export const UPDATE_SUBSCRIPTION_PRODUCTS = "UPDATE_SUBSCRIPTION_PRODUCTS";
export const UPDATE_SUBSCRIPTION_PRODUCTS_SUCCESS = "UPDATE_SUBSCRIPTION_PRODUCTS_SUCCESS";
export const UPDATE_SUBSCRIPTION_PRODUCTS_FAILURE = "UPDATE_SUBSCRIPTION_PRODUCTS_FAILURE";

export const UPDATE_SUBSCRIPTION_ITEMS = "UPDATE_SUBSCRIPTION_ITEMS";
export const UPDATE_SUBSCRIPTION_ITEMS_SUCCESS = "UPDATE_SUBSCRIPTION_ITEMS_SUCCESS";
export const UPDATE_SUBSCRIPTION_ITEMS_FAILURE = "UPDATE_SUBSCRIPTION_ITEMS_FAILURE";

//shipping Cost
export const GET_SHIPPING_COST = "GET_SHIPPING_COST";
export const GET_SHIPPING_COST_SUCCESS = "GET_SHIPPING_COST_SUCCESS";
export const GET_SHIPPING_COST_FAILURE = "GET_SHIPPING_COST_FAILURE";

export const UPDATE_SHIPPING_COST = "UPDATE_SHIPPING_COST";
export const UPDATE_SHIPPING_COST_SUCCESS = "UPDATE_SHIPPING_COST_SUCCESS";
export const UPDATE_SHIPPING_COST_FAILURE = "UPDATE_SHIPPING_COST_FAILURE";

//return cost
export const GET_RETURN_COST = "GET_RETURN_COST";
export const GET_RETURN_COST_SUCCESS = "GET_RETURN_COST_SUCCESS";
export const GET_RETURN_COST_FAILURE = "GET_RETURN_COST_FAILURE";

export const UPDATE_RETURN_COST = "UPDATE_RETURN_COST";
export const UPDATE_RETURN_COST_SUCCESS = "UPDATE_RETURN_COST_SUCCESS";
export const UPDATE_RETURN_COST_FAILURE = "UPDATE_RETURN_COST_FAILURE";

export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAILURE = "GET_ORDER_FAILURE";

export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAILURE = "GET_ORDERS_FAILURE";

export const GET_RETURN_ORDERS = "GET_RETURN_ORDERS";
export const GET_RETURN_ORDERS_SUCCESS = "GET_RETURN_ORDERS_SUCCESS";
export const GET_RETURN_ORDERS_FAILURE = "GET_RETURN_ORDERS_FAILURE";

export const GET_EXTRA_DETAILS = "GET_EXTRA_DETAILS";
export const GET_EXTRA_DETAILS_SUCCESS = "GET_EXTRA_DETAILS_SUCCESS";
export const GET_EXTRA_DETAILS_FAILURE = "GET_EXTRA_DETAILS_FAILURE";

export const GET_CARRIERS = "GET_CARRIERS";
export const GET_CARRIERS_SUCCESS = "GET_CARRIERS_SUCCESS";
export const GET_CARRIERS_FAILURE = "GET_CARRIERS_FAILURE";

export const GET_WAREHOUSES = "GET_WAREHOUSES";
export const GET_WAREHOUSES_SUCCESS = "GET_WAREHOUSES_SUCCESS";
export const GET_WAREHOUSES_FAILURE = "GET_WAREHOUSES_FAILURE";

export const UPDATE_ORDER = "UPDATE_ORDER";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILURE = "UPDATE_ORDER_FAILURE";

export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";

export const DELETE_PRODUCT_ITEM = "DELETE_PRODUCT_ITEM";
export const DELETE_PRODUCT_ITEM_SUCCESS = "DELETE_PRODUCT_ITEM_SUCCESS";
export const DELETE_PRODUCT_ITEM_FAILURE = "DELETE_PRODUCT_ITEM_FAILURE";

export const CREATE_PRODUCT_PRICES = "CREATE_PRODUCT_PRICES";
export const CREATE_PRODUCT_PRICES_SUCCESS = "CREATE_PRODUCT_PRICES_SUCCESS";
export const CREATE_PRODUCT_PRICES_FAILURE = "CREATE_PRODUCT_PRICES_FAILURE";

//trial period
export const GET_TRIAL_PERIOD_DAYS = "GET_TRIAL_PERIOD_DAYS";
export const GET_TRIAL_PERIOD_DAYS_SUCCESS = "GET_TRIAL_PERIOD_DAYS_SUCCESS";
export const GET_TRIAL_PERIOD_DAYS_FAILURE = "GET_TRIAL_PERIOD_DAYS_FAILURE";

export const UPDATE_TRIAL_PERIOD_DAYS = "UPDATE_TRIAL_PERIOD_DAYS";
export const UPDATE_TRIAL_PERIOD_DAYS_SUCCESS = "UPDATE_TRIAL_PERIOD_DAYS_SUCCESS";
export const UPDATE_TRIAL_PERIOD_DAYS_FAILURE = "UPDATE_TRIAL_PERIOD_DAYS_FAILURE";
