import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

//MATERIAL UI COMPONENTS
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Paper, Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";

// core components
import NoData from "assets/img/NoData.svg";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

//NPM PACKAGES
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//FUNCTIONS
import { getComparator, stableSort, EnhancedTableHead } from "./HelperFunction";
import { getProducts } from "redux/action/order.action";
import Loading from "components/Loading";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px"
  }
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  //VARIABLES
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("price_version");
  const [page] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const history = useHistory();
  const dispatch = useDispatch();
  const ORDER_STATE = useSelector(state => state.order);
  let products = ORDER_STATE.products;
  const [hooksDependency, setHookDependency] = React.useState(true);

  //FUNCTIONS
  useEffect(() => {
    if (hooksDependency) {
      if (!products) {
        dispatch(getProducts());
      }
      setHookDependency(false);
    }
  }, [hooksDependency, products, dispatch, rowsPerPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    // subscriptions.length < total_subscriptions && page < newPage && dispatch(getProuc({ page: newPage + 2, limit: rowsPerPage }));
    // setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  const onClickAddNewPrice = () => {
    history.push("/admin/products/add");
  };
  //CONSOLE LOG

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <div className={classes.root}>
          <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
            <Button startIcon={<Add />} color="primary" variant="contained" onClick={onClickAddNewPrice}>
              Add new prices
            </Button>
          </div>
          {ORDER_STATE.fetchingProductsLoading ? (
            <div className={classes.loading}>
              <Loading />
            </div>
          ) : (
            <div>
              {products && products.length > 0 ? (
                <div>
                  <Paper className={classes.paper}>
                    <TableContainer>
                      <Table className={classes.table} aria-labelledby="tableTitle" size={"medium"} aria-label="enhanced table">
                        <EnhancedTableHead classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={products.length} />
                        <TableBody>
                          {stableSort(products, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                              return (
                                <TableRow hover tabIndex={-1} key={index}>
                                  <TableCell align="left" padding="default">
                                    {row.price_version}
                                  </TableCell>
                                  <TableCell align="left">{`${row.box} €`}</TableCell>
                                  <TableCell align="left">{row.space_1 ? `${row.space_1} €` : "-"}</TableCell>
                                  <TableCell align="left">{row.space_1 ? `${row.space_2} €` : "-"}</TableCell>

                                  <TableCell align="left">{row.space_v2_01 ? `${row.space_v2_01} €` : "-"}</TableCell>
                                  <TableCell align="left">{row.space_v2_02 ? `${row.space_v2_02} €` : "-"}</TableCell>
                                  <TableCell align="left">{row.space_v2_04 ? `${row.space_v2_04} €` : "-"}</TableCell>
                                  <TableCell align="left">{row.space_v2_06 ? `${row.space_v2_06} €` : "-"}</TableCell>
                                  <TableCell align="left">{row.space_v2_08 ? `${row.space_v2_08} €` : "-"}</TableCell>
                                  <TableCell align="left">{row.space_v2_10 ? `${row.space_v2_10} €` : "-"}</TableCell>
                                  <TableCell align="left">{row.space_v2_16 ? `${row.space_v2_16} €` : "-"}</TableCell>
                                  <TableCell align="left">{row.space_v2_25 ? `${row.space_v2_25} €` : "-"}</TableCell>

                                  <TableCell align="left">{row.space_3 ? `${row.space_3} €` : "-"}</TableCell>
                                  <TableCell align="left">{row.space_4 ? `${row.space_4} €` : "-"}</TableCell>
                                  <TableCell align="left">{row.space_5 ? `${row.space_5} €` : "-"}</TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      component="div"
                      count={products.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </Paper>
                </div>
              ) : (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <img src={NoData} alt="" />
                  <label style={{ fontSize: "larger", padding: "20px" }}>There is no any Products</label>
                </div>
              )}
            </div>
          )}
        </div>
      </GridItem>
    </GridContainer>
  );
}
