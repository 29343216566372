import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { TextField } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { useSnackbar } from "notistack";

import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "../../../config/axios";
import { UploadImage } from "components/UploadImage";

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content"
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120
  },
  formControlLabel: {
    marginTop: theme.spacing(1)
  },
  col1: {
    fontWeight: "500",
    minWidth: "110px",
    padding: "10PX"
  },
  row1: {
    display: "flex",
    margin: "11px 0px 11px 0px"
  },
  details: {
    display: "flex",
    flexDirection: "column",
    padding: "30px",
    width: "90%"
  }
}));

export default function ItemAddDialog({ editItem, items, orderId, handleCloseItemDialog, handleCloseSuccessItemDialog }) {
  const [contentName, setContenName] = React.useState(editItem?.content_name || "");
  const [barcodeID, setBarcodeID] = React.useState("");
  const [product, setStatus] = React.useState(null);
  const [imageUrl, setImageUrl] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const onContentNameChange = event => {
    setContenName(event.target.value);
  };

  const onBarcodeIDChange = event => {
    setBarcodeID(event.target.value);
  };

  const handleStatusChange = event => {
    setStatus(event.target.value);
  };

  const handleSaveItemDialog = async () => {
    let errorMessage;

    if (!imageUrl) errorMessage = "Please upload barcode image";
    if (!barcodeID) errorMessage = "Please add barcode ID";
    if (!contentName) errorMessage = "Please add item name";

    if (errorMessage) {
      enqueueSnackbar(errorMessage, { variant: "error" });
      return;
    }

    const body = {
      ...(!editItem && { product_id: product || (items?.items && items?.items[0]?._id) }),
      content_name: contentName,
      barcode_id: barcodeID,
      barcode_image_url: imageUrl
    };
    setLoading(true);
    await axios
      .patch(editItem ? `/admin/update-order-item/${editItem?._id}` : `/admin/add-order-item/${orderId}`, body)
      .then(res => {
        handleCloseSuccessItemDialog();
      })
      .catch(error => {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
        return error;
      });
    setLoading(false);
  };

  const handleCloseItem = () => {
    handleCloseItemDialog();
  };

  const classes = useStyles();
  return (
    <React.Fragment>
      <Dialog fullWidth={true} maxWidth={"sm"} open={true} onClose={handleCloseItemDialog} aria-labelledby="max-width-dialog-title">
        <DialogTitle id="max-width-dialog-title">{editItem ? "Edit" : "Add"} Item</DialogTitle>
        <div className={classes.details}>
          {!editItem && (
            <div className={classes.row1}>
              <div className={classes.col1}>Product</div>
              <div style={{ width: "400px" }}>
                <TextField
                  id="standard-select-status"
                  select
                  label="Product"
                  value={product || items?.items?.[0]?._id}
                  fullWidth={true}
                  onChange={e => handleStatusChange(e)}
                  variant="outlined"
                >
                  {items.items.map(option => (
                    <MenuItem key={option._id} value={option._id}>
                      {option._id}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
          )}
          <div className={classes.row1}>
            <div className={classes.col1}>Content Name</div>
            <div style={{ width: "400px" }}>
              <TextField id="outlined-size-small" label="Content Name" rows={4} onChange={e => onContentNameChange(e)} value={contentName} variant="outlined" fullWidth={true} />
            </div>
          </div>
          <div className={classes.row1}>
            <div className={classes.col1}>Barcode ID</div>
            <div style={{ width: "400px" }}>
              <TextField id="outlined-size-small" label="Barcode ID" rows={4} onChange={e => onBarcodeIDChange(e)} value={barcodeID} variant="outlined" fullWidth={true} />
            </div>
          </div>
          <div className={classes.row1}>
            <div className={classes.col1}>Barcode Image</div>
            <UploadImage setImageUrl={setImageUrl} setIsImageUploading={setLoading} setBarcodeID={setBarcodeID} />
          </div>
        </div>
        <DialogActions>
          <Button onClick={handleSaveItemDialog} color="primary" disabled={loading}>
            {loading ? "Loading" : "Add"}
          </Button>

          <Button onClick={handleCloseItem} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
