import {
  GET_SHIPPING_COST,
  GET_SHIPPING_COST_SUCCESS,
  GET_SHIPPING_COST_FAILURE,
  UPDATE_SHIPPING_COST,
  UPDATE_SHIPPING_COST_SUCCESS,
  UPDATE_SHIPPING_COST_FAILURE,
  GET_TRIAL_PERIOD_DAYS,
  GET_TRIAL_PERIOD_DAYS_FAILURE,
  GET_TRIAL_PERIOD_DAYS_SUCCESS,
  UPDATE_TRIAL_PERIOD_DAYS,
  UPDATE_TRIAL_PERIOD_DAYS_SUCCESS,
  UPDATE_TRIAL_PERIOD_DAYS_FAILURE
} from "../action/actionType";

let initialState = {
  shippingCost: 0,
  fetchingShippingCostLoading: false,
  updateShippingCostLoading: false,
  fetchingTrialPeriodDaysLoading: false,
  updateTrialPeriodDaysLoading: false,
  trialPeriodDays: 0
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SHIPPING_COST:
      return {
        ...state,
        fetchingShippingCostLoading: true
      };
    case GET_SHIPPING_COST_SUCCESS:
      return {
        ...state,
        shippingCost: action.payload.shippingCost,
        fetchingShippingCostLoading: false
      };
    case GET_SHIPPING_COST_FAILURE:
      return {
        ...state,
        fetchingShippingCostError: action.payload.error
      };
    case UPDATE_SHIPPING_COST:
      return {
        ...state,
        updateShippingCostLoading: true
      };
    case UPDATE_SHIPPING_COST_SUCCESS:
      return {
        ...state,
        shippingCost: action.payload.shippingCost,
        updateShippingCostLoading: false
      };
    case UPDATE_SHIPPING_COST_FAILURE:
      return {
        ...state,
        updateShippingCostLoading: false,
        updateShippingCostError: action.payload.error
      };
    case GET_TRIAL_PERIOD_DAYS:
      return {
        ...state,
        fetchingTrialPeriodDaysLoading: true
      };
    case GET_TRIAL_PERIOD_DAYS_SUCCESS:
      return {
        ...state,
        trialPeriodDays: action.payload.trialPeriodDays,
        fetchingTrialPeriodDaysLoading: false
      };
    case GET_TRIAL_PERIOD_DAYS_FAILURE:
      return {
        ...state,
        fetchingTrialPeriodDaysError: action.payload.error
      };
    case UPDATE_TRIAL_PERIOD_DAYS:
      return {
        ...state,
        updateTrialPeriodDaysLoading: true
      };
    case UPDATE_TRIAL_PERIOD_DAYS_SUCCESS:
      return {
        ...state,
        trialPeriodDays: action.payload.trialPeriodDays,
        updateTrialPeriodDaysLoading: false
      };
    case UPDATE_TRIAL_PERIOD_DAYS_FAILURE:
      return {
        ...state,
        updateTrialPeriodDaysLoading: false,
        updateTrialPeriodDaysError: action.payload.error
      };
    default:
      return state;
  }
};

export default commonReducer;
