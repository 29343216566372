//HoC for check authentication
import React, { Component } from "react";

export default (ComposedComponent, auth) => {
  class RequireAuth extends Component {
    backtoHomePage() {
      this.props.history.push("/admin/login");
    }
    backtoDashboardPage() {
      this.props.history.replace("/admin/orders");
    }
    render() {
      let token = localStorage.getItem("user-token");
      if (auth) {
        if (!token) {
          //this part for login and signUp ..when user logged in it redirects to the dashboard
          return <ComposedComponent {...this.props} />;
        } else {
          this.backtoDashboardPage();
          return null;
        }
      } else {
        if (!token) {
          this.backtoHomePage();
          return null;
        } else {
          return <ComposedComponent {...this.props} />;
        }
      }
    }
  }
  return RequireAuth;
};
