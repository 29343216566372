import * as yup from "yup";

export const customerSchema = yup
  .object({
    name: yup.string().required("Campo obrigatório."),
    phone: yup
      .string()
      .trim()
      .max(9, "Invalid phone number")
      .notRequired()
      .matches(/[0-9]{9}/, "Invalid phone number"),
    nif: yup
      .string()
      .trim()
      .max(9, "Invalid NIF")
      .notRequired()
      .matches(/[0-9]{9}/, { message: "Invalid NIF" }),
    notes: yup.string().notRequired(),
    address: yup.string().optional(),
    city: yup.string().optional(),
    floor: yup.string().optional(),
    zipcode: yup
      .string()
      .max(8, "Invalid Zipcode")
      .notRequired()
      .matches(/[1-2]{1}[0-9]{1}[0-9]{1}[0-9]{1}-[0-9]{3}/, "invalid zipcode")
  })
  .required();