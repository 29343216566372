import {
  AUTH_LOGIN,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE
  // AUTH_USER_LOGOUT,
  // AUTH_USER_LOGOUT_SUCCESS,
  // AUTH_USER_LOGOUT_FAILURE
} from "./actionType";
import axios from "../../config/axios";

//LOGIN

export const authLogin = payload => {
  return {
    type: AUTH_LOGIN,
    payload: payload
  };
};

export const authLoginSuccess = payload => {
  return {
    type: AUTH_LOGIN_SUCCESS,
    payload: payload
  };
};

export const authLoginFailure = payload => {
  return {
    type: AUTH_LOGIN_FAILURE,
    payload: payload
  };
};

export const login = (body, handleLoginSuccess) => async dispatch => {
  dispatch(authLogin());

  axios
    .post(`/admin/login`, body)
    .then(res => {
      dispatch(authLoginSuccess(res.data));
      localStorage.setItem("user-token", res.data.data.token);
      handleLoginSuccess && handleLoginSuccess();
    })
    .catch(error => {
      dispatch(authLoginFailure(error.response.data.message));
    });
};
