import TextField from "@material-ui/core/TextField";
import { Controller } from "react-hook-form";
import React from "react";

export const FormInputText = ({ name, control, label, helperText, inputProps = {}, isError = false }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField variant="outlined" fullWidth={true} onChange={onChange} value={value} label={label} helperText={helperText} error={isError} {...inputProps} />
      )}
    />
  );
};
