import React from "react";
import { Route } from "react-router-dom";

//core components
import ProductList from "components/ProductList/ProductList";
import AddProductPrices from "components/AddProductPrices/AddProductPrices";

export default function ProductPrices() {
  return (
    <div>
      <Route path={`/admin/products/add`} exact={true} component={AddProductPrices} />
      <Route path={`/admin/products`} exact={true} component={ProductList} />
    </div>
  );
}
