import React, { useMemo, useState, useCallback } from "react";

import { useSnackbar } from "notistack";
import { find, isEmpty, uniq } from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { TextField } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

import axios from "../../../config/axios";
import { useDispatch, useSelector } from "react-redux";
import { getProductDetailsByProductType } from "utils";
import { getCustomer } from "redux/action/customer.action";
import { UploadImage } from "components/UploadImage";
import { getOrder } from "redux/action/order.action";

const useStyles = makeStyles(theme => ({
  details: {
    display: "flex",
    flexDirection: "column",
    padding: "30px",
    width: "90%"
  },
  col1: {
    fontWeight: "500",
    minWidth: "110px",
    padding: "10PX"
  },
  row1: {
    display: "flex",
    margin: "11px 0px 11px 0px"
  }
}));

export default function ProductAddDialog({ orderId, allowOrderSelection, userId, priceVersion, setIsOpenAddProductDialog }) {
  const dispatch = useDispatch();
  const CUSTOMER_STATE = useSelector(state => state.customer);
  const ORDER_STATE = useSelector(state => state.order);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [contentName, setContenName] = useState("");
  const [barcodeID, setBarcodeID] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const orderIds = useMemo(() => uniq(CUSTOMER_STATE?.customer?.subscription_products?.map(product => product?.order_id?._id)), [CUSTOMER_STATE]);
  const userProductTypes = useMemo(
    () => ORDER_STATE?.products && priceVersion && Object.keys(find(ORDER_STATE.products, product => product.price_version === priceVersion))?.slice(1),
    [ORDER_STATE, priceVersion]
  );

  const [newProductType, setNewProductType] = useState(userProductTypes?.[0]);
  const [selectedOrderId, setSelectedOrderId] = useState(orderId || orderIds?.[0]);

  const handleProductTypeChange = useCallback(event => {
    setNewProductType(event.target.value);
  }, []);

  const handleOrderIdChange = useCallback(event => {
    setSelectedOrderId(event.target.value);
  }, []);

  const handleCloseAddProductDialog = useCallback(() => {
    setIsOpenAddProductDialog(false);
  }, [setIsOpenAddProductDialog]);

  const handleAddProduct = useCallback(async () => {
    const body = {
      user_id: userId,
      product_type: newProductType,
      order_id: selectedOrderId,
      ...(!isEmpty(contentName) && { content_name: contentName }),
      ...(!isEmpty(barcodeID) && { barcode_id: barcodeID }),
      ...(!isEmpty(imageUrl) && { barcode_image_url: imageUrl })
    };
    setLoading(true);
    await axios
      .patch(`/admin/upgrade-subscription/add-product`, body)
      .then(res => {
        setIsOpenAddProductDialog(false);
        if (orderId) {
          dispatch(getOrder({ id: orderId, type: "purchase-order" }));
        } else {
          dispatch(getCustomer({ id: CUSTOMER_STATE?.customer?._id }));
        }
        enqueueSnackbar("Product added successfully", { variant: "success" });
      })
      .catch(error => {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
        setLoading(false);
        return error;
      });
  }, [dispatch, enqueueSnackbar, setIsOpenAddProductDialog, CUSTOMER_STATE, newProductType, selectedOrderId, contentName, barcodeID, imageUrl, orderId, userId]);

  const classes = useStyles();

  return (
    <React.Fragment>
      <Dialog fullWidth={true} maxWidth={"sm"} open={true} onClose={handleCloseAddProductDialog} aria-labelledby="max-width-dialog-title">
        <div className={classes.details}>
          <div className={classes.row1}>
            <div className={classes.col1}>Product Name</div>
            <div style={{ width: "400px" }}>
              <TextField id="standard-select-status" select label="Product" value={newProductType} fullWidth={true} onChange={e => handleProductTypeChange(e)} variant="outlined">
                {userProductTypes?.map(productType => (
                  <MenuItem key={productType} value={productType}>
                    {getProductDetailsByProductType(ORDER_STATE.allPriceVersionProducts[priceVersion], productType).name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          {allowOrderSelection && (
            <div className={classes.row1}>
              <div className={classes.col1}>Order ID</div>
              <div style={{ width: "400px" }}>
                <TextField id="standard-select-status" select label="Order ID" value={selectedOrderId} fullWidth={true} onChange={e => handleOrderIdChange(e)} variant="outlined">
                  {orderIds?.map(orderId => (
                    <MenuItem key={orderId} value={orderId}>
                      {orderId}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
          )}
          <h3 style={{ fontWeight: "400", textAlign: "center" }}>Item Details </h3>

          <div className={classes.row1}>
            <div className={classes.col1}>Content Name</div>
            <div style={{ width: "400px" }}>
              <TextField
                id="outlined-size-small"
                label="Content Name"
                rows={4}
                onChange={e => setContenName(e.target.value)}
                value={contentName}
                variant="outlined"
                fullWidth={true}
              />
            </div>
          </div>
          <div className={classes.row1}>
            <div className={classes.col1}>Barcode ID</div>
            <div style={{ width: "400px" }}>
              <TextField id="outlined-size-small" label="Barcode ID" rows={4} onChange={e => setBarcodeID(e.target.value)} value={barcodeID} variant="outlined" fullWidth={true} />
            </div>
          </div>
          <div className={classes.row1}>
            <div className={classes.col1}>Barcode Image</div>
            <UploadImage setImageUrl={setImageUrl} setIsImageUploading={setLoading} setBarcodeID={setBarcodeID} />
          </div>
        </div>
        <DialogActions>
          <Button onClick={handleAddProduct} color="primary" disabled={loading}>
            {loading ? "Loading" : "Add Product"}
          </Button>

          <Button onClick={handleCloseAddProductDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
