import React from "react";
import PropTypes from "prop-types";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { stripeDashboardURL } from "utils";

const headCells = [
  { id: "_id", numeric: false, disablePadding: false, label: "Subscription ID" },
  { id: "user_id", numeric: false, disablePadding: false, label: "User ID" },
  { id: "user_name", numeric: false, disablePadding: false, label: "Name" },
  {
    id: "status",
    disablePadding: true,
    label: "Status"
  },
  { id: "amount", numberic: false, disablePadding: false, label: "Amount" },
  { id: "products", numberic: false, disablePadding: false, label: "Products" },
  { id: "created_at", numeric: false, disablePadding: false, label: "Created Date" },
  { id: "current_period_start", numeric: false, disablePadding: false, label: "Start Date" },
  { id: "current_period_end", numeric: false, disablePadding: false, label: "Next Payment" }
];

function descendingComparator(a, b, orderBy) {
  const aOrderBy = orderBy === "amount" ? a["amount"]["value"] : a[orderBy];
  const bOrderBy = orderBy === "amount" ? b["amount"]["value"] : b[orderBy];
  if (bOrderBy < aOrderBy) {
    return -1;
  }
  if (bOrderBy > aOrderBy) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox" align="left"></TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? "right" : "left"}
            align="left"
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? <span className={classes.visuallyHidden}></span> : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const getCurrentSubscriptionId = subscription => {
  return subscription?.stripe_subscription_schedule_id || subscription.stripe_subscription_id || subscription.id;
};

const getCurrentSubscriptionURL = subscription => {
  return `${stripeDashboardURL}/${subscription.stripe_subscription_schedule_id ? "subscription_schedules" : "subscription"}/${getCurrentSubscriptionId(subscription)}`;
};

export { getComparator, stableSort, EnhancedTableHead, getCurrentSubscriptionId, getCurrentSubscriptionURL };
