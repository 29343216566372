import React, { useState, useEffect, useCallback } from "react";
import axios from "../../config/axios";
import Loading from "components/Loading";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { Button, makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Add, Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import { WarehouseAddOrEdit } from "./WarehouseAddOrEdit";
import IconButton from "@material-ui/core/IconButton";

import "./index.css";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    flexGrow: 1
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  loading: {
    display: "flex",
    justifyContent: "center"
  }
}));

const WarehouseList = () => {
  const classes = useStyles();
  const [warehouses, setWarehouses] = useState([]);
  const [editWarehouse, setEditWarehouse] = useState(null);
  const [fetchingWarehouses, setFetchingWarehouses] = useState(false);
  const [isOpenWarehousePopup, setIsOpenWarehousePopup] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const fetchWarehouses = useCallback(() => {
    setFetchingWarehouses(true);
    axios
      .get(`/admin/warehouses`)
      .then(res => {
        setFetchingWarehouses(false);
        setWarehouses(res.data.data.warehouses);
      })
      .catch(error => {
        enqueueSnackbar(error, { variant: "error" });
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (isEmpty(warehouses)) {
      fetchWarehouses();
    }
  }, [warehouses, fetchWarehouses]);

  const onClickAddNewWarehouse = () => {
    setIsOpenWarehousePopup(true);
  };
  const handleCloseWarehousePopup = () => {
    setIsOpenWarehousePopup(false);
    setEditWarehouse(null);
    fetchWarehouses();
  };

  const handlDeleteWarehouse = warehouseId => {
    axios.delete(`/admin/remove-warehouse/${warehouseId}`).then(() => {
      enqueueSnackbar("Warehouse removed successfully", { variant: "success" });
      fetchWarehouses();
    });
  };

  const handleEditWarehouse = warehouse => {
    setIsOpenWarehousePopup(true);
    setEditWarehouse(warehouse);
  };

  return (
    <div className="warehouse-list">
      <WarehouseAddOrEdit isOpen={isOpenWarehousePopup} handleClose={handleCloseWarehousePopup} editWarehouse={editWarehouse} />
      {fetchingWarehouses && isEmpty(warehouses) ? (
        <div className={classes.loading}>
          <Loading />
        </div>
      ) : (
        <div>
          {warehouses && warehouses.length > 0 && (
            <div>
              <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
                <Button startIcon={<Add />} color="primary" variant="contained" onClick={() => onClickAddNewWarehouse()}>
                  Add new warehouse
                </Button>
              </div>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Warehouse ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Zipcode</TableCell>
                      <TableCell>latitude</TableCell>
                      <TableCell>longitude</TableCell>
                      <TableCell>Admin Emails</TableCell>
                      <TableCell>Active</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {warehouses.map(warehouse => (
                      <TableRow key={warehouse._id}>
                        <TableCell> {warehouse._id}</TableCell>
                        <TableCell>{warehouse.name}</TableCell>
                        <TableCell>{warehouse.zipcode}</TableCell>
                        <TableCell>{warehouse.latitude}</TableCell>
                        <TableCell>{warehouse.longitude}</TableCell>
                        <TableCell>{warehouse.admin_emails.join(",")}</TableCell>
                        <TableCell>{warehouse.is_active ? "Yes" : "No"}</TableCell>
                        <TableCell>
                          <IconButton aria-label="expand row" size="small" onClick={() => handleEditWarehouse(warehouse)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton aria-label="expand row" size="small" onClick={() => handlDeleteWarehouse(warehouse._id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default WarehouseList;
