exports.localStorageKeys = {
  ROWS_PER_PAGE: "ROWS_PER_PAGE",
  PICKUP_ORDERS_TABLE_FILTER: "PICKUP_ORDERS_TABLE_FILTER",
  RETURN_ORDERS_TABLE_FILTER: "RETURN_ORDERS_TABLE_FILTER",
  CUSTOMERS_TABLE_FILTER: "CUSTOMERS_TABLE_FILTER",
  SUBSCRIPTIONS_TABLE_FILTER: "SUBSCRIPTIONS_TABLE_FILTER"
};

exports.orderShippingStatuses = {
  requires_payment_method: { name: "Requires payment method", color: "#c41d7f", backgroundColor: "#ffadd2", borderColor: "#ffadd2" },
  requires_confirmation: { name: "Requires confirmation", color: "#c41d7f", backgroundColor: "#ffadd2", borderColor: "#ffadd2" },
  requires_action: { name: "Requires action", color: "#c41d7f", backgroundColor: "#ffadd2", borderColor: "#ffadd2" },
  processing: { name: "Processing", color: "#7cb305", backgroundColor: "#eaff8f", borderColor: "#eaff8f" },
  requires_capture: { name: "Requires capture", color: "#c41d7f", backgroundColor: "#ffadd2", borderColor: "#ffadd2" },
  canceled: { name: "Canceled", color: "#cf1322", backgroundColor: "#ffa39e", borderColor: "#ffa39e" },
  succeeded: { name: "Succeeded", color: "#389e0d", backgroundColor: "#b7eb8f", borderColor: "#b7eb8f" }
};

exports.stripeSubscriptionStatuses = {
  incomplete: { name: "Incomplete", color: "#c41d7f", backgroundColor: "#ffadd2", borderColor: "#ffadd2" },
  incomplete_expired: { name: "Incomplete expired", color: "#c41d7f", backgroundColor: "#ffadd2", borderColor: "#ffadd2" },
  trialing: { name: "Trialing", color: "#c41d7f", backgroundColor: "#ffadd2", borderColor: "#ffadd2" },
  active: { name: "Active", color: "#389e0d", backgroundColor: "#b7eb8f", borderColor: "#b7eb8f" },
  past_due: { name: "Past due", color: "#c41d7f", backgroundColor: "#ffadd2", borderColor: "#ffadd2" },
  canceled: { name: "Canceled", color: "#cf1322", backgroundColor: "#ffa39e", borderColor: "#ffa39e" },
  unpaid: { name: "Unpaid", color: "#7cb305", backgroundColor: "#eaff8f", borderColor: "#eaff8f" }
};

exports.stripeSubscriptionScheduleStatuses = {
  not_started: { name: "Not started", color: "#c41d7f", backgroundColor: "#ffadd2", borderColor: "#ffadd2" },
  active: { name: "Active", color: "#c41d7f", backgroundColor: "#ffadd2", borderColor: "#ffadd2" },
  completed: { name: "Completed", color: "#c41d7f", backgroundColor: "#ffadd2", borderColor: "#ffadd2" },
  released: { name: "Released", color: "#389e0d", backgroundColor: "#b7eb8f", borderColor: "#b7eb8f" },
  canceled: { name: "Canceled", color: "#cf1322", backgroundColor: "#ffa39e", borderColor: "#ffa39e" }
};

exports.purchaseOrderStatus = {
  processing: "processing",
  confirmed: "confirmed",
  completed: "completed",
  cancelled: "cancelled"
};

exports.returnOrderStatus = {
  processing: "processing",
  confirmed: "confirmed",
  completed: "completed",
  cancelled: "cancelled"
};

exports.masterTableSlugs = Object.freeze({
  TRIAL_PERIOD: "TRIAL_PERIOD",
  SHIPPING_COST: "SHIPPING_COST",
  NEW_USER_REFERRAL_BONUS: "NEW_USER_REFERRAL_BONUS",
  EXISTING_USER_REFERRAL_BONUS: "EXISTING_USER_REFERRAL_BONUS",
  EXTRA_SHIPPING_COST_AFTER_KILOMETERS: "EXTRA_SHIPPING_COST_AFTER_KILOMETERS",
  EXTRA_EURO_PER_KILOMETER: "EXTRA_EURO_PER_KILOMETER"
});
