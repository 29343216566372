const csvFormat = {
  "/admin/orders": [
    ["_id", "ID"],
    ["user_name", "Name"],
    ["email", "Email"],
    ["products", "Products"],
    ["subscribed_price", "Subscribed amount"],
    ["city", "City"],
    ["zipcode", "Zipcode"],
    ["status", "Status"],
    ["created_at", "Created At"]
  ],
  "/admin/return-orders": [
    ["_id", "ID"],
    ["user_name", "Name"],
    ["email", "Email"],
    ["items", "Items"],
    ["city", "City"],
    ["zipcode", "Zipcode"],
    ["status", "Status"],
    ["created_at", "Created At"]
  ],
  "/get-subscriptions": [
    ["_id", "ID"],
    ["status", "Status"],
    ["products", "Products"],
    ["user_id", "User ID"],
    ["customer", "Stripe Customer ID"],
    ["current_period_start", "Start date"],
    ["current_period_end", "End date"],
    ["amount", "Amount"],
    ["created_at", "Created At"]
  ],
  "/admin/items": [
    ["_id", "ID"],
    ["content_name", "Contetn name"],
    ["quantity", "Quantity"],
    ["barcode_id", "Barcode ID"],
    ["barcode_image_url", "Image"],
    ["product_id", "Product ID"],
    ["user_id", "User ID"],
    ["order_id", "Order ID"],
    ["return_id", "Return ID"],
    ["created_at", "Created at"]
  ]
};
export default csvFormat;
