import React, { useEffect, useState } from "react";

//NPM PACKAGES
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { split, round } from "lodash";

//MATERIAL UI COMPONENTS
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Chip from "@material-ui/core/Chip";

//core components
import ShippingDetails from "components/ShippingDetails/index";
import { getExtraDetails, getOrder, getCarriers, getWarehouses, updateOrder } from "redux/action/order.action";
import { orderShippingStatuses } from "assets/constants/constants";
import { returnOrderStatus } from "assets/constants/constants";
import { ItemLists } from "./ItemLists";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const statuses = [
  {
    value: "processing",
    label: "Processing"
  },
  {
    value: "confirmed",
    label: "Confirmed"
  },
  {
    value: "completed",
    label: "Completed"
  },
  {
    value: "cancelled",
    label: "Cancelled"
  }
];

const ReturnOrderDetails = () => {
  //CONSTANTS

  const dispatch = useDispatch();
  const params = useParams();
  const ORDER_STATE = useSelector(state => state.order);
  const [selectedReturnDate, setSelectedReturnDate] = React.useState(null);
  const [selectedReturnTime, setSelectedReturnTime] = React.useState(null);
  const [status, setStatus] = React.useState("");
  const [carrier, setCarrier] = React.useState("");
  const [warehouse, setWarehouse] = React.useState("");
  const [oneTimeValue, setOneTimeValue] = useState(null);
  const [notes, setNotes] = React.useState("");
  const [updatedOrder, setUpdatedOrder] = React.useState({});
  const [showComponent, setShowComponent] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [hooksDependency, setHookDependency] = React.useState(true);
  const [hooksDependency2, setHookDependency2] = React.useState({
    random1: true,
    random2: true,
    random3: true,
    random4: true
  });
  //FUNCTIONS
  useEffect(() => {
    if (hooksDependency) {
      let { id } = params;
      const handleSuccess = ({ order }) => {
        if (order.status === "confirmed" && !order.carrier_id) {
          enqueueSnackbar("Depois de confirmares a encomenda, não te esqueças de associar um Carrier a este pedido.", { variant: "warning" });
        }
      };
      dispatch(getOrder({ id, type: "return-order" }, handleSuccess));
      setShowComponent(true);
      if (!ORDER_STATE.extraDetails) {
        dispatch(getExtraDetails());
      }
      if (!ORDER_STATE.carriers || !ORDER_STATE.warehouses) {
        dispatch(getCarriers());
        dispatch(getWarehouses());
      }
      setHookDependency(false);
    }
  }, [ORDER_STATE, dispatch, params, hooksDependency, enqueueSnackbar]); //[] ONLY TO REMOVE WARNINGS

  useEffect(() => {
    if (showComponent && ORDER_STATE.order && !status && hooksDependency2.random1) {
      let date = moment(split(ORDER_STATE?.order.return_date, "::")[0]).format("YYYY-MM-DD");
      let time = split(ORDER_STATE?.order?.return_date, "::")[1];
      setSelectedReturnDate(date);
      setSelectedReturnTime(time);

      setStatus(ORDER_STATE.order.status);
      setOneTimeValue(ORDER_STATE.order.amount.one_time.value);
      setNotes(ORDER_STATE.order.notes);
      setHookDependency2({ ...hooksDependency2, random1: false });
    }
    if (showComponent && ORDER_STATE.order && ORDER_STATE.carriers && !carrier && hooksDependency2.random2) {
      setCarrier(ORDER_STATE.order.carrier_id);
      setHookDependency2({ ...hooksDependency2, random2: false });
    }
    if (showComponent && ORDER_STATE.order && ORDER_STATE.warehouses && !warehouse && hooksDependency2.random3) {
      setWarehouse(ORDER_STATE.order.warehouse_id);
      setHookDependency2({ ...hooksDependency2, random3: false });
    }
    if (showComponent && ORDER_STATE.order && ORDER_STATE.order.return_date && !selectedReturnDate && hooksDependency2.random4) {
      setSelectedReturnDate(moment(split(ORDER_STATE?.order.return_date, "::")[0]).format("YYYY-MM-DD"));
      setHookDependency2({ ...hooksDependency2, random4: false });
    }
  }, [ORDER_STATE, carrier, hooksDependency2, selectedReturnDate, showComponent, status, warehouse]); // [ORDER_STATE] ADDED FOR REMOVE WARNINGS

  const handleupdateOrderSuccess = () => {
    enqueueSnackbar("Order Updated Successfully", { variant: "success" });
    if (updatedOrder?.status === "confirmed" && !carrier) {
      enqueueSnackbar("Depois de confirmares a encomenda, não te esqueças de associar um Carrier a este pedido.", { variant: "warning" });
    }
    setUpdatedOrder({});
  };
  const handleUpdateOrderFailure = ({ error }) => {
    enqueueSnackbar(error, { variant: "error" });
  };
  const HandleSubmit = event => {
    event.preventDefault();

    let order = { ...updatedOrder };
    if (order.date && order.time) {
      order.return_date = `${order.date}::${order.time}`;
      delete order.date;
      delete order.time;
    } else if (order.date) {
      order.return_date = `${order.date}::${split(ORDER_STATE.order.return_date, "::")[1]}`;
      delete order.date;
    } else if (order.time) {
      order.return_date = `${split(ORDER_STATE.order.return_date, "::")[0]}::${order.time}`;
      delete order.time;
    }
    dispatch(updateOrder({ updatedOrder: order, order_id: ORDER_STATE.order._id, type: "return-order" }, handleupdateOrderSuccess, handleUpdateOrderFailure));
  };

  const handleReturnDateChange = event => {
    if (moment(event.target.value).unix() > moment().unix()) {
      setSelectedReturnDate(event.target.value);

      setUpdatedOrder({ ...updatedOrder, date: moment(event.target.value).format("YYYY-MM-DD") });
    } else {
      //! show notification
    }
  };
  const handleReturnTimeChange = event => {
    setSelectedReturnTime(event.target.value);
    setUpdatedOrder({ ...updatedOrder, time: event.target.value });
  };
  const onChangeOneTimeValue = value => {
    setOneTimeValue(value);
    setUpdatedOrder({ ...updatedOrder, one_time: { value: round(value, 2), currency: "€" } });
  };
  const onChangeCarrier = value => {
    setCarrier(value);
    setUpdatedOrder({ ...updatedOrder, carrier_id: value });
  };
  const onChangeWarehouse = value => {
    setWarehouse(value);
    setUpdatedOrder({ ...updatedOrder, warehouse_id: value });
  };
  const onnotesChange = event => {
    setNotes(event.target.value);
    setUpdatedOrder({ ...updatedOrder, notes: event.target.value });
  };

  const handleStatusChange = event => {
    setStatus(event.target.value);
    setUpdatedOrder({ ...updatedOrder, status: event.target.value });
  };

  return (
    <div>
      <h3 style={{ fontWeight: "600" }}>Return Order Details</h3>

      {ORDER_STATE.fetchingOrderLoading ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
        <div>
          {showComponent && ORDER_STATE.order && ORDER_STATE.extraDetails && ORDER_STATE.carriers && ORDER_STATE.warehouses && (
            <div>
              <div className="details">
                <div className="title">Return Order #{ORDER_STATE.order._id}</div>
                <div className="card">
                  <div className="col">
                    <div className="row">
                      <div className="label">Return Date</div>
                      <div className="form-input">
                        <Grid>
                          <TextField
                            id="datetime-local"
                            label="Date"
                            type="date"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={selectedReturnDate === null ? "" : selectedReturnDate}
                            onChange={handleReturnDateChange}
                          />
                        </Grid>
                      </div>
                    </div>
                    <div className="row">
                      <div className="label">Return Time </div>
                      <div className="form-input">
                        <FormControl variant="outlined">
                          <InputLabel id="demo-simple-select-outlined-label">Time</InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={updatedOrder?.time ? updatedOrder.time : selectedReturnTime === null ? "" : selectedReturnTime}
                            onChange={handleReturnTimeChange}
                            label="Time"
                          >
                            {/* <MenuItem value="">"</MenuItem> */}
                            <MenuItem value="09:00 - 12:00">09:00 - 12:00</MenuItem>
                            <MenuItem value="13:00 - 15:00">13:00 - 15:00</MenuItem>
                            <MenuItem value="15:00 - 19:00">15:00 - 19:00</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className="row">
                      <div className="label">Status</div>
                      <div className="form-input">
                        <TextField
                          id="standard-select-status"
                          select
                          label="Select"
                          value={status === null ? "" : status}
                          onChange={e => handleStatusChange(e)}
                          helperText="Please select Status of order"
                          variant="outlined"
                        >
                          {statuses.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="label">Location</div>
                      <div>
                        <div className="row">
                          <div className="label">Address</div>
                          <div>{ORDER_STATE.order.location.address}</div>
                        </div>
                        <div className="row">
                          <div className="label">floor</div>
                          <div>{ORDER_STATE.order.location.floor}</div>
                        </div>
                        <div className="row">
                          <div className="label">City</div>
                          <div>{ORDER_STATE.order.location.city}</div>
                        </div>
                        <div className="row">
                          <div className="label">Zipcode</div>
                          <div>{ORDER_STATE.order.location.zipcode}</div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="label">Notes</div>
                      <div className="form-input">
                        <TextField
                          id="outlined-multiline-static"
                          label="Multiline"
                          multiline
                          rows={4}
                          onChange={e => onnotesChange(e)}
                          value={notes || ""}
                          variant="outlined"
                          fullWidth={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="save">
                  {ORDER_STATE.updateOrderLoading ? (
                    <Button variant="contained" color="primary" disabled={true}>
                      Loading
                    </Button>
                  ) : (
                    <Button variant="contained" color="primary" onClick={e => HandleSubmit(e)} disabled={Object.keys(updatedOrder).length <= 0 ? true : false}>
                      Save
                    </Button>
                  )}
                </div>
              </div>

              <div className="details">
                <div className="title">Shipping Details</div>
                <div className="card">
                  <div className="col">
                    <div className="row">
                      <div className="label">Shipping Amount</div>
                      <div className="form-input">
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            value={oneTimeValue === null ? "" : round(oneTimeValue, 2)}
                            onChange={e => {
                              onChangeOneTimeValue(e.target.value);
                            }}
                            disabled
                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                            labelWidth={60}
                          />
                        </FormControl>
                      </div>
                    </div>
                    {ORDER_STATE.order.stripe_shipping_details && (
                      <div className="row">
                        <div className="label">Shipping status</div>
                        <div>
                          <Chip
                            label={orderShippingStatuses[ORDER_STATE.order.stripe_shipping_details.p_intent_status]?.name}
                            variant="outlined"
                            style={{
                              border: "1px solid",
                              borderColor: orderShippingStatuses[ORDER_STATE.order.stripe_shipping_details.p_intent_status]?.borderColor,
                              backgroundColor: orderShippingStatuses[ORDER_STATE.order.stripe_shipping_details.p_intent_status]?.backgroundColor,
                              borderRadius: "5px",
                              fontWeight: "bold",
                              color: orderShippingStatuses[ORDER_STATE.order.stripe_shipping_details.p_intent_status]?.color
                            }}
                          />
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="label">Carrier</div>
                      <div className="form-input">
                        <div>
                          <TextField
                            id="standard-select-status"
                            select
                            fullWidth={true}
                            label="Select"
                            value={carrier === null ? "" : carrier}
                            onChange={e => onChangeCarrier(e.target.value)}
                            helperText="Please select carrier "
                            variant="outlined"
                          >
                            {ORDER_STATE.carriers.map(option => (
                              <MenuItem key={option._id} value={option._id}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="label">Warehouse</div>
                      <div className="form-input">
                        <div>
                          <TextField
                            id="standard-select-status"
                            select
                            fullWidth={true}
                            label="Select"
                            value={warehouse === null ? "" : warehouse}
                            onChange={e => onChangeWarehouse(e.target.value)}
                            helperText="Please select Warehouse"
                            variant="outlined"
                          >
                            {ORDER_STATE.warehouses.map(option => (
                              <MenuItem key={option._id} value={option._id}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <ShippingDetails listOfExtraDetails={ORDER_STATE.order.list_of_extra_details} />
                  </div>
                </div>
              </div>

              <div className="details">
                <div className="title">Customer Information</div>

                <div className="col">
                  <div className="row">
                    <div className="label">ID</div>
                    <Link to={`/admin/customers/${ORDER_STATE.order.user_id._id}`}>
                      <div style={{ fontWeight: "400" }}>{ORDER_STATE.order.user_id._id}</div>
                    </Link>
                  </div>
                  <div className="row">
                    <div className="label">Name</div>

                    <div>{ORDER_STATE.order.user_id.name}</div>
                  </div>
                  <div className="row">
                    <div className="label">Email</div>
                    <div>{ORDER_STATE.order.user_id.email}</div>
                  </div>
                  <div className="row">
                    <div className="label">Phone Number</div>
                    <div>{ORDER_STATE.order.user_id.phone}</div>
                  </div>
                  <div className="row">
                    <div className="label">NIF Number</div>
                    <div>{ORDER_STATE.order.user_id.nif}</div>
                  </div>
                </div>
              </div>
              <div className="details">
                <div className="title">Items</div>
                <div className="table">
                  <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell />
                          <StyledTableCell>ID</StyledTableCell>
                          <StyledTableCell align="center">Content Name</StyledTableCell>
                          <StyledTableCell align="center">Quantity</StyledTableCell>
                          <StyledTableCell align="center">Product ID</StyledTableCell>
                          <StyledTableCell align="center">Order ID</StyledTableCell>
                          {ORDER_STATE.order.status === returnOrderStatus.processing && <StyledTableCell align="center">Action</StyledTableCell>}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ORDER_STATE.order.items.map(item => (
                          <ItemLists item={item} />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ReturnOrderDetails;
