import React, { useMemo } from "react";
import { stripeSubscriptionScheduleStatuses, stripeSubscriptionStatuses } from "assets/constants/constants";
import { Chip } from "@material-ui/core";

const SubscriptionStatusChip = ({ stripe_subscription_schedule_id, stripe_subscription_schedule_status, stripe_subscription_status }) => {
  const statuses = useMemo(() => (stripe_subscription_schedule_id ? stripeSubscriptionScheduleStatuses : stripeSubscriptionStatuses), [stripe_subscription_schedule_id]);
  const stripeStatus = useMemo(() => (stripe_subscription_schedule_id ? stripe_subscription_schedule_status : stripe_subscription_status), [
    stripe_subscription_schedule_id,
    stripe_subscription_status,
    stripe_subscription_schedule_status
  ]);

  return (
    <Chip
      label={statuses[stripeStatus]?.name}
      variant="outlined"
      style={{
        border: "1px solid",
        borderColor: statuses[stripeStatus]?.borderColor,
        backgroundColor: statuses[stripeStatus]?.backgroundColor,
        borderRadius: "5px",
        fontWeight: "bold",
        color: statuses[stripeStatus]?.color
      }}
    />
  );
};

export default SubscriptionStatusChip;
