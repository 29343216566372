import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles, withStyles, Collapse, Box } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { TextField, Button } from "@material-ui/core";

import { KeyboardArrowDown, KeyboardArrowUp, Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";

import axios from "../../../config/axios";
import { returnOrderStatus } from "assets/constants/constants";
import { getOrder, removeItem } from "redux/action/order.action";
import { UploadImage } from "components/UploadImage";
import { isEmpty } from "lodash";

const useStyles = makeStyles(theme => ({
  col1: {
    fontWeight: "500",
    minWidth: "110px",
    padding: "10PX"
  },
  row1: {
    display: "flex",
    margin: "11px 0px 11px 0px"
  },
  details: {
    display: "flex",
    flexDirection: "column",
    padding: "30px",
    width: "90%"
  }
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

export const ItemLists = props => {
  const { item } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [uploadBarcoeId, setUploadBarcodeId] = useState(null);
  const [inputImageUrl, setInputImageUrl] = useState(null);
  const [inputBarcodeId, setInputBarcodeId] = useState(null);
  const [loading, setLoading] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const ORDER_STATE = useSelector(state => state.order);

  const handleDeleteItem = useCallback(
    itemId => {
      const handleRemoveItemSuccess = () => {
        dispatch(getOrder({ id: ORDER_STATE.order._id, type: "return-order" }));
        enqueueSnackbar("Item removed successfully", { variant: "success" });
      };
      const handleRemoveItemFailure = error => {
        enqueueSnackbar(error, { variant: "error" });
      };

      dispatch(removeItem({ orderType: "return-order", itemId, handleRemoveItemSuccess, handleRemoveItemFailure }));
    },
    [ORDER_STATE, dispatch, enqueueSnackbar]
  );

  const handleCloseItem = useCallback(() => {
    setInputImageUrl(null);
    setInputBarcodeId(null);
    setUploadBarcodeId(null);
  }, []);

  const handleUploadItem = async () => {
    const handleAddSubItemSuccess = () => {
      dispatch(getOrder({ id: ORDER_STATE.order._id, type: "return-order" }));
      enqueueSnackbar("bacode scanned successfully", { variant: "success" });
      handleCloseItem();
    };

    let errorMessage;
    if (isEmpty(inputImageUrl)) errorMessage = "Invalid image URL";
    if (inputBarcodeId !== uploadBarcoeId) errorMessage = "Barcode does not match.";

    if (!!errorMessage) {
      enqueueSnackbar(errorMessage, { variant: "error" });
      return;
    }

    setLoading(true);
    await axios
      .patch(`/admin/update-sub-items/return-order`, { item_id: item._id, barcode_id: `${inputBarcodeId}`, return_barcode_image_url: inputImageUrl })
      .then(res => {
        handleAddSubItemSuccess();
      })
      .catch(error => {
        enqueueSnackbar(error.response.data.message, { variant: "warning" });
        return error;
      });
    setLoading(false);
  };

  return (
    <React.Fragment>
      {uploadBarcoeId && (
        <Dialog fullWidth={true} maxWidth={"sm"} open={true} onClose={() => setUploadBarcodeId(null)} aria-labelledby="max-width-dialog-title">
          <DialogTitle id="max-width-dialog-title"> Scan Barcode</DialogTitle>
          <div className={classes.details}>
            <div className={classes.row1}>
              <div className={classes.col1}>Barcode ID</div>
              <div style={{ width: "400px" }}>
                <TextField
                  id="outlined-size-small"
                  label="Barcode ID"
                  rows={4}
                  onChange={e => setInputBarcodeId(e.target.value)}
                  value={inputBarcodeId}
                  variant="outlined"
                  fullWidth={true}
                />
              </div>
            </div>

            <div className={classes.row1}>
              <div className={classes.col1}>Barcode Image</div>
              <UploadImage setImageUrl={setInputImageUrl} setIsImageUploading={setLoading} setBarcodeID={setInputBarcodeId} />
            </div>
          </div>
          <DialogActions>
            <Button onClick={handleUploadItem} color="primary" disabled={loading}>
              {loading ? "Loading" : "Add"}
            </Button>

            <Button onClick={handleCloseItem} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <StyledTableRow key={item._id}>
        <StyledTableCell size="medium">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </StyledTableCell>

        <StyledTableCell>{item._id}</StyledTableCell>
        <StyledTableCell component="th" scope="row" align="center">
          {item.content_name}
        </StyledTableCell>
        <StyledTableCell align="center">{item.quantity}</StyledTableCell>
        <StyledTableCell align="center">{item.product_id}</StyledTableCell>
        <StyledTableCell align="center">
          <Link to={`/admin/orders/${item.order_id}`}>
            <div style={{ fontWeight: "400" }}>{item.order_id}</div>
          </Link>
        </StyledTableCell>
        {ORDER_STATE.order.status === returnOrderStatus.processing && (
          <StyledTableCell align="center">
            <IconButton aria-label="expand row" size="small" onClick={() => handleDeleteItem(item._id)}>
              <DeleteIcon />
            </IconButton>
          </StyledTableCell>
        )}
      </StyledTableRow>

      {item.sub_items.length > 0 && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, padding: "0px 74px" }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1, padding: "50px" }}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Pick up barcode</TableCell>
                      <TableCell>Return barcode</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.sub_items.map(subItem => (
                      <TableRow key={subItem._id}>
                        <TableCell>
                          <a href={subItem.barcode_image_url} target="_blank" rel="noopener noreferrer">
                            {subItem.barcode_id}
                          </a>
                        </TableCell>
                        <TableCell>
                          {subItem.return_barcode_image_url ? (
                            <a href={subItem.return_barcode_image_url} target="_blank" rel="noopener noreferrer">
                              {subItem.barcode_id}
                            </a>
                          ) : (
                            "NA"
                          )}
                        </TableCell>

                        <TableCell size="medium">
                          <IconButton aria-label="expand row" size="small" onClick={() => setUploadBarcodeId(subItem.barcode_id)}>
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};
