import React from "react";
import PropTypes from "prop-types";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const headCells = [
  { id: "price_version", numeric: false, disablePadding: false, label: "Price Version" },
  { id: "box", numeric: false, disablePadding: false, label: "Caixa" },
  { id: "space_1", numeric: false, disablePadding: false, label: "0.25m²" },
  { id: "space_2", numeric: false, disablePadding: false, label: "0.50m²" },
  { id: "space_v2_01", numeric: false, disablePadding: false, label: "1m²" },
  { id: "space_v2_02", numeric: false, disablePadding: false, label: "2m²" },
  { id: "space_v2_04", numeric: false, disablePadding: false, label: "4m²" },
  { id: "space_v2_06", numeric: false, disablePadding: false, label: "6m²" },
  { id: "space_v2_08", numeric: false, disablePadding: false, label: "8m²" },
  { id: "space_v2_10", numeric: false, disablePadding: false, label: "10m²" },
  { id: "space_v2_16", numeric: false, disablePadding: false, label: "16m²" },
  { id: "space_v2_25", numeric: false, disablePadding: false, label: "25m²" },

  { id: "space_3", numeric: false, disablePadding: false, label: "3m³" },
  { id: "space_4", numeric: false, disablePadding: false, label: "6m³" },
  { id: "space_5", numeric: false, disablePadding: false, label: "9m³" }
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox" align="left"></TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? "right" : "left"}
            align="left"
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? <span className={classes.visuallyHidden}></span> : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export { getComparator, stableSort, EnhancedTableHead };
