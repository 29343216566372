import React, { useState, useEffect } from "react";
import { map, size } from "lodash";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import "./shippingCosts.css";
import Loading from "components/Loading";
import { TextField, Table, TableBody, TableCell, TableHead, TableRow, InputAdornment, Chip, Button, Tabs, Tab, Box, Paper, Typography } from "@material-ui/core";
import { getShippingCosts, updateShippingCost } from "../../redux/action/order.action";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`nav-tabpanel-${index}`} aria-labelledby={`nav-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ShippingCosts = () => {
  const dispatch = useDispatch();
  const ORDER_STATE = useSelector(state => state.order);
  const [shippingCostValues, setShippingCostValues] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (!shippingCostValues?.length > 0) {
      setShippingCostValues(ORDER_STATE?.shippingCosts);
    }
  }, [ORDER_STATE, shippingCostValues, dispatch]);
  useEffect(() => {
    dispatch(getShippingCosts());
  }, [dispatch]);

  if (ORDER_STATE?.fetchingShippingCostLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  return (
    <div className="shipping-container">
      <div>
        <span className="cost-title">Shipping Cost Notation</span>
        <div className="shipping-cost__main-list">
          <ul>
            <li>Sem condições</li>
            <li>Embalamento especial</li>
            <li>mais que 2 andares sem elvador</li>
            <li>Precisa de desmontar</li>
          </ul>
          <ul className="shipping-cost__bedge-list">
            <li>
              <Badge avatar="0" />
            </li>
            <li>
              <Badge avatar="1" />
            </li>
            <li>
              <Badge avatar="3" />
            </li>
            <li>
              <Badge avatar="5" />
            </li>
          </ul>
        </div>
      </div>
      <Paper square>
        <Tabs
          value={selectedTab}
          onChange={(e, value) => {
            setSelectedTab(value);
          }}
          variant="fullWidth"
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="icon label tabs example"
        >
          <Tab label="Active" />
          <Tab label="Old" />
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          {shippingCostValues?.activeShippingCosts?.length > 0 && <ShippingCostTab costs={shippingCostValues.activeShippingCosts} />}
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          {shippingCostValues?.oldShippingCosts?.length > 0 && <ShippingCostTab costs={shippingCostValues.oldShippingCosts} />}
        </TabPanel>
      </Paper>
    </div>
  );
};

const ShippingCostTab = ({ costs }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [shippingCostValues, setShippingCostValues] = useState(costs);
  const [updateShippingCostLoading, setUpdateShippingCostLoading] = useState(false);
  const [newCosts, setNewCosts] = useState({});

  const onChangeCost = (position, value) => {
    const regex = /^[0-9\b]+$/;
    if (!(value === "" || regex.test(value))) return;

    let sc = [...shippingCostValues];
    sc[position[0]].pricing[position[1]].amount.value = Number(value);
    setShippingCostValues(sc);
    //
    let nc = { ...newCosts, [sc[position[0]]._id]: sc[position[0]].pricing };
    setNewCosts(nc);
  };
  const handleUpdateCostSuccess = () => {
    setUpdateShippingCostLoading(false);
    enqueueSnackbar("Shipping cost updated successfully", { variant: "success" });
  };
  const handleUpdateCostFailure = error => {
    setUpdateShippingCostLoading(false);
    enqueueSnackbar(error, { variant: "error" });
  };
  const handleSubmit = () => {
    setUpdateShippingCostLoading(true);
    dispatch(updateShippingCost({ newCosts, handleUpdateCostSuccess, handleUpdateCostFailure }));
  };

  return (
    <div style={{ display: "block", overflowX: "auto" }}>
      <Table>
        <TableHead>
          <TableRow className="cost-table__head-row">
            <TableCell></TableCell>
            <TableCell>Name</TableCell>
            {map([["0"], ["1"], ["3"], ["5"]], (value, index) => {
              return (
                <TableCell id={index}>
                  {map(value, avatar => {
                    return <Badge avatar={avatar} />;
                  })}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {map(shippingCostValues, (cost, index1) => {
            let url = `https://keepwhat-cdn.s3.eu-west-2.amazonaws.com/img/all-products/${cost.product_type_id.type}.svg`;

            return (
              <TableRow className="cost-table__row" id={index1}>
                <TableCell scope="row">
                  <img style={{ height: "70px" }} alt="product" src={url} />
                </TableCell>
                <TableCell scope="row">{cost.product_type_id.name}</TableCell>
                {map(cost.pricing, (price, index2) => {
                  return (
                    <TableCell align="left" id={index2}>
                      <InputText value={price.amount.value} currency={price.amount.currency} position={[index1, index2]} onChangeCost={onChangeCost} />
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <div>
        <Button onClick={handleSubmit} style={{ marginTop: "15px" }} variant="contained" disabled={!size(newCosts) || updateShippingCostLoading ? true : false} color="primary">
          {updateShippingCostLoading ? "Loading" : "Save"}
        </Button>
      </div>
    </div>
  );
};

const Badge = ({ avatar }) => {
  let badges = {
    0: { label: "Default", color: "", background: "mediumturquoise" },
    1: { label: "Special", color: "", background: "fuchsia" },
    3: { label: "No elevator", color: "", background: "aliceblue" },
    5: { label: "disassemble", color: "", background: "aqua" }
  };
  const [badgeState] = useState(badges[avatar]);

  return <Chip variant="outlined" style={{ color: badgeState.color, background: badgeState.background }} size="small" label={badgeState.label} />;
};

const InputText = ({ value, currency, position, onChangeCost }) => {
  return (
    <TextField
      id="outlined-adornment-amount"
      onChange={event => onChangeCost(position, event.target.value)}
      InputProps={{
        startAdornment: <InputAdornment position="start">{currency}</InputAdornment>
      }}
      variant="outlined"
      className="cost-table__input"
      value={value}
      type="Number"
      size="small"
    />
  );
};
export default ShippingCosts;
