import moment from "moment";
import { isBoolean, split } from "lodash";

function loadServerRows(data, filterKey, filterValue, operatorValue) {
  const serverRows = [...data];

  return new Promise(resolve => {
    setTimeout(() => {
      if (!filterValue) {
        resolve(serverRows);
        return;
      }
      resolve(
        serverRows.filter(row => {
          const first_pickup = isBoolean(row?.is_first_order) ? (row?.is_first_order ? "Yes" : "No") : "-";
          const shipping_payment_status = row?.stripe_shipping_details?.p_intent_status || "NA";
          const type = isBoolean(row?.partial) ? (row.partial ? "Partial" : "Total") : "NA";

          row.first_pickup = first_pickup;
          row.shipping_payment_status = shipping_payment_status;
          row.type = type;

          switch (filterKey) {
            case "id":
              if (row._id.startsWith("sub_")) {
                switch (operatorValue) {
                  case "contains":
                    return row._id ? row._id.toLowerCase().indexOf(filterValue) > -1 : false;
                  case "equals":
                    return row._id.toLowerCase() === filterValue ? true : false;
                  case "startsWith":
                    return row._id.toLowerCase().startsWith(filterValue);
                  case "endsWith":
                    return row._id.toLowerCase().endsWith(filterValue);
                  default:
                    return row._id.toLowerCase().indexOf(filterValue) > -1;
                }
              } else {
                switch (operatorValue) {
                  case ">":
                    return row._id > +filterValue ? true : false;
                  case "!=":
                    return row._id !== filterValue ? true : false;
                  case ">=":
                    return row._id >= +filterValue ? true : false;
                  case "<=":
                    return row._id <= +filterValue ? true : false;
                  case "<":
                    return row._id < +filterValue ? true : false;
                  case "isEmpty":
                    return !row._id ? true : false;
                  case "isNotEmpty":
                    return row._id ? true : false;
                  default:
                    return row._id === filterValue ? true : false;
                }
              }
            case "status":
              switch (operatorValue) {
                case "contains":
                  return row.status ? row.status.toLowerCase().indexOf(filterValue) > -1 : false;
                case "equals":
                  return row.status.toLowerCase() === filterValue ? true : false;
                case "startsWith":
                  return row.status.toLowerCase().startsWith(filterValue);
                case "endsWith":
                  return row.status.toLowerCase().endsWith(filterValue);
                default:
                  return row.status.toLowerCase().indexOf(filterValue) > -1;
              }
            case "sripe_sub_status":
              const stripe_sub_status = row?.stripe_subscription_schedule_id ? row?.stripe_subscription_schedule_status : row?.stripe_subscription_status;

              switch (operatorValue) {
                case "contains":
                  return stripe_sub_status ? stripe_sub_status.toLowerCase().indexOf(filterValue) > -1 : false;
                case "equals":
                  return stripe_sub_status.toLowerCase() === filterValue ? true : false;
                case "startsWith":
                  return stripe_sub_status.toLowerCase().startsWith(filterValue);
                case "endsWith":
                  return stripe_sub_status.toLowerCase().endsWith(filterValue);
                default:
                  return stripe_sub_status.toLowerCase().indexOf(filterValue) > -1;
              }

            case "created_at":
              switch (operatorValue) {
                case "is":
                  return moment(row.created_at).format("YYYY-MM-DD") === filterValue ? true : false;
                case "not":
                  return moment(row.created_at).format("YYYY-MM-DD") !== filterValue ? true : false;
                case "after":
                  return moment(row.created_at).format("YYYY-MM-DD") > filterValue ? true : false;
                case "before":
                  return moment(row.created_at).format("YYYY-MM-DD") < filterValue ? true : false;
                case "onOrAfter":
                  return moment(row.created_at).format("YYYY-MM-DD") >= filterValue ? true : false;
                case "onOrBefore":
                  return moment(row.created_at).format("YYYY-MM-DD") <= filterValue ? true : false;
                default:
                  return row.created_at ? row.created_at.toLowerCase().indexOf(filterValue) > -1 : "";
              }
            case "start_date":
              switch (operatorValue) {
                case "is":
                  return moment(row.current_period_start * 1000).format("YYYY-MM-DD") === filterValue ? true : false;
                case "not":
                  return moment(row.current_period_start * 1000).format("YYYY-MM-DD") !== filterValue ? true : false;
                case "after":
                  return moment(row.current_period_start * 1000).format("YYYY-MM-DD") > filterValue ? true : false;
                case "before":
                  return moment(row.current_period_start * 1000).format("YYYY-MM-DD") < filterValue ? true : false;
                case "onOrAfter":
                  return moment(row.current_period_start * 1000).format("YYYY-MM-DD") >= filterValue ? true : false;
                case "onOrBefore":
                  return moment(row.current_period_start * 1000).format("YYYY-MM-DD") <= filterValue ? true : false;
                default:
                  return row.current_period_start ? row.current_period_start.toLowerCase().indexOf(filterValue) > -1 : "";
              }
            case "next_payment":
              switch (operatorValue) {
                case "is":
                  return moment(row.current_period_end * 1000).format("YYYY-MM-DD") === filterValue ? true : false;
                case "not":
                  return moment(row.current_period_end * 1000).format("YYYY-MM-DD") !== filterValue ? true : false;
                case "after":
                  return moment(row.current_period_end * 1000).format("YYYY-MM-DD") > filterValue ? true : false;
                case "before":
                  return moment(row.current_period_end * 1000).format("YYYY-MM-DD") < filterValue ? true : false;
                case "onOrAfter":
                  return moment(row.current_period_end * 1000).format("YYYY-MM-DD") >= filterValue ? true : false;
                case "onOrBefore":
                  return moment(row.current_period_end * 1000).format("YYYY-MM-DD") <= filterValue ? true : false;
                default:
                  return row.current_period_end ? row.current_period_end.toLowerCase().indexOf(filterValue) > -1 : "";
              }
            case "user_id":
              switch (operatorValue) {
                case ">":
                  return row.user_id > +filterValue ? true : false;
                case "!=":
                  return row.user_id !== filterValue ? true : false;
                case ">=":
                  return row.user_id >= +filterValue ? true : false;
                case "<=":
                  return row.user_id <= +filterValue ? true : false;
                case "<":
                  return row.user_id < +filterValue ? true : false;
                default:
                  return row.user_id ? row.user_id.indexOf(filterValue) > -1 : "";
              }
            case "user_name":
              switch (operatorValue) {
                case "contains":
                  return row.user_name ? row.user_name.toLowerCase().indexOf(filterValue) > -1 : false;
                case "equals":
                  return row.user_name.toLowerCase() === filterValue ? true : false;
                case "startsWith":
                  return row.user_name.toLowerCase().startsWith(filterValue);
                case "endsWith":
                  return row.user_name.toLowerCase().endsWith(filterValue);
                default:
                  return row.user_name.toLowerCase().indexOf(filterValue) > -1;
              }
            case "phone":
              if (row.phone) {
                switch (operatorValue) {
                  case "contains":
                    return row.phone ? row.phone.toLowerCase().indexOf(filterValue) > -1 : false;
                  case "equals":
                    return row.phone.toLowerCase() === filterValue ? true : false;
                  case "startsWith":
                    return row.phone.toLowerCase().startsWith(filterValue);
                  case "endsWith":
                    return row.phone.toLowerCase().endsWith(filterValue);
                  default:
                    return row.phone.toLowerCase().indexOf(filterValue) > -1;
                }
              }
              return false;
            case "name":
              if (row.name) {
                switch (operatorValue) {
                  case "contains":
                    return row.name ? row.name.toLowerCase().indexOf(filterValue) > -1 : false;
                  case "equals":
                    return row.name.toLowerCase() === filterValue ? true : false;
                  case "startsWith":
                    return row.name.toLowerCase().startsWith(filterValue);
                  case "endsWith":
                    return row.name.toLowerCase().endsWith(filterValue);
                  default:
                    return row.name.toLowerCase().indexOf(filterValue) > -1;
                }
              }
              return false;
            case "subscribed_price":
              if (row.subscription) {
                switch (operatorValue) {
                  case ">":
                    return row.subscription.amount.value > +filterValue ? true : false;
                  case "!=":
                    return row.subscription.amount.value !== +filterValue ? true : false;
                  case ">=":
                    return row.subscription.amount.value >= +filterValue ? true : false;
                  case "<=":
                    return row.subscription.amount.value <= +filterValue ? true : false;
                  case "<":
                    return row.subscription.amount.value < +filterValue ? true : false;
                  default:
                    return row.subscription.amount.value === +filterValue ? true : false;
                }
              } else {
                switch (operatorValue) {
                  case ">":
                    return row.subscribed_price > +filterValue ? true : false;
                  case "!=":
                    return row.subscribed_price !== +filterValue ? true : false;
                  case ">=":
                    return row.subscribed_price >= +filterValue ? true : false;
                  case "<=":
                    return row.subscribed_price <= +filterValue ? true : false;
                  case "<":
                    return row.subscribed_price < +filterValue ? true : false;
                  default:
                    return row.subscribed_price === +filterValue ? true : false;
                }
              }
            case "shipping_cost":
              switch (operatorValue) {
                case ">":
                  return row.shipping_cost > +filterValue ? true : false;
                case "!=":
                  return row.shipping_cost !== +filterValue ? true : false;
                case ">=":
                  return row.shipping_cost >= +filterValue ? true : false;
                case "<=":
                  return row.shipping_cost <= +filterValue ? true : false;
                case "<":
                  return row.shipping_cost < +filterValue ? true : false;
                default:
                  return row.shipping_cost === +filterValue ? true : false;
              }
            case "items_count":
              switch (operatorValue) {
                case ">":
                  return row.items_count > +filterValue ? true : false;
                case "!=":
                  return row.items_count !== +filterValue ? true : false;
                case ">=":
                  return row.items_count >= +filterValue ? true : false;
                case "<=":
                  return row.items_count <= +filterValue ? true : false;
                case "<":
                  return row.items_count < +filterValue ? true : false;
                default:
                  return row.items_count === +filterValue ? true : false;
              }
            case "email":
              switch (operatorValue) {
                case "contains":
                  return row.email ? row.email.toLowerCase().indexOf(filterValue) > -1 : false;
                case "equals":
                  return row.email.toLowerCase() === filterValue ? true : false;
                case "startsWith":
                  return row.email.toLowerCase().startsWith(filterValue);
                case "endsWith":
                  return row.email.toLowerCase().endsWith(filterValue);
                default:
                  return row.email.toLowerCase().indexOf(filterValue) > -1;
              }
            case "products":
              if (typeof row.products === "string") {
                switch (operatorValue) {
                  case "contains":
                    return row.products ? row.products.toLowerCase().indexOf(filterValue) > -1 : false;
                  case "equals":
                    return row.products.toLowerCase() === filterValue ? true : false;
                  case "startsWith":
                    return row.products.toLowerCase().startsWith(filterValue);
                  case "endsWith":
                    return row.products.toLowerCase().endsWith(filterValue);
                  default:
                    return row.products.toLowerCase().indexOf(filterValue) > -1;
                }
              } else {
                switch (operatorValue) {
                  case ">":
                    return row.products > +filterValue ? true : false;
                  case "!=":
                    return row.products !== +filterValue ? true : false;
                  case ">=":
                    return row.products >= +filterValue ? true : false;
                  case "<=":
                    return row.products <= +filterValue ? true : false;
                  case "<":
                    return row.products < +filterValue ? true : false;
                  default:
                    return row.products === +filterValue ? true : false;
                }
              }
            case "products_count":
              switch (operatorValue) {
                case ">":
                  return row.products?.length > +filterValue ? true : false;
                case "!=":
                  return row.products?.length !== +filterValue ? true : false;
                case ">=":
                  return row.products?.length >= +filterValue ? true : false;
                case "<=":
                  return row.products?.length <= +filterValue ? true : false;
                case "<":
                  return row.products?.length < +filterValue ? true : false;
                default:
                  return row.products?.length === +filterValue ? true : false;
              }
            case "city":
              switch (operatorValue) {
                case "contains":
                  return row.city ? row.city.toLowerCase().indexOf(filterValue) > -1 : false;
                case "equals":
                  return row.city.toLowerCase() === filterValue ? true : false;
                case "startsWith":
                  return row.city.toLowerCase().startsWith(filterValue);
                case "endsWith":
                  return row.city.toLowerCase().endsWith(filterValue);
                default:
                  return row.city.toLowerCase().indexOf(filterValue) > -1;
              }
            case "zipcode":
              switch (operatorValue) {
                case "contains":
                  return row.zipcode ? row.zipcode.toLowerCase().indexOf(filterValue) > -1 : false;
                case "equals":
                  return row.zipcode.toLowerCase() === filterValue ? true : false;
                case "startsWith":
                  return row.zipcode.toLowerCase().startsWith(filterValue);
                case "endsWith":
                  return row.zipcode.toLowerCase().endsWith(filterValue);
                default:
                  return row.zipcode.toLowerCase().indexOf(filterValue) > -1;
              }
            case "amount":
              const amount = row?.subscription?.amount || row?.amount?.value;

              switch (operatorValue) {
                case ">":
                  return amount > +filterValue ? true : false;
                case "!=":
                  return amount !== +filterValue ? true : false;
                case ">=":
                  return amount >= +filterValue ? true : false;
                case "<=":
                  return amount <= +filterValue ? true : false;
                case "<":
                  return amount < +filterValue ? true : false;
                default:
                  return amount === +filterValue ? true : false;
              }
            case "first_pickup":
              switch (operatorValue) {
                case "contains":
                  return row.first_pickup ? row.first_pickup.toLowerCase().indexOf(filterValue) > -1 : false;
                case "equals":
                  return row.first_pickup.toLowerCase() === filterValue ? true : false;
                case "startsWith":
                  return row.first_pickup.toLowerCase().startsWith(filterValue);
                case "endsWith":
                  return row.first_pickup.toLowerCase().endsWith(filterValue);
                default:
                  return row.first_pickup.toLowerCase().indexOf(filterValue) > -1;
              }
            case "pickup_date":
              switch (operatorValue) {
                case "is":
                  return moment(split(row.pickup_date, "::")?.[0]).format("YYYY-MM-DD") === filterValue ? true : false;
                case "not":
                  return moment(split(row.pickup_date, "::")?.[0]).format("YYYY-MM-DD") !== filterValue ? true : false;
                case "after":
                  return moment(split(row.pickup_date, "::")?.[0]).format("YYYY-MM-DD") > filterValue ? true : false;
                case "before":
                  return moment(split(row.pickup_date, "::")?.[0]).format("YYYY-MM-DD") < filterValue ? true : false;
                case "onOrAfter":
                  return moment(split(row.pickup_date, "::")?.[0]).format("YYYY-MM-DD") >= filterValue ? true : false;
                case "onOrBefore":
                  return moment(split(row.pickup_date, "::")?.[0]).format("YYYY-MM-DD") <= filterValue ? true : false;
                default:
                  return split(row.pickup_date, "::")?.[0] ? split(row.pickup_date, "::")?.[0].toLowerCase().indexOf(filterValue) > -1 : "";
              }
            case "return_date":
              switch (operatorValue) {
                case "is":
                  return moment(split(row.return_date, "::")?.[0]).format("YYYY-MM-DD") === filterValue ? true : false;
                case "not":
                  return moment(split(row.return_date, "::")?.[0]).format("YYYY-MM-DD") !== filterValue ? true : false;
                case "after":
                  return moment(split(row.return_date, "::")?.[0]).format("YYYY-MM-DD") > filterValue ? true : false;
                case "before":
                  return moment(split(row.return_date, "::")?.[0]).format("YYYY-MM-DD") < filterValue ? true : false;
                case "onOrAfter":
                  return moment(split(row.return_date, "::")?.[0]).format("YYYY-MM-DD") >= filterValue ? true : false;
                case "onOrBefore":
                  return moment(split(row.return_date, "::")?.[0]).format("YYYY-MM-DD") <= filterValue ? true : false;
                default:
                  return split(row.return_date, "::")?.[0] ? split(row.return_date, "::")?.[0].toLowerCase().indexOf(filterValue) > -1 : "";
              }
            case "shipping_payment_status":
              switch (operatorValue) {
                case "contains":
                  return row.shipping_payment_status ? row.shipping_payment_status.toLowerCase().indexOf(filterValue) > -1 : false;
                case "equals":
                  return row.shipping_payment_status === filterValue ? true : false;
                case "startsWith":
                  return row.shipping_payment_status.toLowerCase().startsWith(filterValue);
                case "endsWith":
                  return row.shipping_payment_status.toLowerCase().endsWith(filterValue);
                default:
                  return row.shipping_payment_status.toLowerCase().indexOf(filterValue) > -1;
              }

            case "warehouse_name":
              switch (operatorValue) {
                case "contains":
                  return row.warehouse_name ? row.warehouse_name.toLowerCase().indexOf(filterValue) > -1 : false;
                case "equals":
                  return row.warehouse_name === filterValue ? true : false;
                case "startsWith":
                  return row.warehouse_name.toLowerCase().startsWith(filterValue);
                case "endsWith":
                  return row.warehouse_name.toLowerCase().endsWith(filterValue);
                default:
                  return row.warehouse_name.toLowerCase().indexOf(filterValue) > -1;
              }
            case "carrier_name":
              switch (operatorValue) {
                case "contains":
                  return row.carrier_name ? row.carrier_name.toLowerCase().indexOf(filterValue) > -1 : false;
                case "equals":
                  return row.carrier_name === filterValue ? true : false;
                case "startsWith":
                  return row.carrier_name.toLowerCase().startsWith(filterValue);
                case "endsWith":
                  return row.carrier_name.toLowerCase().endsWith(filterValue);
                default:
                  return row.carrier_name.toLowerCase().indexOf(filterValue) > -1;
              }
            case "type":
              switch (operatorValue) {
                case "contains":
                  return row.type ? row.type.toLowerCase().indexOf(filterValue) > -1 : false;
                case "equals":
                  return row.type.toLowerCase() === filterValue ? true : false;
                case "startsWith":
                  return row.type.toLowerCase().startsWith(filterValue);
                case "endsWith":
                  return row.type.toLowerCase().endsWith(filterValue);
                default:
                  return row.type.toLowerCase().indexOf(filterValue) > -1;
              }
            default:
              return serverRows;
          }
        })
      );
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

export { loadServerRows };
