import {
  GET_EXTRA_DETAILS,
  GET_EXTRA_DETAILS_SUCCESS,
  GET_EXTRA_DETAILS_FAILURE,
  GET_CARRIERS,
  GET_CARRIERS_SUCCESS,
  GET_CARRIERS_FAILURE,
  GET_WAREHOUSES,
  GET_WAREHOUSES_SUCCESS,
  GET_WAREHOUSES_FAILURE,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILURE,
  GET_ORDERS,
  GET_RETURN_ORDERS,
  GET_RETURN_ORDERS_SUCCESS,
  GET_RETURN_ORDERS_FAILURE,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILURE,
  GET_SHIPPING_COST,
  GET_SHIPPING_COST_SUCCESS,
  GET_SHIPPING_COST_FAILURE,
  UPDATE_SHIPPING_COST,
  UPDATE_SHIPPING_COST_SUCCESS,
  UPDATE_SHIPPING_COST_FAILURE,
  GET_RETURN_COST,
  GET_RETURN_COST_SUCCESS,
  GET_RETURN_COST_FAILURE,
  UPDATE_RETURN_COST,
  UPDATE_RETURN_COST_SUCCESS,
  UPDATE_RETURN_COST_FAILURE,
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  CREATE_PRODUCT_PRICES,
  CREATE_PRODUCT_PRICES_SUCCESS,
  CREATE_PRODUCT_PRICES_FAILURE,
  DELETE_PRODUCT_ITEM,
  DELETE_PRODUCT_ITEM_SUCCESS,
  DELETE_PRODUCT_ITEM_FAILURE
} from "../action/actionType";

let initialState = {
  //EXTRA DETAILS
  extraDetails: null, //[]
  fetchingExtraDetailsLoading: false,
  fetchingExtraDetailsError: "",

  //CARRIERS
  carriers: null, //[]
  fetchingCarriersLoading: false,
  fetchingCarriersError: "",

  //WAREHOUSES
  warehouses: null, //[]
  fetchingWarehousesLoading: false,
  fetchingWarehousesError: "",

  //ORDER
  order: null,
  fetchingOrderLoading: false,
  fetchingOrderError: "",

  //ORDERS
  fetchingOrdersLoading: false,
  fetchingOrdersError: "",
  orders: null,
  total_orders: 0,
  processing_orders: null, //[]
  processing_total_orders: 0,
  confirmed_orders: null,
  confirmed_total_orders: 0,
  completed_orders: null,
  completed_total_orders: 0,
  cancelled_orders: null,
  cancelled_total_orders: 0,

  //RETURN ORDERS
  fetchingReturnOrdersLoading: false,
  fetchingReturnOrdersError: "",
  return_orders: null,
  total_return_orders: 0,
  processing_return_orders: null, //[]
  processing_total_return_orders: 0,
  confirmed_return_orders: null,
  confirmed_total_return_orders: 0,
  completed_return_orders: null,
  completed_total_return_orders: 0,
  cancelled_return_orders: null,
  cancelled_total_return_orders: 0,

  //UPDATE ORDER
  updateOrderLoading: false,
  updateOrderError: false,

  //SHIPPING COST
  shippingCosts: {},
  fetchingShippingCostLoading: false,
  fetchingShippingCostError: null,
  updateShippingCostLoading: false,
  updateShippingCostError: null,

  //RETURN COST
  returnCosts: {},
  fetchingReturnCostLoading: false,
  fetchingReturnCostError: null,
  updateReturnCostLoading: false,
  updateReturnCostError: null,

  //PRODUCT PRICES
  products: null, //[]
  fetchingProductsLoading: false,
  fetchingProductsError: "",
  createProductPricesLoading: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EXTRA_DETAILS:
      return {
        ...state,
        fetchingExtraDetailsLoading: true,
        fetchingExtraDetailsError: ""
      };
    case GET_EXTRA_DETAILS_SUCCESS:
      return {
        ...state,
        fetchingExtraDetailsLoading: false,
        extraDetails: action.payload.extraDetails
      };
    case GET_EXTRA_DETAILS_FAILURE:
      return {
        ...state,
        fetchingExtraDetailsLoading: false,
        fetchingExtraDetailsError: ""
      };

    case GET_CARRIERS:
      return {
        ...state,
        fetchingCarriersLoading: true,
        fetchingCarriersError: ""
      };
    case GET_CARRIERS_SUCCESS:
      return {
        ...state,
        fetchingCarriersLoading: false,
        carriers: action.payload.carriers
      };
    case GET_CARRIERS_FAILURE:
      return {
        ...state,
        fetchingCarriersLoading: false,
        fetchingCarriersError: action.payload.error
      };

    case GET_WAREHOUSES:
      return {
        ...state,
        fetchingWarehousesLoading: true,
        fetchingWarehousesError: ""
      };
    case GET_WAREHOUSES_SUCCESS:
      return {
        ...state,
        fetchingWarehousesLoading: false,
        warehouses: action.payload.warehouses
      };
    case GET_WAREHOUSES_FAILURE:
      return {
        ...state,
        fetchingWarehousesLoading: false,
        fetchingWarehousesError: action.payload.error
      };

    case GET_ORDER:
      return {
        ...state,
        order: null,
        fetchingOrderLoading: true,
        fetchingOrderError: ""
      };
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        fetchingOrderLoading: false,
        order: action.payload.order
      };
    case GET_ORDER_FAILURE:
      return {
        ...state,
        fetchingOrderLoading: false,
        fetchingOrderError: action.payload.error
      };

    case GET_ORDERS: {
      let newOrders = {};
      if (action.payload.rowsPerPageChanged) {
        newOrders = {
          orders: null,
          total_orders: 0,
          processing_orders: null, //[]
          processing_total_orders: 0,
          confirmed_orders: null,
          confirmed_total_orders: 0,
          completed_orders: null,
          completed_total_orders: 0,
          cancelled_orders: null,
          cancelled_total_orders: 0
        };
      }
      return {
        ...state,
        ...newOrders,
        fetchingOrdersLoading: true,
        fetchingOrdersError: ""
      };
    }
    case GET_ORDERS_SUCCESS:
      let new_orders = action.payload.orders;
      return {
        ...state,
        fetchingOrdersLoading: false,
        orders: new_orders,
        total_orders: action.payload.total
      };

    case GET_ORDERS_FAILURE:
      return {
        ...state,
        fetchingOrdersLoading: false,
        fetchingOrdersError: action.payload.error
      };

    case GET_RETURN_ORDERS: {
      let newReturnOrders = {};
      if (action.payload.rowsPerPageChanged) {
        newReturnOrders = {
          processing_return_orders: null, //[]
          processing_total_return_orders: 0,
          confirmed_return_orders: null,
          confirmed_total_return_orders: 0,
          completed_return_orders: null,
          completed_total_return_orders: 0,
          cancelled_return_orders: null,
          cancelled_total_return_orders: 0
        };
      }
      return {
        ...state,
        ...newReturnOrders,
        fetchingReturnOrdersLoading: true,
        fetchingReturnOrdersError: ""
      };
    }
    case GET_RETURN_ORDERS_SUCCESS:
      let new_return_orders = action.payload.returnOrders;
      return {
        ...state,
        fetchingReturnOrdersLoading: false,
        return_orders: new_return_orders,
        total_return_orders: action.payload.total
      };

    case GET_RETURN_ORDERS_FAILURE:
      return {
        ...state,
        fetchingReturnOrdersLoading: false,
        fetchingReturnOrdersError: action.payload.error
      };

    case UPDATE_ORDER:
      return {
        ...state,
        updateOrderLoading: true,
        updateOrderError: ""
      };
    case UPDATE_ORDER_SUCCESS: {
      const { type, shouldUpdateOrderList } = action.payload;
      const postFix = type === "order" ? "orders" : "return_orders";
      if (shouldUpdateOrderList) {
        return {
          ...state,
          updateOrderLoading: false,
          updateOrderError: "",
          [`processing_${postFix}`]: null,
          [`processing_total_${postFix}`]: 0,
          [`confirmed_${postFix}`]: null,
          [`confirmed_total_${postFix}`]: 0,
          [`completed_${postFix}`]: null,
          [`completed_total_${postFix}`]: 0
        };
      }

      return { ...state, updateOrderLoading: false, updateOrderError: "" };
    }
    case UPDATE_ORDER_FAILURE:
      return {
        ...state,
        updateOrderLoading: false,
        updateOrderError: action.payload.error
      };

    case GET_SHIPPING_COST:
      return {
        ...state,
        fetchingShippingCostLoading: true
      };
    case GET_SHIPPING_COST_SUCCESS:
      return {
        ...state,
        shippingCosts: action.payload.shippingCosts,
        fetchingShippingCostLoading: false
      };
    case GET_SHIPPING_COST_FAILURE:
      return {
        ...state,
        fetchingShippingCostError: action.payload.error
      };

    case UPDATE_SHIPPING_COST:
      return {
        ...state,
        updateShippingCostLoading: true
      };
    case UPDATE_SHIPPING_COST_SUCCESS:
      return {
        ...state,
        updateShippingCostLoading: false
      };
    case UPDATE_SHIPPING_COST_FAILURE:
      return {
        ...state,
        updateShippingCostLoading: false,
        updateShippingCostError: action.payload.error
      };

    case GET_RETURN_COST:
      return {
        ...state,
        fetchingReturnCostLoading: true
      };
    case GET_RETURN_COST_SUCCESS:
      return {
        ...state,
        returnCosts: action.payload.returnCosts,
        fetchingReturnCostLoading: false
      };
    case GET_RETURN_COST_FAILURE:
      return {
        ...state,
        fetchingReturnCostError: action.payload.error
      };

    case UPDATE_RETURN_COST:
      return {
        ...state,
        updateReturnCostLoading: true
      };
    case UPDATE_RETURN_COST_SUCCESS:
      return {
        ...state,
        updateReturnCostLoading: false
      };
    case UPDATE_RETURN_COST_FAILURE:
      return {
        ...state,
        updateReturnCostLoading: false,
        updateReturnCostError: action.payload.error
      };

    case GET_PRODUCTS:
      return {
        ...state,
        fetchingProductsLoading: true
      };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload.products,
        allPriceVersionProducts: action.payload.allPriceVersionProducts,
        fetchingProductsLoading: false
      };
    case GET_PRODUCTS_FAILURE:
      return {
        ...state,
        fetchingProductsError: action.payload.error
      };

    case CREATE_PRODUCT_PRICES:
      return {
        ...state,
        createProductPricesLoading: true
      };
    case CREATE_PRODUCT_PRICES_SUCCESS:
      return {
        ...state,
        createProductPricesLoading: false
      };
    case CREATE_PRODUCT_PRICES_FAILURE:
      return {
        ...state,
        createProductPricesLoading: false,
        updateReturnCostError: action.payload.error
      };

    case DELETE_PRODUCT_ITEM:
      return {
        ...state,
        deletingProductItemLoading: true
      };
    case DELETE_PRODUCT_ITEM_SUCCESS:
      return {
        ...state,
        deletingProductItemLoading: false
      };
    case DELETE_PRODUCT_ITEM_FAILURE:
      return {
        ...state,
        deletingProductItemLoading: false,
        updateReturnCostError: action.payload.error
      };

    default:
      return state;
  }
};

export default authReducer;
