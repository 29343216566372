import React, { useState } from "react";
import Quagga from "quagga";
import ImageUploading from "react-images-uploading";
import ImageUpload from "assets/img/ImageUpload";
import axios from "../../config/axios";
import { useSnackbar } from "notistack";
import { isEmpty } from "lodash";

export const UploadImage = ({ setImageUrl, setIsImageUploading, setBarcodeID }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [image, setImage] = useState();

  const onChangeLogo = async imageList => {
    setImage(imageList);
    const body = {
      is_barcode_image: true,
      base64: imageList[0].data_url
    };
    setIsImageUploading(true);

    //SCAN IMAGE TO GET BARCODE
    Quagga.decodeSingle(
      {
        decoder: {
          readers: ["upc_reader", "upc_e_reader", "ean_reader", "ean_8_reader"] // List of active readers
        },
        numOfWorkers: 8,
        locate: true,
        src: imageList[0].data_url
      },
      function (result) {
        if (result?.codeResult?.code?.length >= 12) {
          setBarcodeID(result.codeResult.code);
        } else {
          enqueueSnackbar("Barcode is not detected.", { variant: "warning" });
        }
      }
    );

    await axios
      .post("/admin/image-upload", body)
      .then(({ data }) => {
        setImageUrl(data.data.url);
        setIsImageUploading(false);
      })
      .catch(error => {
        enqueueSnackbar(error.response.data.message, { variant: "warning" });
      });
  };

  return (
    <ImageUploading value={image} onChange={onChangeLogo} maxNumber={2} dataURLKey="data_url">
      {({ imageList, onImageUpload, isDragging, dragProps }) => (
        <div className="upload__image" style={{ maxWidth: "500px" }}>
          <div onClick={onImageUpload} {...dragProps}>
            <ImageUpload isDragging={isDragging} />
          </div>

          {!isEmpty(imageList) && (
            <div>
              {imageList.map((image, index) => (
                <div key={index} className="image-item">
                  <img src={image.data_url} alt="" width="100" />
                  <div className="image-item__btn-wrapper"></div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </ImageUploading>
  );
};
