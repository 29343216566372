import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../../config/axios";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button } from "@material-ui/core";
import { masterTableSlugs } from "assets/constants/constants";
import { useSnackbar } from "notistack";
import { isEmpty } from "lodash";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    maxWidth: 800
  }
});

const details = {
  [masterTableSlugs.TRIAL_PERIOD]: { title: "Trial Period days", type: "Days" },
  [masterTableSlugs.SHIPPING_COST]: { title: "Shipping cost discount", type: "Euro" },
  [masterTableSlugs.NEW_USER_REFERRAL_BONUS]: { title: "New user referral bonus", type: "Euro" },
  [masterTableSlugs.EXISTING_USER_REFERRAL_BONUS]: { title: "Existing user referral bonus", type: "Euro" },
  [masterTableSlugs.EXTRA_SHIPPING_COST_AFTER_KILOMETERS]: { title: "Extra shipping cost after kilometer", type: "Kilometer" },
  [masterTableSlugs.EXTRA_EURO_PER_KILOMETER]: { title: "Extra Euro per Kilometer", type: "Euro" }
};

export const MasterSetting = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [masterDetails, setMasterDetails] = useState({});

  const handleChange = (slug, value) => {
    setMasterDetails({ ...masterDetails, [slug]: Number(value) });
  };

  useEffect(() => {
    axios
      .get(`/admin/master-settings`)
      .then(res => {
        setMasterDetails(res.data.data);
      })
      .catch(error => {
        enqueueSnackbar(error, { variant: "error" });
      });
  }, [enqueueSnackbar]);

  const handleClickSave = () => {
    setIsLoading(true);
    axios
      .patch("/admin/master-settings", { masterDetails })
      .then(res => {
        setIsLoading(false);
        enqueueSnackbar("Details updated successfully", { variant: "success" });
      })
      .catch(error => {
        setIsLoading(false);
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  if (isEmpty(masterDetails)) return <></>;

  return (
    <div className="container">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type of value</TableCell>
              <TableCell align="right">Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(masterTableSlugs).map(masterSlug => (
              <TableRow key={masterSlug}>
                <TableCell>{details[masterSlug].title}</TableCell>
                <TableCell>{details[masterSlug].type}</TableCell>
                <TableCell align="right">
                  <TextField
                    id="outlined-number"
                    label=""
                    value={masterDetails[masterSlug]}
                    type="number"
                    onChange={event => handleChange(masterSlug, event.target.value)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      inputProps: { min: 0 }
                    }}
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: "flex", margin: "5px" }}>
        <Button style={{ width: "90px" }} className="d-flex" variant="contained" size="small" color="primary" onClick={handleClickSave}>
          {isLoading ? "Loading" : "Save"}
        </Button>
      </div>
    </div>
  );
};
