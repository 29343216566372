import {
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  UPDATE_SUBSCRIPTION_PRODUCTS,
  UPDATE_SUBSCRIPTION_PRODUCTS_SUCCESS,
  UPDATE_SUBSCRIPTION_PRODUCTS_FAILURE,
  UPDATE_SUBSCRIPTION_ITEMS,
  UPDATE_SUBSCRIPTION_ITEMS_SUCCESS,
  UPDATE_SUBSCRIPTION_ITEMS_FAILURE,
  GET_CUSTOMERS,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_FAILURE,
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAILURE,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAILURE
} from "../action/actionType";

let initialState = {
  //CUSTOMER
  customer: null,
  fetchingCustomerLoading: false,
  fetchingCustomerError: "",
  customerOrders: null,
  customerReturnOrders: null,

  updateCustomerLoading: false,
  updateCustomerError: "",

  deleteCustomerLoading: false,
  deleteCustomerError: "",

  //CUSTOMERS
  customers: null, //[]
  total_customers: 0,
  fetchingCustomersLoading: false,
  fetchingCustomersError: "",

  //SUBSCRIPTIONS
  subscriptions: null, //[]
  total_subscriptions: 0,
  fetchingSubscriptionsLoading: false,
  fetchingSubscriptionsError: "",
  error: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    //GET CUSTOMER
    case GET_CUSTOMER:
      return {
        ...state,
        customer: null,
        fetchingCustomerLoading: true
      };
    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: action.payload.customer,
        customerOrders: action.payload.orders,
        customerReturnOrders: action.payload.returnOrders,
        customerSubscriptions: action.payload.customerSubscriptions,
        fetchingCustomerLoading: false
      };
    case GET_CUSTOMER_FAILURE:
      return {
        ...state,
        fetchingCustomerLoading: true,
        fetchingCustomerError: action.payload.error
      };
    //UPDATE CUSTOEMR
    case UPDATE_CUSTOMER:
      return {
        ...state,
        updateCustomerLoading: true
      };
    case UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        updateCustomerLoading: false
      };
    case UPDATE_CUSTOMER_FAILURE:
      return {
        ...state,
        updateCustomerLoading: false,
        updateCustomerError: action.payload.error
      };

    //UPDATE SUBSCRIPTION PRODUCT
    case UPDATE_SUBSCRIPTION_PRODUCTS:
      return {
        ...state,
        updateSubscriptionProductLoading: true
      };
    case UPDATE_SUBSCRIPTION_PRODUCTS_SUCCESS:
      return {
        ...state,
        updateSubscriptionProductLoading: false
      };
    case UPDATE_SUBSCRIPTION_PRODUCTS_FAILURE:
      return {
        ...state,
        updateSubscriptionProductLoading: false
      };

    //UPDATE SUBSCRIPTION ITEMS
    case UPDATE_SUBSCRIPTION_ITEMS:
      return {
        ...state,
        updateSubscriptionItemsLoading: true
      };
    case UPDATE_SUBSCRIPTION_ITEMS_SUCCESS:
      return {
        ...state,
        updateSubscriptionItemsLoading: false
      };
    case UPDATE_SUBSCRIPTION_ITEMS_FAILURE:
      return {
        ...state,
        updateSubscriptionItemsLoading: false
      };

    //DELETE CUSTOEMR
    case DELETE_CUSTOMER:
      return {
        ...state,
        deleteCustomerLoading: true
      };
    case DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: null, //[]
        total_customers: 0,
        subscriptions: null,
        total_subscriptions: 0,
        deleteCustomerLoading: false
      };
    case DELETE_CUSTOMER_FAILURE:
      return {
        ...state,
        deleteCustomerLoading: false,
        deleteCustomerError: action.payload.error
      };
    //GET_CUSTOMERS
    case GET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload.rowsPerPageChanged ? null : state.customers,
        fetchingCustomersLoading: true
      };
    case GET_CUSTOMERS_SUCCESS:
      let customers = state.customers;
      let new_customers;
      if (customers === null) {
        new_customers = action.payload.customers;
      } else {
        new_customers = [...customers, ...action.payload.customers];
      }
      return {
        ...state,
        fetchingCustomersLoading: false,
        customers: new_customers,
        total_customers: action.payload.total
      };

    case GET_CUSTOMERS_FAILURE:
      return {
        ...state,
        fetchingCustomersLoading: false,
        fetchingCustomersError: action.payload.error
      };

    //GET_SUBSCRIPTIONS
    case GET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload.rowsPerPageChanged ? null : state.subscriptions,
        fetchingSubscriptionsLoading: true
      };
    case GET_SUBSCRIPTIONS_SUCCESS:
      let subscriptions = state.subscriptions;
      let new_subscriptions;
      if (subscriptions === null) {
        new_subscriptions = action.payload.subscriptions;
      } else {
        new_subscriptions = [...subscriptions, ...action.payload.subscriptions];
      }
      return {
        ...state,
        fetchingSubscriptionsLoading: false,
        subscriptions: new_subscriptions,
        total_subscriptions: action.payload.total
      };

    case GET_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        fetchingSubscriptionsLoading: false,
        fetchingSubscriptionsError: action.payload.error
      };

    default:
      return state;
  }
};

export default authReducer;
