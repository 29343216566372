import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import axios from "../../../config/axios";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, TextField, Checkbox } from "@material-ui/core";
import { useSnackbar } from "notistack";

export const CarrierAddOrEdit = ({ isOpen, handleClose, editCarrier }) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleCarrierSubmit = async event => {
    //Preventing page refresh
    event.preventDefault();

    setLoading(true);
    const body = {
      // password: event.target.password.value,
      latitude: event.target.latitude.value,
      longitude: event.target.longitude.value,
      name: event.target.name.value,
      area_name: event.target.area_name.value,
      is_active: event.target.is_active.checked
    };
    if (editCarrier) {
      if (event.target.email.value !== editCarrier.email) {
        body.email = event.target.email.value;
      }

      axios
        .patch(`/admin/update-carrier/${editCarrier._id}`, body)
        .then(res => {
          setLoading(false);
          handleClose();
          enqueueSnackbar("Carrier updated successfully", { variant: "success" });
        })
        .catch(error => {
          enqueueSnackbar(error?.message, { variant: "error" });
          setLoading(false);
        });
    } else {
      body.email = event.target.email.value;
      body.password = event.target.password.value;

      axios
        .post(`/admin/create-carrier`, body)
        .then(res => {
          setLoading(false);
          handleClose();
          enqueueSnackbar("Carrier created successfully", { variant: "success" });
        })
        .catch(error => {
          enqueueSnackbar(error?.message, { variant: "error" });
          setLoading(false);
        });
    }
  };
  return (
    <Dialog fullWidth={true} maxWidth={"sm"} open={isOpen} onClose={handleClose} aria-labelledby="max-width-dialog-title">
      <DialogTitle id="max-width-dialog-title">{editCarrier ? "Edit" : "Add"} Carrier</DialogTitle>
      <div className="carrier-popup">
        <form onSubmit={handleCarrierSubmit}>
          <div className="form-field">
            <div>Name</div>
            <div>
              <TextField id="outlined-basic" name="name" label="Name" variant="outlined" defaultValue={editCarrier?.name} required />
            </div>
          </div>
          <div className="form-field">
            <div>Email</div>
            <TextField id="outlined-basic" name="email" type="email" label="Email" variant="outlined" defaultValue={editCarrier?.email} required />
          </div>
          {!editCarrier && (
            <div className="form-field">
              <div>Password</div>
              <TextField id="outlined-basic" name="password" inputProps={{ minLength: 8 }} label="Password" defaultValue={editCarrier?.password} variant="outlined" required />
            </div>
          )}
          <div className="form-field">
            <div>Area name</div>
            <TextField id="outlined-basic" name="area_name" label="Area name" variant="outlined" defaultValue={editCarrier?.area_name} required />
          </div>
          <div className="form-field">
            <div>Latitude</div>
            <TextField id="outlined-basic" name="latitude" type="String" label="Latitude" variant="outlined" defaultValue={editCarrier?.latitude} required />
          </div>
          <div className="form-field">
            <div>Longitude</div>
            <TextField id="outlined-basic" name="longitude" type="String" label="Longitude" variant="outlined" defaultValue={editCarrier?.longitude} required />
          </div>
          <div className="form-field">
            <div>Is Active</div>
            <Checkbox name="is_active" defaultChecked={editCarrier?.is_active} />
          </div>

          <div className="save-button">
            <Button type="submit" fullWidth variant="contained" color="primary" disabled={loading}>
              {loading ? "Loading" : "Save"}
            </Button>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};
