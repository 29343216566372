import {
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAILURE,
  GET_CUSTOMERS,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_FAILURE,
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAILURE,
  UPDATE_SUBSCRIPTION_PRODUCTS,
  UPDATE_SUBSCRIPTION_PRODUCTS_SUCCESS,
  UPDATE_SUBSCRIPTION_PRODUCTS_FAILURE,
  UPDATE_SUBSCRIPTION_ITEMS,
  UPDATE_SUBSCRIPTION_ITEMS_SUCCESS,
  UPDATE_SUBSCRIPTION_ITEMS_FAILURE
} from "./actionType";
import axios from "../../config/axios";

//FETCH CUSTOMER
const fetchCustomer = payload => {
  return {
    type: GET_CUSTOMER,
    payload: payload
  };
};
const fetchCustomerSuccess = payload => {
  return {
    type: GET_CUSTOMER_SUCCESS,
    payload: payload
  };
};
const fetchCustomerFailure = payload => {
  return {
    type: GET_CUSTOMER_FAILURE,
    payload: payload
  };
};
export const getCustomer = ({ id }) => async dispatch => {
  dispatch(fetchCustomer());
  axios
    .get(`/admin/customer/${id}`)
    .then(res => {
      dispatch(
        fetchCustomerSuccess({
          customer: res.data.data.customer,
          orders: res.data.data.orders.orders,
          returnOrders: res.data.data.returnOrders.returnOrders,
          customerSubscriptions: res.data.data.subscriptions
        })
      );
    })
    .catch(err => {
      dispatch(fetchCustomerFailure({ error: err.response.data.message }));
    });
};

//UPDATE CUSTOMER

const updateCustomer1 = payload => {
  return {
    type: UPDATE_CUSTOMER,
    payload: payload
  };
};
const updateCustomerSuccess = payload => {
  return {
    type: UPDATE_CUSTOMER_SUCCESS,
    payload: payload
  };
};
const updateCustomerFailure = payload => {
  return {
    type: UPDATE_CUSTOMER_FAILURE,
    payload: payload
  };
};
export const updateCustomer = ({ newCustomerData, id }, handleupdateCustomerSuccess, handleUpdateCustomerFailure) => async dispatch => {
  dispatch(updateCustomer1());

  axios
    .patch(`/admin/update-customer/${id}`, newCustomerData)
    .then(res => {
      handleupdateCustomerSuccess();
      dispatch(updateCustomerSuccess({}));
    })
    .catch(err => {
      handleUpdateCustomerFailure({ error: err.response.data.message });
      dispatch(updateCustomerFailure({ error: err.response.data.message }));
    });
};

//UPDATE SUBSCRIPTION ITEMS
const changeSubscriptionItems = payload => {
  return {
    type: UPDATE_SUBSCRIPTION_ITEMS,
    payload: payload
  };
};
const changeSubscriptionItemsSuccess = payload => {
  return {
    type: UPDATE_SUBSCRIPTION_ITEMS_SUCCESS,
    payload: payload
  };
};
const changeSubscriptionItemsFailure = payload => {
  return {
    type: UPDATE_SUBSCRIPTION_ITEMS_FAILURE,
    payload: payload
  };
};
export const updateSubscriptionItems = ({ items, subscriptionId }, handleupdateCustomerSuccess, handleUpdateCustomerFailure) => async dispatch => {
  dispatch(changeSubscriptionItems());
  axios
    .patch(`/admin/update-subscription-items/${subscriptionId}`, { items })
    .then(res => {
      dispatch(changeSubscriptionItemsSuccess());
      handleupdateCustomerSuccess({});
    })
    .catch(err => {
      handleUpdateCustomerFailure({ error: err.response.data.message });
      dispatch(changeSubscriptionItemsFailure({ error: err.response.data.message }));
    });
};

//UPDATE SUBSCRIPTION PRODUCT
const changeSubscriptionProduct = payload => {
  return {
    type: UPDATE_SUBSCRIPTION_PRODUCTS,
    payload: payload
  };
};
const changeSubscriptionProductSuccess = payload => {
  return {
    type: UPDATE_SUBSCRIPTION_PRODUCTS_SUCCESS,
    payload: payload
  };
};
const changeSubscriptionProductFailure = payload => {
  return {
    type: UPDATE_SUBSCRIPTION_PRODUCTS_FAILURE,
    payload: payload
  };
};
export const updateSubscriptionProduct = ({ userId, currentProductId, selectedProductType }, handleupdateCustomerSuccess, handleUpdateCustomerFailure) => async dispatch => {
  dispatch(changeSubscriptionProduct());
  axios
    .patch(`/admin/update-subscription-product`, { user_id: userId, current_product_id: currentProductId, new_product_type: selectedProductType })
    .then(res => {
      dispatch(changeSubscriptionProductSuccess());
      handleupdateCustomerSuccess({});
    })
    .catch(err => {
      handleUpdateCustomerFailure({ error: err.response.data.message });
      dispatch(changeSubscriptionProductFailure({ error: err.response.data.message }));
    });
};

//UPDATE CUSTOMER

const removeCustomer = payload => {
  return {
    type: DELETE_CUSTOMER,
    payload: payload
  };
};
const removeCustomerSuccess = payload => {
  return {
    type: DELETE_CUSTOMER_SUCCESS,
    payload: payload
  };
};
const removeCustomerFailure = payload => {
  return {
    type: DELETE_CUSTOMER_FAILURE,
    payload: payload
  };
};
export const deleteCustomer = ({ id }, handleDeleteCustomerSuccess, handleDeleteCustomerFailure) => async dispatch => {
  dispatch(removeCustomer());
  axios
    .delete(`/admin/remove-user/${id}`)
    .then(res => {
      handleDeleteCustomerSuccess();
      dispatch(removeCustomerSuccess({}));
    })
    .catch(err => {
      handleDeleteCustomerFailure({ error: err.response.data.message });
      dispatch(removeCustomerFailure({ error: err.response.data.message }));
    });
};

//FETCH CUSTOMERS
const fetchCustomers = payload => {
  return {
    type: GET_CUSTOMERS,
    payload: payload
  };
};
const fetchCustomersSuccess = payload => {
  return {
    type: GET_CUSTOMERS_SUCCESS,
    payload: payload
  };
};
const fetchCustomersFailure = payload => {
  return {
    type: GET_CUSTOMERS_FAILURE,
    payload: payload
  };
};

export const getCustomers = ({ rowsPerPageChanged = false }) => async dispatch => {
  dispatch(fetchCustomers({ rowsPerPageChanged }));
  axios
    .get(`/admin/customers`)
    .then(res => {
      dispatch(fetchCustomersSuccess({ customers: res.data.data.users, total: res.data.data.total }));
    })
    .catch(err => {
      dispatch(fetchCustomersFailure({ error: err.response.data.message }));
    });
};

//FETCH SUBSCRIPTIONS
const fetchSubsciptions = payload => {
  return {
    type: GET_SUBSCRIPTIONS,
    payload: payload
  };
};
const fetchSubsciptionsSuccess = payload => {
  return {
    type: GET_SUBSCRIPTIONS_SUCCESS,
    payload: payload
  };
};
const fetchSubsciptionsFailure = payload => {
  return {
    type: GET_SUBSCRIPTIONS_FAILURE,
    payload: payload
  };
};

export const getSubscriptions = ({ rowsPerPageChanged = false }) => async dispatch => {
  dispatch(fetchSubsciptions({ rowsPerPageChanged }));
  axios
    .get(`/admin/subscriptions`) //?page=${page}&limit=${limit}
    .then(res => {
      dispatch(fetchSubsciptionsSuccess({ subscriptions: res.data.data.subscriptions, total: res.data.data.total })); //res.data.data.total
    })
    .catch(err => {
      dispatch(fetchSubsciptionsFailure({ error: err.response.data.message }));
    });
};
