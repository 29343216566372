import React, { useEffect, useState } from "react";

//NPM PACKAGES
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Cards from "react-credit-cards";
import { useSnackbar } from "notistack";
import moment from "moment";
import { isBoolean, reduce, round, split } from "lodash";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

//MATERIAL UI COMPONENTS
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import { Add } from "@material-ui/icons";

//core components
import { getCustomer, updateCustomer } from "redux/action/customer.action";
import ProductLists from "components/OrderDetails/ProductLists";
import ItemLists from "./ItemLists";
import CustomerDelete from "./CustomerDeletePopup";
import { stripeDashboardURL } from "utils";
import { getProducts } from "redux/action/order.action";
import AddProduct from "./AddProduct";
import { getCurrentSubscriptionURL } from "components/Subscription/HelperFunction";
import { getCurrentSubscriptionId } from "components/Subscription/HelperFunction";
import SubscriptionStatusChip from "components/Subscription/SubscriptionStatusChip";
import axios from "../../config/axios";
import { FormInputText } from "components/MuiInputComponents/FormInputText";
import { customerSchema } from "./schemas/CustomerDetails.schema";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const CustomerDetails = () => {
  //CONSTANTS

  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  let { id } = params;
  const CUSTOMER_STATE = useSelector(state => state.customer);
  const ORDER_STATE = useSelector(state => state.order);

  const { enqueueSnackbar } = useSnackbar();
  const [showComponent, setShowComponent] = React.useState(false);
  const [isDeleteShow, setIsDeleteShow] = React.useState(false);
  const [isOpenAddProductDialog, setIsOpenAddProductDialog] = React.useState(false);
  const [updatedCustomerDefaultValues, setUpdatedCustomerDefaultValues] = useState(false);
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({ resolver: yupResolver(customerSchema) });

  //FUNCTIONS
  useEffect(() => {
    dispatch(getCustomer({ id }));
    setShowComponent(true);

    if (!ORDER_STATE.products) {
      dispatch(getProducts());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  useEffect(() => {
    if (showComponent && CUSTOMER_STATE.customer && !updatedCustomerDefaultValues) {
      reset({
        name: CUSTOMER_STATE?.customer?.name,
        phone: CUSTOMER_STATE?.customer?.phone,
        nif: CUSTOMER_STATE?.customer?.nif,
        address: CUSTOMER_STATE?.customer?.location?.address,
        floor: CUSTOMER_STATE?.customer?.location?.floor,
        city: CUSTOMER_STATE?.customer.location?.city,
        zipcode: CUSTOMER_STATE?.customer?.location?.zipcode,
        notes: CUSTOMER_STATE?.customer?.notes
      });
      setUpdatedCustomerDefaultValues(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CUSTOMER_STATE]);
  const handleupdateCustomerSuccess = () => {
    enqueueSnackbar("Order Updated Successfully", { variant: "success" });
  };
  const handleUpdateCustomerFailure = ({ error }) => {
    enqueueSnackbar(error, { variant: "error" });
  };
  const onSubmit = data => {
    const updatedData = {
      newCustomerData: {
        name: data.name,
        phone: data?.phone || "",
        nif: data?.nif || "",
        location: {
          address: data.address,
          floor: data.floor,
          city: data.city,
          zipcode: data.zipcode
        },
        notes: data?.notes || ""
      },
      id: CUSTOMER_STATE.customer._id
    };
    dispatch(updateCustomer(updatedData, handleupdateCustomerSuccess, handleUpdateCustomerFailure));
  };
  const openOrder = orderId => {
    history.push(`/admin/orders/${orderId}`);
  };
  const openReturnOrder = returnOrderId => {
    history.push(`/admin/return-orders/${returnOrderId}`);
  };

  const HandleDelete = event => {
    setIsDeleteShow(true);
  };

  const handleUrl = () => {
    if (window.location.href.includes("admin-dev")) {
      return "https://app-dev.keepwhat.com/login-support/";
    }
    if (window.location.href.includes("localhost")) {
      return "http://localhost:3000/login-support/";
    }
    return "https://app.keepwhat.com/login-support/";
  };

  const resendActivationMail = () => {
    axios
      .patch(`/send-acc-activation-mail`, { email: CUSTOMER_STATE.customer.email })
      .then(res => {
        enqueueSnackbar(res.data.message, { variant: "success" });
      })
      .catch(error => {
        enqueueSnackbar(error?.message, { variant: "error" });
      });
  };

  const resetPassword = () => {
    axios
      .post(`/recover-password`, { email: CUSTOMER_STATE.customer.email })
      .then(res => {
        enqueueSnackbar("Reset password mail sent successfully", { variant: "success" });
      })
      .catch(error => {
        enqueueSnackbar(error?.message, { variant: "error" });
      });
  };

  return (
    <div>
      {isOpenAddProductDialog && (
        <AddProduct
          setIsOpenAddProductDialog={setIsOpenAddProductDialog}
          allowOrderSelection={true}
          priceVersion={CUSTOMER_STATE?.customer?.price_version}
          userId={CUSTOMER_STATE?.customer?._id}
        />
      )}
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <h3 style={{ fontWeight: "600" }}>Customer Details</h3>
        {!CUSTOMER_STATE.fetchingCustomerLoading && (
          <Button variant="contained" color="primary" onClick={() => window.open(`${handleUrl()}${CUSTOMER_STATE?.customer?.email}`, "_blank")}>
            {`login as ${CUSTOMER_STATE?.customer?.name}`}
          </Button>
        )}
      </div>

      {CUSTOMER_STATE.fetchingCustomerLoading ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
        <div>
          {showComponent && CUSTOMER_STATE.customer && (
            <div>
              <div className="details">
                <div className="title">User #{CUSTOMER_STATE.customer._id}</div>
                <div className="card">
                  <div className="col">
                    <div className="row">
                      <div className="label">Stripe customer ID</div>
                      {CUSTOMER_STATE.customer.stripe_payment_method_id ? (
                        <a href={`${stripeDashboardURL}/customers/${CUSTOMER_STATE.customer.stripe_payment_method_id.customer}`} target="_blank" rel="noopener noreferrer">
                          <div style={{ fontWeight: "400" }}>{CUSTOMER_STATE.customer.stripe_payment_method_id.customer}</div>
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </div>
                    <div className="row">
                      <div className="label">Email</div>
                      <div>{CUSTOMER_STATE.customer.email}</div>
                    </div>
                    <div className="row">
                      <div className="label">Name</div>
                      <FormInputText name="name" control={control} />
                    </div>
                    <div className="row">
                      <div className="label">Phone number</div>
                      <FormInputText name="phone" control={control} helperText={errors?.phone?.message} isError={errors?.phone?.message} />
                    </div>
                    <div className="row">
                      <div className="label">NIF number</div>
                      <FormInputText name="nif" control={control} helperText={errors?.nif?.message} isError={errors?.nif?.message} />
                    </div>

                    <div style={{ width: "100%" }}>
                      <div className="row">
                        <div className="label">Address</div>
                        <FormInputText name="address" control={control} />
                      </div>
                      <div className="row">
                        <div className="label">floor</div>
                        <FormInputText name="floor" control={control} />
                      </div>
                      <div className="row">
                        <div className="label">City</div>
                        <FormInputText name="city" control={control} />
                      </div>
                      <div className="row">
                        <div className="label">Zipcode</div>
                        <FormInputText name="zipcode" label="0000-000" control={control} helperText={errors?.zipcode?.message} isError={errors?.zipcode?.message} />
                      </div>
                    </div>

                    <div className="row">
                      <div className="label">Notes</div>
                      <FormInputText name="notes" control={control} inputProps={{ multiline: true, rows: 4 }} />
                    </div>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="label">Created At</div>
                      <div>{moment(CUSTOMER_STATE.customer.created_at).format("YYYY-MM-DD")}</div>
                    </div>
                    <div className="row">
                      <div className="label">Price Version</div>
                      <div>{CUSTOMER_STATE.customer.price_version}</div>
                    </div>

                    <div className="row">
                      <div style={{ display: "flex", justifyContent: "flex-start" }}>
                        {CUSTOMER_STATE.customer?.stripe_payment_method_id?.card && (
                          <>
                            {(function () {
                              const creditCard = CUSTOMER_STATE.customer?.stripe_payment_method_id?.card;
                              const expMonth = ("0" + creditCard.exp_month).slice(-2);
                              const expYear = moment(creditCard.exp_year, "YYYY").format("YY");
                              return (
                                <Cards
                                  expiry={`${expMonth}${expYear}`}
                                  number={`**** **** **** ${creditCard.last4}`}
                                  cvc="***"
                                  name={creditCard.cardHolderName ? creditCard.cardHolderName : ""}
                                  preview
                                  issuer={creditCard.brand}
                                />
                              );
                            })()}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      {CUSTOMER_STATE.customer.is_verified ? (
                        <div>
                          <Button variant="outlined" color="primary" onClick={() => resetPassword()}>
                            Send Reset Password Mail
                          </Button>
                        </div>
                      ) : (
                        <Button variant="outlined" color="primary" onClick={() => resendActivationMail()}>
                          Resend Activation Mail
                        </Button>
                      )}
                    </div>
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", padding: "0px 30px 30px 30px" }}>
                  {CUSTOMER_STATE.updateCustomerLoading ? (
                    <Button variant="contained" color="primary" disabled={true}>
                      Loading
                    </Button>
                  ) : (
                    <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                      Save
                    </Button>
                  )}
                  {isDeleteShow ? (
                    <div>
                      <CustomerDelete setIsDeleteShow={setIsDeleteShow} isDeleteShow={isDeleteShow} id={CUSTOMER_STATE.customer._id} />
                    </div>
                  ) : (
                    <Button variant="contained" color="warning" onClick={e => HandleDelete(e)} style={{ backgroundColor: "red", color: "white" }}>
                      Delete
                    </Button>
                  )}
                </div>
              </div>

              {/* */}
              <div className="details">
                <div className="title">Stripe Subscription Details</div>
                {CUSTOMER_STATE.customer.stripe_subscription ? (
                  <div className="col">
                    <div className="row">
                      <div className="label">ID</div>
                      <a href={`${stripeDashboardURL}/subscriptions/${CUSTOMER_STATE.customer.stripe_subscription.id}`} target="_blank" rel="noopener noreferrer">
                        <div style={{ fontWeight: "400" }}>{CUSTOMER_STATE.customer.stripe_subscription.id}</div>
                      </a>
                    </div>
                    <div className="row">
                      <div className="label">Products</div>
                      <div>{CUSTOMER_STATE.customer.total_products}</div>
                    </div>
                    <div className="row">
                      <div className="label">Status</div>
                      <SubscriptionStatusChip stripe_subscription_status={CUSTOMER_STATE?.customer?.stripe_subscription_status} />
                    </div>
                    <div className="row">
                      <div className="label">Latest Invoice</div>
                      <div>
                        <div>
                          <div className="row">
                            <div className="label">ID</div>
                            <a href={`${stripeDashboardURL}/invoices/${CUSTOMER_STATE.customer.stripe_subscription.latest_invoice.id}`} target="_blank" rel="noopener noreferrer">
                              <div style={{ fontWeight: "400" }}>{CUSTOMER_STATE.customer.stripe_subscription.latest_invoice.id}</div>
                            </a>
                          </div>
                          <div className="row">
                            <div className="label">Total</div>
                            <div>
                              {CUSTOMER_STATE.customer.stripe_subscription.latest_invoice.total / 100}{" "}
                              {CUSTOMER_STATE.customer.stripe_subscription.latest_invoice.currency === "eur"
                                ? "€"
                                : CUSTOMER_STATE.customer.stripe_subscription.latest_invoice.currency}
                            </div>
                          </div>
                          <div className="row">
                            <div className="label">Status</div>
                            <div>{CUSTOMER_STATE.customer.stripe_subscription.latest_invoice.status}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="label">Current Period Start</div>
                      <div>{moment.unix(CUSTOMER_STATE.customer.stripe_subscription.current_period_start).format("LLLL")}</div>
                    </div>
                    <div className="row">
                      <div className="label">Current Period End</div>
                      <div>{moment.unix(CUSTOMER_STATE.customer.stripe_subscription.current_period_end).format("LLLL")}</div>
                    </div>
                  </div>
                ) : (
                  <div style={{ fontSize: "larger", padding: "30px" }}> There is no active subscription </div>
                )}

                {CUSTOMER_STATE?.customer?.subscription_products?.length > 0 && (
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid lightgrey", marginRight: "15px" }}>
                    <div className="title">Products</div>
                    <Button startIcon={<Add />} color="primary" variant="contained" onClick={() => setIsOpenAddProductDialog(true)}>
                      Add new product
                    </Button>
                  </div>
                )}

                {CUSTOMER_STATE.customer.subscription_products.length > 0 && (
                  <div className="table">
                    <TableContainer component={Paper}>
                      <Table aria-label="customized table" size="small">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell />
                            <StyledTableCell>ID</StyledTableCell>
                            <StyledTableCell align="center">Order id</StyledTableCell>
                            <StyledTableCell align="center">Product Name</StyledTableCell>
                            <StyledTableCell align="center">Content Items</StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {CUSTOMER_STATE.customer.subscription_products &&
                            CUSTOMER_STATE.customer.subscription_products.map(row => (
                              <ProductLists key={row._id} userPriceVersion={CUSTOMER_STATE.customer.price_version} row={row} fromScreen={"customer"} allowEditProduct={true} />
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}

                {CUSTOMER_STATE.customer?.subscription_items?.length > 0 && <ItemLists />}
              </div>

              {CUSTOMER_STATE?.customerSubscriptions && (
                <div className="details">
                  <div className="title">Subscriptions</div>
                  <div className="table">
                    <TableContainer component={Paper}>
                      <Table aria-label="customized table" size="small">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">Subscription ID</StyledTableCell>
                            <StyledTableCell align="left">Created Date</StyledTableCell>
                            <StyledTableCell align="left">Started Date</StyledTableCell>
                            <StyledTableCell align="left">Status</StyledTableCell>
                            <StyledTableCell align="left">Amount</StyledTableCell>
                            <StyledTableCell align="left">Products</StyledTableCell>
                            <StyledTableCell align="left">Next Payment</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {CUSTOMER_STATE.customerSubscriptions.map(subscription => {
                            return (
                              <StyledTableRow key={subscription._id}>
                                <StyledTableCell align="left">
                                  <a href={getCurrentSubscriptionURL(subscription)} target="_blank" rel="noopener noreferrer">
                                    <div style={{ fontWeight: "400", color: "blue" }}>{getCurrentSubscriptionId(subscription)}</div>
                                  </a>
                                </StyledTableCell>
                                <StyledTableCell align="left">{moment(subscription.created * 1000).format("YYYY-MM-DD")}</StyledTableCell>
                                <StyledTableCell align="left">
                                  {subscription?.current_period_start ? moment(subscription?.current_period_start * 1000).format("YYYY-MM-DD") : "NA"}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  <SubscriptionStatusChip
                                    stripe_subscription_schedule_id={subscription?.stripe_subscription_schedule_id}
                                    stripe_subscription_schedule_status={subscription?.stripe_subscription_schedule_status}
                                    stripe_subscription_status={subscription?.stripe_subscription_status}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="left">{subscription?.amount ? `${subscription?.amount?.value} €` : "NA"}</StyledTableCell>
                                <StyledTableCell align="left">{subscription?.products}</StyledTableCell>
                                <StyledTableCell align="left">
                                  {subscription?.current_period_end ? moment(subscription?.current_period_end * 1000).format("YYYY-MM-DD") : "NA"}
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              )}

              <div className="details">
                <div className="title">Pickup Orders</div>
                <div className="table">
                  {CUSTOMER_STATE.customerOrders.length > 0 ? (
                    <TableContainer component={Paper}>
                      <Table aria-label="customized table" size="small">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell>ID</StyledTableCell>
                            <StyledTableCell align="left">Creation Date</StyledTableCell>
                            <StyledTableCell align="left">First Pickup</StyledTableCell>
                            <StyledTableCell align="left">Pickup Date</StyledTableCell>
                            <StyledTableCell align="left">Status</StyledTableCell>
                            <StyledTableCell align="left">Payment Status</StyledTableCell>
                            <StyledTableCell align="left">Subscribed Price</StyledTableCell>
                            <StyledTableCell align="left">Shipping Price</StyledTableCell>
                            <StyledTableCell align="left">Products</StyledTableCell>

                            <StyledTableCell align="left">Items</StyledTableCell>
                            <StyledTableCell align="left">City</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {CUSTOMER_STATE.customerOrders.map(order => (
                            <StyledTableRow key={order._id}>
                              <StyledTableCell>
                                <IconButton m={0.5} aria-label="primary" onClick={() => openOrder(order._id)}>
                                  <VisibilityIcon style={{ paddingRight: "10px" }} />
                                </IconButton>
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                {order._id}
                              </StyledTableCell>
                              <StyledTableCell align="left">{moment(order.created_at).format("YYYY-MM-DD")}</StyledTableCell>
                              <StyledTableCell align="left">{isBoolean(order?.is_first_order) ? (order?.is_first_order ? "No" : "Yes") : "-"}</StyledTableCell>
                              <StyledTableCell align="left">{moment(split(order?.pickup_date, "::")[0]).format("YYYY-MM-DD")}</StyledTableCell>
                              <StyledTableCell align="left">{order.status}</StyledTableCell>
                              <StyledTableCell align="left">{order?.stripe_shipping_details?.p_intent_status}</StyledTableCell>
                              <StyledTableCell align="left">{round(order?.subscribed_price, 2)} €</StyledTableCell>
                              <StyledTableCell align="left">{round(order.shipping_cost, 2)} €</StyledTableCell>
                              <StyledTableCell align="left">{order?.products?.length}</StyledTableCell>
                              <StyledTableCell align="left">{reduce(order?.products, (total, product) => total + product?.items?.length, 0)}</StyledTableCell>
                              <StyledTableCell align="left">{order.city}</StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <div style={{ fontSize: "larger" }}> There is no any Order </div>
                  )}
                </div>
              </div>
              <div className="details">
                <div className="title">Return Orders</div>
                <div className="table">
                  {CUSTOMER_STATE.customerReturnOrders.length > 0 ? (
                    <TableContainer component={Paper}>
                      <Table aria-label="customized table" size="small">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell>ID</StyledTableCell>
                            <StyledTableCell align="left">Creation Date</StyledTableCell>
                            <StyledTableCell align="left">Type</StyledTableCell>
                            <StyledTableCell align="left">Return Date</StyledTableCell>
                            <StyledTableCell align="left">Status</StyledTableCell>
                            <StyledTableCell align="left">Payment Status</StyledTableCell>
                            <StyledTableCell align="left">Shipping Price</StyledTableCell>
                            <StyledTableCell align="left">items</StyledTableCell>
                            <StyledTableCell align="left">City</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {CUSTOMER_STATE.customerReturnOrders.map(order => (
                            <StyledTableRow key={order._id}>
                              <StyledTableCell>
                                <IconButton m={0.5} aria-label="primary" onClick={() => openReturnOrder(order._id)}>
                                  <VisibilityIcon style={{ paddingRight: "10px" }} />
                                </IconButton>
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                {order._id}
                              </StyledTableCell>
                              <StyledTableCell align="left">{moment(order.created_at).format("YYYY-MM-DD")}</StyledTableCell>
                              <StyledTableCell align="left">{isBoolean(order?.partial) ? (order.partial ? "Partial" : "Total") : "NA"}</StyledTableCell>

                              <StyledTableCell align="left">{moment(split(order.return_date, "::")[0]).format("YYYY-MM-DD")}</StyledTableCell>
                              <StyledTableCell align="left">{order.status}</StyledTableCell>
                              <StyledTableCell align="left">{order?.stripe_shipping_details?.p_intent_status}</StyledTableCell>
                              <StyledTableCell align="left">
                                {order.shipping_cost}
                                {" €"}
                              </StyledTableCell>
                              <StyledTableCell align="left">{order.items_count}</StyledTableCell>
                              <StyledTableCell align="left">{order.city}</StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <div style={{ fontSize: "larger" }}> There is no any order </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomerDetails;
