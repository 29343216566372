import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import NoData from "assets/img/NoData.svg";
import { loadServerRows } from "./filterFunction";
import { isString } from "lodash";
import { localStorageKeys } from "assets/constants/constants";
const useStyles = makeStyles(theme => ({
  table: {
    height: 750
  },
  pageSizeTable: {
    position: "absolute",
    height: "90%",
    minWidth: "97.5%",
    background: "white"
  },
  noRows: {
    display: "flex",
    justifyContent: "center",
    marginTop: "210px"
  },
  noRowsText: {
    display: "flex",
    justifyContent: "center",
    marginTop: "0px"
  }
}));

export default function MaterialDataGridTable({ rows, columns, data, setRows, handleDataAsColumn, tableName }) {
  const classes = useStyles();
  //VARIABLES
  const [pageSize, setPageSize] = React.useState(Number(localStorage.getItem(localStorageKeys["ROWS_PER_PAGE"])) || 10);
  const [filterValue, setFilterValue] = React.useState();
  const [filterKey, setFilterKey] = React.useState();
  const [operatorValue, setOperatorValue] = React.useState();

  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    let active = true;

    if (filterValue) {
      (async () => {
        setLoading(true);
        const newRows = await loadServerRows(data, filterKey, filterValue, operatorValue);

        if (!active) {
          return;
        }
        let filteredOrderers = await handleDataAsColumn(newRows);
        setRows(filteredOrderers);
        setLoading(false);
      })();
      return () => {
        active = false;
      };
    } else {
      (async () => {
        let withoutFilteredOrders = await handleDataAsColumn(data);
        setRows(withoutFilteredOrders);
        setLoading(false);
      })();
    }
  }, [filterValue, operatorValue, filterKey]); // eslint-disable-line

  const onFilterChange = React.useCallback(
    filterModel => {
      const filterAttributes = {
        filterKey: filterModel?.items?.[0]?.columnField,
        filterValue: isString(filterModel?.items?.[0]?.value) ? filterModel?.items?.[0]?.value.toLowerCase() : filterModel?.items?.[0]?.value,
        operatorValue: filterModel?.items[0]?.operatorValue
      };

      if (filterModel?.items?.length > 0) {
        setFilterKey(filterAttributes.filterKey);
        setFilterValue(filterAttributes.filterValue);
        setOperatorValue(filterAttributes.operatorValue);

        localStorage.setItem(localStorageKeys[`${tableName}_FILTER`], JSON.stringify(filterAttributes));
      } else {
        setFilterKey();
        setFilterValue();
        setOperatorValue();

        localStorage.removeItem(localStorageKeys[`${tableName}_FILTER`]);
      }
    },
    [tableName]
  );

  useEffect(() => {
    let filterAttributes = localStorage.getItem(localStorageKeys[`${tableName}_FILTER`]);

    if (filterAttributes) {
      filterAttributes = JSON.parse(filterAttributes);
      setFilterKey(filterAttributes.filterKey);
      setFilterValue(isString(filterAttributes.filterValue) ? filterAttributes.filterValue.toLowerCase() : filterAttributes.filterValue);
      setOperatorValue(filterAttributes.operatorValue);
    }
  }, [tableName]);

  const onChangePageSize = newPageSize => {
    setPageSize(newPageSize);
    localStorage.setItem(localStorageKeys["ROWS_PER_PAGE"], newPageSize);
  };

  return (
    <React.Fragment>
      <div className={pageSize > 10 ? classes.pageSizeTable : classes.table}>
        <DataGrid
          rowHeight={60}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={newPageSize => onChangePageSize(newPageSize)}
          rowsPerPageOptions={[10, 25, 50]}
          pagination
          {...rows}
          filterMode="server"
          filterModel={{
            items: [{ columnField: filterKey, operatorValue: operatorValue, value: filterValue }]
          }}
          onFilterModelChange={onFilterChange}
          loading={loading}
          disableSelectionOnClick
          components={{
            Toolbar: GridToolbar,
            NoRowsOverlay: () => (
              <>
                <div className={classes.noRows}>
                  <img src={NoData} alt="" />
                </div>
                <label className={classes.noRowsText}>There is no any order</label>
              </>
            )
          }}
        />
      </div>
    </React.Fragment>
  );
}
