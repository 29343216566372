import React from "react";
import { Route } from "react-router-dom";

//core components
import CustomerList from "components/Customer/index";
import CustomerDetails from "components/CustomerDetails/CustomerDetails";

export default function Customers() {
  return (
    <div>
      <Route path={`/admin/customers/:id`} exact={true} component={CustomerDetails} />
      <Route path={`/admin/customers`} exact={true} component={CustomerList} />
    </div>
  );
}
